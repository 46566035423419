import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Grow } from "@material-ui/core";
import moment from "moment";

import { Creators as NotificationActions } from "store/ducks/notification";
import { ExternalEvaluationJobController } from "modules/evaluationJob/controller";

import Logo from "assets/imgs/brand/closeer-logo.svg";
import calendarIcon from "@iconify-icons/fluent/calendar-ltr-20-filled";
import clockIcon from "@iconify-icons/fluent/clock-20-filled";
import infoIcon from "@iconify-icons/fluent/info-24-filled";
import Footer from "components/Footer";
import LoadingSpinner from "components/LoadingSpinner";
import Field from "components/modalJobAndInterview/components/form/field";
import InputCustom from "components/modalJobAndInterview/components/form/input";
import ErrorLoadingModule from "templates/ErrorLoadingModule";
import { RatingStar } from "modules/evaluationJob/components/RatingStar";
import { HeaderRating } from "modules/evaluationJob/components/HeaderRating";
import { IconTextJob } from "components/IconTextJob";
import { Button } from "libs/purple-ui";
import { Avatar } from "./components/Avatar";

import * as S from "./styles";

export function EvaluationJob() {
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const [comment, setComment] = useState("");
  const [validate, setValidate] = useState(false);
  const [statusRatingSend, setStatusRatingSend] = useState(false);
  const [error, setError] = useState("");
  const [jobDetails, setJobDetails] = useState({
    id: "",
    date: "",
    hourStart: "",
    hourEnd: "",
  });
  const [worker, setWorker] = useState({
    name: "",
    role: "",
    image: "",
  });
  const [establishment, setEstablishment] = useState({
    name: "",
    address: "",
    image: "",
  });
  const [ratingOptions, setRatingOptions] = useState([
    {
      name: "",
      value: null,
      tooltip: "",
    },
  ]);
  const [textRating, setTextRating] = useState({
    show: false,
    labelTextField: "",
    placeholderTextField: "",
    feedback: "",
  });
  const MAXLENGTHCOMMENT = 200;

  const { t } = useTranslation([
    "rating",
    "jobDetail",
    "configAddress",
    "common",
  ]);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!id) {
      getOffer();
    }
  }, []);

  useEffect(() => {
    textByRating();
    setValidate(ratingOptions?.every((item) => !!item?.value));
  }, [ratingOptions]);

  const getOffer = useCallback(async () => {
    try {
      setLoading(true);
      let { data } = await ExternalEvaluationJobController.getJobDetails({
        id,
      });

      setStatusRatingSend(!!data?.was_ratied);
      getOptionsForRating(data);

      setJobDetails({
        id: data?.id,
        date: moment(data?.new_start_date).format("DD/MM/YYYY"),
        hourStart: moment(data?.new_start_date).format("HH:mm"),
        hourEnd: moment(data?.new_end_date).format("HH:mm"),
      });

      const roleNames = data?.role_worker?.map((role) => role);
      setWorker({
        name: data?.worker_name,
        role: roleNames.join(" / "),
        image: data?.worker_image,
      });

      setEstablishment({
        name: data?.establishment_name,
        address: data?.establishment_address,
        image: data?.logo_establishment,
      });
    } catch (error) {
      setErrorLoadingData(true);
      const { checked } = error?.customErrorOptions;

      if (!checked) {
        dispatch(
          NotificationActions.trigger(
            t("jobDetail:messages.load_error"),
            "danger"
          )
        );
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const getOptionsForRating = (data) => {
    let array = [];
    if (!!data?.was_ratied) {
      data?.rating_data?.options_value?.map((item) => {
        array.push({
          option: item?.option?.id,
          name: item?.option?.name,
          tooltip: item?.option?.description,
          value: item?.value,
        });
        setComment(data?.rating_data?.rating_text);
      });
    } else {
      data?.options_to_rating?.map((item) => {
        array.push({
          option: item.id,
          name: item?.name,
          tooltip: item?.description,
          value: null,
        });
      });
    }
    setRatingOptions(array);
  };

  const sendEvaluation = async () => {
    try {
      const newRatingOptions = ratingOptions.map(({ option, value }) => ({
        option,
        value,
      }));
      await ExternalEvaluationJobController.sendRating({
        id,
        text: comment,
        options: newRatingOptions,
      });
      setStatusRatingSend(true);
      setTextRating({
        feedback: t(`external_evaluation.review_received_message.good`),
      });
    } catch (error) {
      const { checked } = error?.customErrorOptions;

      if (!checked) {
        dispatch(
          NotificationActions.trigger(
            t("configAddress:message_error.error_3"),
            "danger"
          )
        );
      }
    }
  };

  const textByRating = () => {
    setTextRating(() => {
      let good = ratingOptions?.every((item) => item?.value === 5) ?? false;
      let bad = ratingOptions?.every(
        (item) => item?.value <= 1 && !!item?.value
      );

      let level = "good";
      if (bad) level = "bad";
      const workerName = worker?.name;

      const text = {
        show: good || bad,
        labelTextField: t(`external_evaluation.review_send.${level}`, {
          name: workerName,
        }),
        placeholderTextField: t(`external_evaluation.placeholder.${level}`),
        feedback: t(`external_evaluation.review_received_message.${level}`),
      };
      return text;
    });
  };

  const handleRating = (index, title) => {
    let arrayItems = Array.from(ratingOptions);
    arrayItems.map((item) => {
      if (item.name === title) {
        if (item.value === index) {
          item.value = null;
        } else {
          item.value = index;
        }
      }
    });
    setRatingOptions(arrayItems);
  };

  const handleSubmit = async () => {
    if (!validate) {
      return;
    }
    setLoadingSubmit(true);
    await sendEvaluation();
    setLoadingSubmit(false);
  };

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const onBlur = (e) => {
    let _error = "";

    if (e.target.value.length > MAXLENGTHCOMMENT) {
      _error = "exceder_caracteres";
    }

    setValidate(!_error);
    setError(_error);
  };

  if (errorLoadingData) {
    return <ErrorLoadingModule />;
  }

  const formatAddressLabel = ({ address }) => {
    const street = address?.address;
    const number = address?.number || "S/N";
    const neighbourhood = address?.neighb?.name;
    const city = address?.neighb?.city?.name;
    const uf = address?.neighb?.city?.state?.uf;

    let baseAddress = [street, number, neighbourhood].filter(Boolean);
    let complement = [city, uf].filter(Boolean);
    return [baseAddress.join(", "), complement.join(" - ")].join(", ");
  };

  return (
    <S.Container>
      <S.Logo src={Logo} />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <Grow in timeout={1000}>
          <S.Content>
            <HeaderRating
              jobDetails={jobDetails}
              textAfterRating={textRating?.feedback}
              statusRatingSend={statusRatingSend}
            />

            <S.ContainerWorker>
              <Avatar
                extra={worker?.role}
                name={worker?.name}
                kind={t("worker")}
                avatar={worker?.image}
              />
              {statusRatingSend && (
                <Avatar
                  extra={formatAddressLabel({
                    address: establishment?.address,
                  })}
                  name={establishment?.name}
                  kind={t("unit")}
                  avatar={establishment?.image}
                />
              )}
            </S.ContainerWorker>

            <S.ContainerRating>
              {ratingOptions.map((item, index) => (
                <RatingStar
                  key={index}
                  disabled={!!statusRatingSend}
                  value={item.value}
                  name={item.name}
                  tooltip={item.tooltip}
                  onClick={(ratingValue) => {
                    handleRating(ratingValue, item.name);
                  }}
                />
              ))}
            </S.ContainerRating>

            {!!comment && !!statusRatingSend && (
              <S.Header>
                <S.Title>
                  {t("rating:external_evaluation.review_comment")}:
                </S.Title>
                <S.Comment>"{comment}"</S.Comment>
              </S.Header>
            )}

            {statusRatingSend && (
              <S.ContainerJobInformation>
                <IconTextJob
                  title={t("rating:external_evaluation.id_job")}
                  information={jobDetails?.id}
                  icon={infoIcon}
                />
                <IconTextJob
                  title={t("jobDetail:table.date")}
                  information={jobDetails?.date}
                  icon={calendarIcon}
                />
                <IconTextJob
                  title={t("jobDetail:table.hour")}
                  information={t("common:interval_hours", {
                    start: jobDetails?.hourStart,
                    end: jobDetails?.hourEnd,
                  })}
                  icon={clockIcon}
                />
              </S.ContainerJobInformation>
            )}

            {!!textRating?.show && !statusRatingSend && (
              <S.ContainerTextField>
                <Field
                  label={textRating?.labelTextField}
                  error={t(`common:messages.${error}`, {
                    value: MAXLENGTHCOMMENT,
                  })}
                  invalid={!!error}
                >
                  <InputCustom
                    value={comment}
                    name="comment"
                    onChange={handleComment}
                    placeholder={textRating?.placeholderTextField}
                    onBlur={onBlur}
                    error={t(`common:messages.${error}`, {
                      value: MAXLENGTHCOMMENT,
                    })}
                    invalid={!!error}
                  />
                </Field>
              </S.ContainerTextField>
            )}

            {!statusRatingSend && (
              <S.Actions>
                <Button
                  loading={loadingSubmit}
                  disabled={!validate || loadingSubmit}
                  onClick={handleSubmit}
                >
                  {t("submit")}
                </Button>
              </S.Actions>
            )}
          </S.Content>
        </Grow>
      )}

      <Footer />
    </S.Container>
  );
}
