const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: {
    current_balance: 0,
  },
};

/**
 * TYPES
 */

export const Types = {
  WALLET_REQUEST: "wallet-request",
  WALLET_SUCCESS: "wallet-success",
  WALLET_FAILURE: "wallet-failure",
};
/**
 * REDUCER
 */
export function wallet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.WALLET_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.WALLET_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  setWallet: (data) => ({
    type: Types.WALLET_REQUEST,
    payload: { data },
  }),
  walletSuccess: (data) => ({
    type: Types.WALLET_SUCCESS,
    payload: { data },
  }),
  walletFailure: (data) => ({
    type: Types.WALLET_FAILURE,
    payload: { data },
  }),
};
