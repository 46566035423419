const INITIAL_STATE = {
  enrolled: {
    loading: false,
    loaded: false,
    error: "",
    data: {
      results: [],
      count: 0,
      next: null,
      previous: null,
      can_view_applicants_contact: false,
    },
  },
  selected: {
    loading: false,
    loaded: false,
    error: "",
    data: {
      results: [],
      count: 0,
      next: null,
      previous: null,
      can_view_applicants_contact: false,
    },
  },
  selectedWorker: {
    loading: false,
    loaded: false,
    error: "",
    data: null,
    selected: null,
  },
  detailsVacancy: null,
};

/**
 * TYPES
 */

export const Types = {
  ENROLLED_REQUEST: "enrolled-request",
  ENROLLED_SUCCESS: "enrolled-success",
  ENROLLED_FAILURE: "enrolled-failure",
  // ----------------------------------
  SELECTED_REQUEST: "selected-request",
  SELECTED_SUCCESS: "selected-success",
  SELECTED_FAILURE: "selected-failure",
  // ---------------------------------
  SELECTED_WORKER_REQUEST: "selected-worker-request",
  SELECTED_WORKER_SUCCESS: "selected-worker-success",
  SELECTED_WORKER_FAILURE: "selected-worker-failure",
  // ---------------------------------
  SELECTED_WORKER_LIST: "selected-worker-pk",
  // ---------------------------------
  DETAILS_VACANCY: "details-vacancy",
  // ---------------------------------
  CLEAR_DETAILS: "clear-details",
  REMOVE_SELECTED_WORKER: "remove-selected-worker",
};
/**
 * REDUCER
 */

export function vacancy(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ENROLLED_REQUEST:
      return {
        ...state,
        enrolled: {
          data: {
            ...state?.enrolled?.data,
            count: state?.enrolled?.data?.count,
          },
          loading: true,
          error: "",
        },
      };

    case Types.ENROLLED_SUCCESS:
      return {
        ...state,
        enrolled: {
          ...state.enrolled,
          data: action.payload.data,
          loading: false,
          loaded: true,
        },
      };
    case Types.ENROLLED_FAILURE:
      return {
        ...state,
        enrolled: {
          ...state.enrolled,
          loading: false,
          error: action.payload.data,
        },
      };
    //----------------------------------------
    case Types.SELECTED_REQUEST:
      return {
        ...state,
        selected: {
          data: {
            ...state?.selected?.data,
            count: state?.selected?.data?.count,
          },
          loading: true,
          error: "",
        },
      };
    case Types.SELECTED_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          data: action.payload.data,
          loading: false,
          loaded: true,
        },
      };
    case Types.SELECTED_FAILURE:
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: false,
          error: action.payload.data,
        },
      };
    // -------------------------------------
    case Types.SELECTED_WORKER_REQUEST:
      return {
        ...state,
        selectedWorker: { ...state.selectedWorker, loading: true, error: "" },
      };

    case Types.SELECTED_WORKER_SUCCESS:
      return {
        ...state,
        selectedWorker: {
          ...state.selectedWorker,
          data: action.payload.data,
          loading: false,
          loaded: true,
        },
      };
    case Types.SELECTED_WORKER_FAILURE:
      return {
        ...state,
        selectedWorker: {
          ...state.selectedWorker,
          loading: false,
          error: action.payload.data,
        },
      };
    // -------------------------------------
    case Types.SELECTED_WORKER_LIST:
      return {
        ...state,
        selectedWorker: {
          ...state.selectedWorker,
          currentApplication: action.payload.data,
        },
      };
    // -------------------------------------
    case Types.DETAILS_VACANCY:
      return {
        ...state,
        detailsVacancy: action.payload.data,
      };

    case Types.CLEAR_DETAILS:
      return {
        state: INITIAL_STATE,
      };
    case Types.REMOVE_SELECTED_WORKER:
      return {
        ...state,
        selectedWorker: {
          ...state.selectedWorker,
          loading: false,
          loaded: true,
          data: null,
          currentApplication: null,
        },
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  setEnrolled: () => ({
    type: Types.ENROLLED_REQUEST,
    payload: null,
  }),
  enrolledSuccess: (data) => ({
    type: Types.ENROLLED_SUCCESS,
    payload: { data },
  }),
  enrolledFailure: (data) => ({
    type: Types.ENROLLED_FAILURE,
    payload: { data },
  }),
  // -----------------------------
  setSelected: () => ({
    type: Types.SELECTED_REQUEST,
    payload: null,
  }),
  selectedSuccess: (data) => ({
    type: Types.SELECTED_SUCCESS,
    payload: { data },
  }),
  selectedFailure: (data) => ({
    type: Types.SELECTED_FAILURE,
    payload: { data },
  }),
  // -----------------------------
  setSelectedWorker: (data) => ({
    type: Types.SELECTED_WORKER_REQUEST,
    payload: { data },
  }),
  selectedWorkerSuccess: (data) => ({
    type: Types.SELECTED_WORKER_SUCCESS,
    payload: { data },
  }),
  removeSelectedWorker: () => ({
    type: Types.REMOVE_SELECTED_WORKER,
  }),
  selectedWorkerFailure: (data) => ({
    type: Types.SELECTED_WORKER_FAILURE,
    payload: { data },
  }),
  // -----------------------------
  selectedWorkerList: (data) => ({
    type: Types.SELECTED_WORKER_LIST,
    payload: { data },
  }),
  // -----------------------------
  setDetailsVacancy: (data) => ({
    type: Types.DETAILS_VACANCY,
    payload: { data },
  }),
  // -----------------------------
  clearDetailsVacancy: (data) => ({
    type: Types.CLEAR_DETAILS,
    payload: { data },
  }),
  // -----------------------------
};
