import React from "react";

function SplashScreen() {
  return (
    <>
      <div className="splash-screen-container">
        <img
          alt="Fallback Loading"
          src="/loading.gif"
          className="loading"
          styles={{
            width: "200px",
            height: "200px",
          }}
        />
      </div>
    </>
  );
}

export default SplashScreen;
