import { call, put } from "redux-saga/effects";
import MainService from "../../services/mainService";

import { Creators as DetailsActions } from "../ducks/vacancy";

export function* getSelectedWorker(action) {
  try {
    const { estabPk, workerPk } = action.payload.data;

    const { data } = yield call(
      MainService.get,
      `v2/establishment/${estabPk}/worker/${workerPk}`
    );
    yield put(DetailsActions.selectedWorkerSuccess(data));
  } catch (error) {
    yield put(DetailsActions.selectedWorkerFailure(error.message));
  }
}
