const INITIAL_STATE = {
  establishment: {
    loading: false,
    data: [],
    page: 1,
    reload: false,
    dataPagination: {
      next: null,
      previous: null,
      count: 0,
    },
  },
  bigcompany: {
    loading: false,
    data: [],
    page: 1,
    reload: false,
    dataPagination: {
      next: null,
      previous: null,
      count: 0,
    },
  },
};

/**
 * TYPES
 */

export const Types = {
  HISTORY_BILLING_REQUEST: "get-history-billing-request",
  HISTORY_BILLING_SUCCESS: "get-history-billing-success",
  HISTORY_BILLING_CHANGE_PAGE: "change-page-history-billing",
  RELOAD_HISTORY_BILLING: "reload-history-billing",
};
/**
 * REDUCER
 */
export function historyBilling(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.HISTORY_BILLING_REQUEST:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: action.payload.loading,
        },
      };
    case Types.HISTORY_BILLING_SUCCESS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          data: action.payload.data,
          dataPagination: action.payload.dataPagination,
        },
      };
    case Types.HISTORY_BILLING_CHANGE_PAGE:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          page: action.payload.page,
        },
      };
    case Types.RELOAD_HISTORY_BILLING:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          reload: !state.reload,
        },
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  requestHistoryBilling: ({ user = "establishment", loading = true }) => ({
    type: Types.HISTORY_BILLING_REQUEST,
    payload: { user, loading },
  }),
  setHistoryBilling: ({
    user = "establishment",
    data = [],
    dataPagination = { count: 0, next: null, previous: null },
  }) => ({
    type: Types.HISTORY_BILLING_SUCCESS,
    payload: { user, data, dataPagination },
  }),
  changePage: ({ user = "establishment", page = 1 }) => ({
    type: Types.HISTORY_BILLING_CHANGE_PAGE,
    payload: { user, page },
  }),
  reloadList: (user = "establishment") => ({
    type: Types.RELOAD_HISTORY_BILLING,
    payload: { user },
  }),
};
