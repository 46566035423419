import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";

import { RadioButton, Text } from "libs/purple-ui";

import * as S from "./styles";

/**
 * ## Parâmetros
 * @param {Object[]} options - As opções do grupo de botões de radio button
 * @param {string|number}} options[].value - O valor da opção
 * @param {node|string} options[].text - A label da opção
 * @param {node|string} options[].description - A descrição opcional da opção
 * @param {string|number} value - O valor selecionado
 * @param {function} onChange - O callback chamado quando o valor é alterado.
 * @param {boolean} column - Se o radio button vai ser em coluna
 * @param {string} spacing - Define o espaçamento entre os radio buttons
 * @param {boolean} separator - Se vai ter separador entre os radio buttons
 * @param {string} error - A mensagem de erro
 * @param {boolean} disabled Define se o radiobutton está desabilitado
 * @param {string} name - nome do radiobutton

 * ## Exemplo de uso
 * @example
 * <RadioButtonGroup
 *  column
 *  disabled={false}
 *  options={[
 *  { text: 'Primeiro', value: 'first', description: 'Primeiro'},
 *  { text: 'Segundo', value: 'second', description: <Comment>Segundo</Comment>},
 *  { text: <Component text="Terceiro" />, value: 'third' }
  ]}
 *  onChange={() => console.log('Clicou no radio button')}
 *  value="value"
 *  error="Mensagem de erro"
 *  spacing="10px"
 *  separator={false}
 * />
 * @returns node
 */

export const RadioButtonGroup = ({
  options = [],
  value = "",
  onChange = () => {},
  column = false,
  error = "",
  spacing = "0.875rem",
  separator = false,
  disabled = false,
  name = "",
}) => {
  const theme = useTheme();

  return (
    <S.Container column={column} spacing={spacing}>
      {options.map((option, index) => (
        <div key={option?.value} style={{ alignSelf: "flex-start" }}>
          <RadioButton
            name={name}
            disabled={disabled}
            value={option?.value}
            text={option?.text}
            checked={value === option?.value}
            onChange={onChange}
            description={option?.description}
          >
            {option?.text}
          </RadioButton>
          {index < options?.length - 1 && separator && <S.Separator />}
        </div>
      ))}
      {error && (
        <Text color={theme.colors.danger_4} size="small">
          {error}
        </Text>
      )}
    </S.Container>
  );
};

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.node,
      description: PropTypes.node,
    })
  ),
  error: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  column: PropTypes.bool,
  spacing: PropTypes.string,
  separator: PropTypes.bool,
};
