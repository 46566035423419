const INITIAL_STATE = {
    data: [],
    type: 'Todos',
  };

  /**
   * TYPES
   */
  export const Types = {
    SET_EXTRACT_FREE: "set-extract-free",
  };
  /**
   * REDUCER
   */
  export function extractFree(state = INITIAL_STATE, action) {
    switch (action.type) {
      case Types.SET_EXTRACT_FREE:
        return {
          ...state,
          data: action.payload.data,
          type: action.payload.type,
        };
      default:
        return state;
    }
  }

  /**
   * ACTIONS
   */
  export const Creators = {
    setExtract: (data, type) => ({
      type: Types.SET_EXTRACT_FREE,
      payload: { data, type }
    }),
  };
