import styled, { css } from "styled-components";

export const Container = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  position: relative;
  overflow: hidden;
  border: none;

  ${({ size }) => {
    let _size = size.replace("rem", "").replace("px", "");
    let minViewBox = Number(_size) * 16 + "px";
    return css`
      min-width: ${minViewBox ?? "24px"};
      min-height: ${minViewBox ?? "24px"};
    `;
  }};

  width: ${({ size }) => size ?? "2rem"};
  height: ${({ size }) => size ?? "2rem"};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${({ theme }) => theme.borderRadius.rounded};

  background: ${({ theme, disabled, background }) =>
    disabled
      ? theme.colors.grey_1
      : background
      ? background
      : "rgba(255, 255, 255, 0.1)"};
  color: ${({ theme, color, disabled }) =>
    disabled ? theme.colors.grey_2 : color || theme.colors.grey_3};

  transition: background-color 0.2s linear;

  &:hover:not(:disabled) {
    background-color: ${({ theme, background }) =>
      !background && `${theme.colors.opacity(theme.colors.grey_3, 25)}`};
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;
