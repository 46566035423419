import React from "react";

import * as S from "./styles";
import IconCloseer from "components/IconCloseer";

const StyleIconButton = {
  width: "2.5rem",
  height: "2.5rem",
  flex: "none",
  marginRight: "1rem",
};


export function IconTextJob({ 
  icon = null, 
  title = "",
  information = "" 
}) {
  return (
    <S.TextGroup>
      <IconCloseer
        style={{
          ...StyleIconButton,
        }}
        heightIcon="1.6rem"
        icon={icon}
      />
      <S.TextGroup column>
        <strong>{title}</strong>
        {information}
      </S.TextGroup>
    </S.TextGroup>
  );
}
