const colors = {
  blue: "#02E2BD",
  green: "#AFF82D",
  green_dark: "#7FC837",
  red: "#EB5F5F",
  gray: "#8f9498",
  purple: "#4A0D77",
  secundary: "#D1B7E4",
  black: "#2C3033",
  background: "#F6F6F6",
  background2: "#ededed",
  sucess: "#7FC837",
  disable: "#D6D6D6",
  yellow: "#F2C94C",
  alertYellow: "#ECC561",
  info: "#2D9CDB",
  light_blue: "#CBEAE5",
  grayLight: "#A7A7A7",
  grayLight2: "#d5d5d5",
  grayLight3: "#EEEEEE",
  redLight: "#EB5F5F80",
  redLight2: "#FFC2C2",
  lightPurple: "#D1B7E4",
  // new colors
  primary_1: "#540DCE",
  primary_2: "#6B57E0",
  primary_3: "#7869D4",
  primary_4: "#A599E3",
  primary_5: "#D1CCF0",
  primary_6: "#B19DE3",
  primary_7: "#EFE9FF",
  white: "#F4F7FC",
};

export default colors;
