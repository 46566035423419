import React, { createContext, useContext, useEffect, useRef } from "react";

const DeferredPromptContext = createContext();

export const DeferredPromptProvider = ({ children }) => {
  const deferredPromptRef = useRef(null);

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      deferredPromptRef.current = e;
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
    };
  }, []);

  return (
    <DeferredPromptContext.Provider value={deferredPromptRef}>
      {children}
    </DeferredPromptContext.Provider>
  );
};

export const useDeferredPrompt = () => useContext(DeferredPromptContext);
