import i18n from "i18next";

import colors from "assets/colors";
import WarningIcon from "@iconify-icons/fluent/warning-24-regular";
import InfoIcon from "@iconify-icons/fluent/info-24-regular";
import ShieldErrorIcon from "@iconify-icons/fluent/shield-error-24-regular";

const GROUP_ID = "gst3hk6f2d01";

const COLORS_BACKGROUND = {
  general_error: colors.red,
  critical: colors.red,
  major: "#FF8B00",
  minor: "#FFC225",
  active_multiple: "#3B4052",
  active: "#4770DC",
};

const ICONS = {
  general_error: ShieldErrorIcon,
  critical: ShieldErrorIcon,
  major: WarningIcon,
  minor: WarningIcon,
  active_multiple: ShieldErrorIcon,
  active: InfoIcon,
};

export const listStatusPage = (
  maintenances_active = [],
  incidents_list = []
) => {
  // total incidentes
  const incidents = getData(incidents_list?.incidents);

  // total manutenção em andamento
  const active = getData(maintenances_active?.scheduled_maintenances);

  const statusPage = {
    incidents: {
      total: incidents?.total,
      dictIncidents: incidents?.dictIdIncidents,
      listGroup: incidents?.listGroup,
      newIncident: {
        id: incidents?.listGroup?.[0]?.id,
        status: incidents?.listGroup[0]?.impact,
        message: incidents?.listGroup[0]?.incident_updates[0]?.body,
        link: incidents?.listGroup[0]?.shortlink,
      },
    },
    active: {
      total: active?.total,
      dictActive: active?.dictIdIncidents,
      listGroup: active?.listGroup,
      newActive: {
        id: active?.listGroup?.[0]?.id,
        status: active?.listGroup[0]?.impact,
        message: active?.listGroup[0]?.incident_updates[0]?.body,
        link: active?.listGroup[0]?.shortlink,
      },
    },
  };
  return statusPage;
};

const hasSomeCritical = ({ listGroup }) =>
  listGroup?.some(({ impact }) => impact === "critical");

export const checkCriticalImpact = ({ active, incidents }) => {
  return hasSomeCritical(active) || hasSomeCritical(incidents);
};

export const compareStorage = (storage, api) => {
  let status = "normal";
  // se storage estiver vazio
  if (!storage) {
    storage = {
      incidents: {
        total: 0,
        dictIncidents: {},
      },
      active: {
        total: 0,
        dictActive: {},
      },
    };
  }

  // verifica as atualizações de incidentes
  let incidentsUpdate = 0;
  for (let key in api.incidents?.dictIncidents) {
    if (
      storage.incidents?.dictIncidents[key] !==
      api.incidents?.dictIncidents[key]
    ) {
      incidentsUpdate++;
    }
  }
  // verifica as atualizações de ativos
  let activeUpdate = 0;
  for (let key in api.active?.dictActive) {
    if (storage.active?.dictActive[key] !== api.active?.dictActive[key]) {
      activeUpdate++;
    }
    if (incidentsUpdate > 0) {
      api.showMoreCard = true;
      delete api.active?.dictActive[key];
    }
  }
  if (incidentsUpdate > 1) {
    status = "general_error";
  } else if (incidentsUpdate === 1) {
    status = api?.incidents?.newIncident?.status;
    if (status === "none") {
      status = "minor";
    }
  } else if (activeUpdate > 1) {
    status = "active_multiple";
  } else if (activeUpdate === 1) {
    status = "active";
  }

  return {
    statusWidget: status,
    api,
  };
};

export const objectStatus = (
  status = "general_error",
  listStatus = {
    active: {},
    incidents: {},
  }
) => {
  let propObject = {
    icon: ICONS[status],
    background: COLORS_BACKGROUND[status],
    title: "",
    message: "",
    link: "https://closeerstatus.statuspage.io/",
    tags: [],
  };

  if (status === "general_error") {
    propObject = {
      ...propObject,
      title: i18n.t("common:status_page.system_instability"),
      message: i18n.t("common:status_page.system_instability_message"),
    };
  } else if (
    status === "critical" ||
    status === "major" ||
    status === "minor"
  ) {
    propObject = {
      ...propObject,
      tags: listTags(listStatus.incidents?.listGroup),
      title: i18n.t("common:status_page.system_instability"),
      message: listStatus.incidents?.newIncident?.message,
      link: listStatus.incidents?.newIncident?.link,
    };
  } else if (status === "active_multiple") {
    propObject = {
      ...propObject,
      title: i18n.t("common:status_page.maintenance"),
      message: i18n.t("common:status_page.ongoing_maintenance_message"),
    };
  } else if (status === "active") {
    propObject = {
      ...propObject,
      tags: listTags(listStatus.active?.listGroup),
      title: i18n.t("common:status_page.ongoing_maintenance"),
      message: listStatus.active?.newActive?.message,
      link: listStatus.active?.newActive?.link,
    };
  } else {
    propObject = {
      ...propObject,
      title: i18n.t("common:status_page.problems_solved"),
      message: i18n.t("common:status_page.problems_solved_message"),
    };
  }
  return propObject;
};

const listTags = (listStatus = []) => {
  let allTags = listStatus[0]?.components
    ?.filter((component) => component.group_id === GROUP_ID)
    .map((item) => item.name);

  if (allTags?.length > 3) {
    allTags = allTags.slice(0, 3);
    allTags.push("...");
  }
  return allTags;
};

const getData = (incidents) => {
  let listGroup = [];

  let dictIdIncidents = {};
  let dictDateIncidents = {};

  let total = 0;

  if (!!incidents?.length) {
    listGroup = incidents?.filter(
      (item) =>
        item?.components?.filter((component) => component.group_id === GROUP_ID)
          ?.length > 0
    );
    listGroup?.map((item) => {
      total += 1;
      dictIdIncidents[item.id] = item?.incident_updates?.length;
    });
  }
  return {
    listGroup,
    dictIdIncidents,
    dictDateIncidents,
    total,
  };
};
