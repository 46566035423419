import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
`;

export const VisibilityControl = styled.p`
  font: 0.875rem/1.098rem "Muli", sans-serif;
  color: ${({ theme }) => theme.colors.black_pure};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;
