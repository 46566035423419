import React from "react";
import ErrorPage from "./index";
import mainService from "services/mainService";
import StackTrace from "stacktrace-js";
import { connect } from "react-redux";

import { getBrowserVersion, getInfoUsers } from "utils/basic";

async function reportError({ error, stack, state }) {
  if (process.env.NODE_ENV === "development") {
    console.log(JSON.stringify(error));
    console.log("\n");
    console.log(JSON.stringify({ stack }, null, 2));
    return;
  }

  if (/Loading (?:(CSS+\s)?)+chunk [\d]+ failed/.test(error)) {
    return;
  } else {
    try {
      let url = "v2/error_logs_front";

      let body = {
        app_build: "0.1.0",
        error: {
          error_json: JSON.stringify(stack),
          error_text: `${error}`,
          extra_data: `Rota: ${location.pathname},
          Resolução: ${window.innerWidth}x${window.innerHeight},
          ${getInfoUsers(state)},
          Navegador: ${getBrowserVersion()},`,
          app_type: "WEB_EMPRESAS",
        },
      };

      const response = await mainService.post(url, body);

      if (!response.data.ok) {
        throw response;
      } else {
        return response.json();
      }
    } catch (err) {
      console.log(err);
      /* throw err; */
    }
  }
}

class WrapperErrorBoundary extends React.Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    if (/Loading (?:(CSS+\s)?)+chunk [\d]+ failed/.test(error)) {
      let hasReloaded = !!JSON.parse(
        sessionStorage.getItem("@closeer__ChunkLoadError")
      );

      if (!hasReloaded) {
        sessionStorage.getItem("@closeer__ChunkLoadError", true);
        window.location.reload();
        return state;
      } else {
        sessionStorage.removeItem("@closeer__ChunkLoadError");
        return { error };
      }
    }
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    StackTrace.fromError(error).then((stackframes) => {
      reportError({
        error: error.toString(),
        stack: stackframes,
        state: this.props.state,
      });
    });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <ErrorPage error={error} />;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  state,
});

export default connect(mapStateToProps)(WrapperErrorBoundary);
