import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as SupervisorsActions } from "../ducks/supervisors";

export function* getSupervisors(action) {
  const { page, text } = action.payload;
  try {
    const { data } = yield call(
      MainService.get,
      `/supervisors/?search=${text}${page ? "?page=" + (page + 1) : ""}`
    );

    yield put(SupervisorsActions.getSupervisorsSuccess({ ...data, page }));
  } catch (error) {
    yield put(
      SupervisorsActions.getSupervisorsFailure(
        "Erro no carregamento. Tente novamente mais tarde."
      )
    );
  }
}
