const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: [],
};

/**
 * TYPES
 */

export const Types = {
  STATUS_REQUEST: "status-request",
  STATUS_SUCCESS: "status-success",
  STATUS_FAILURE: "status-failure",
  UPDATE_USERNAME: "config-update-user-name",
  UPDATE_UNIT_NAME: "config-update-unit-name",
  UPDATE_FINANCIAL_EMAIL: "update-financial-email",
  UPDATE_WHATSAPP: "config-update-whatsapp",
  UPDATE_PIX: "config-update-pix",
  TOGGLE_NOTIFICATION: "config-toggle-notifications",
  UPDATE_STATUS: "update-status",
  RESET: "reset-status-initial-state",
  UPDATE_COMMERCIAL_NAME: "update-commercial-name",
  UPDATE_PROPERTY_RELATION: "update-property-relation",
  UPDATE_USER_EMAIL: "update-user-email",
};
/**
 * REDUCER
 */
export function status(state = INITIAL_STATE, action) {
  switch (action.type) {
    // STATUS
    case Types.STATUS_REQUEST:
      return { ...state, loaded: false, loading: true, error: "" };
    case Types.STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.UPDATE_STATUS:
      return { ...state, data: action.payload.data };
    case Types.UPDATE_USERNAME:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state?.data?.user,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
          },
        },
      };
    case Types.UPDATE_UNIT_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          self_id: action.payload.self_id,
        },
      };
    case Types.UPDATE_COMMERCIAL_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          legal: {
            ...state?.data?.legal,
            commercial_name: action.payload.commercial_name,
          },
        },
      };
    case Types.UPDATE_FINANCIAL_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          financial_email: {
            ...state?.data?.financial_email,
            ...action.payload.data,
          },
        },
      };
    case Types.UPDATE_WHATSAPP:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state?.data?.user,
            username: action.payload.data,
          },
        },
      };
    case Types.TOGGLE_NOTIFICATION:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state?.data?.user,
            notification_settings: {
              ...state?.data?.user?.notification_settings,
              [action.payload.key]: action.payload.value,
            },
          },
        },
      };
    case Types.UPDATE_PIX:
      return {
        ...state,
        data: {
          ...state.data,
          pix: {
            ...state?.data?.pix,
            ...action.payload.data,
          },
        },
      };
    case Types.STATUS_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    case Types.UPDATE_PROPERTY_RELATION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
      };
    case Types.UPDATE_USER_EMAIL:
      return {
        ...state,
        data: {
          ...state?.data,
          user: {
            ...state?.data?.user,
            email: action?.payload?.email,
          },
        },
      };
    case Types.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  // STATUS
  statusRequest: (data) => ({
    type: Types.STATUS_REQUEST,
    payload: { data },
  }),
  updateStatus: (data) => ({
    type: Types.UPDATE_STATUS,
    payload: { data },
  }),
  statusSuccess: (data) => ({
    type: Types.STATUS_SUCCESS,
    payload: { data },
  }),
  statusFailure: (data) => ({
    type: Types.STATUS_FAILURE,
    payload: { data },
  }),
  /* ---------------------------- */
  updateUsername: ({ first_name, last_name }) => ({
    type: Types.UPDATE_USERNAME,
    payload: { first_name, last_name },
  }),
  updateUnitName: ({ self_id }) => ({
    type: Types.UPDATE_UNIT_NAME,
    payload: { self_id },
  }),
  updateCommercialName: ({ commercial_name }) => ({
    type: Types.UPDATE_COMMERCIAL_NAME,
    payload: { commercial_name },
  }),
  updateFinancialEmail: (data) => ({
    type: Types.UPDATE_FINANCIAL_EMAIL,
    payload: { data },
  }),
  updateWhatsapp: (data) => ({
    type: Types.UPDATE_WHATSAPP,
    payload: { data },
  }),
  updatePix: (data) => ({
    type: Types.UPDATE_PIX,
    payload: { data },
  }),
  toggleNotification: ({ key, value }) => ({
    type: Types.TOGGLE_NOTIFICATION,
    payload: { key, value },
  }),
  updatePropertyRelation: ({ key, value }) => ({
    type: Types.UPDATE_PROPERTY_RELATION,
    payload: { key, value },
  }),
  updateEmailUser: ({ email }) => ({
    type: Types.UPDATE_USER_EMAIL,
    payload: { email },
  }),
  reset: () => ({
    type: Types.RESET,
    payload: null,
  }),
};
