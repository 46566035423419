import * as React from "react";
const SvgAccountingDocument = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18.378 7.9-3.612-3.612a1.008 1.008 0 0 0-.707-.293h-7.73a1 1 0 0 0-1 1v14.01a1 1 0 0 0 1 1h12.008c.185 0 .334-.15.334-.333V8.607a.994.994 0 0 0-.293-.707Zm-7.834 3.555-2.248-.84a1.701 1.701 0 0 1-.966-1.647 1.687 1.687 0 0 1 1.527-1.691.166.166 0 0 0 .141-.165v-.449a.667.667 0 0 1 1.334 0v.417c0 .092.075.167.167.167h.834a.667.667 0 0 1 0 1.334H9.248c-.583 0-.583.291-.583.387a.6.6 0 0 0 .122.408l2.248.84c.63.3 1.012.955.965 1.65a1.687 1.687 0 0 1-1.527 1.688.166.166 0 0 0-.14.165v.449a.667.667 0 1 1-1.335 0v-.417a.167.167 0 0 0-.167-.167h-.833a.667.667 0 1 1 0-1.334h2.084c.584 0 .584-.29.584-.387a.6.6 0 0 0-.122-.408Zm6.292 2.88a.5.5 0 0 0-.5-.5h-3.335a.5.5 0 0 0 0 1h3.335a.5.5 0 0 0 .5-.5Zm-3.001-3.002a.5.5 0 0 1 .5-.5h2.001a.5.5 0 0 1 0 1h-2.001a.5.5 0 0 1-.5-.5Zm3.001 6.004a.5.5 0 0 0-.5-.5h-6.67a.5.5 0 0 0 0 1h6.67a.5.5 0 0 0 .5-.5Z"
      clipRule="evenodd"
    />
    <mask
      id="accounting-document_svg__a"
      width={14}
      height={18}
      x={5}
      y={3}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m18.378 7.9-3.612-3.612a1.008 1.008 0 0 0-.707-.293h-7.73a1 1 0 0 0-1 1v14.01a1 1 0 0 0 1 1h12.008c.185 0 .334-.15.334-.333V8.607a.994.994 0 0 0-.293-.707Zm-7.834 3.555-2.248-.84a1.701 1.701 0 0 1-.966-1.647 1.687 1.687 0 0 1 1.527-1.691.166.166 0 0 0 .141-.165v-.449a.667.667 0 0 1 1.334 0v.417c0 .092.075.167.167.167h.834a.667.667 0 0 1 0 1.334H9.248c-.583 0-.583.291-.583.387a.6.6 0 0 0 .122.408l2.248.84c.63.3 1.012.955.965 1.65a1.687 1.687 0 0 1-1.527 1.688.166.166 0 0 0-.14.165v.449a.667.667 0 1 1-1.335 0v-.417a.167.167 0 0 0-.167-.167h-.833a.667.667 0 1 1 0-1.334h2.084c.584 0 .584-.29.584-.387a.6.6 0 0 0-.122-.408Zm6.292 2.88a.5.5 0 0 0-.5-.5h-3.335a.5.5 0 0 0 0 1h3.335a.5.5 0 0 0 .5-.5Zm-3.001-3.002a.5.5 0 0 1 .5-.5h2.001a.5.5 0 0 1 0 1h-2.001a.5.5 0 0 1-.5-.5Zm3.001 6.004a.5.5 0 0 0-.5-.5h-6.67a.5.5 0 0 0 0 1h6.67a.5.5 0 0 0 .5-.5Z"
        clipRule="evenodd"
      />
    </mask>
  </svg>
);
export default SvgAccountingDocument;
