import React from "react";

import * as S from "./styles";

/**
 * @param {string} avatar - foto
 * @param {string} kind - "titulo" do avatar
 * @param {string} name - nome do avatar
 * @param {string} extra - informação extra a ser exibida
 * @example
 * <Avatar avatar={avatar.jpeg} kind={"Profissional"} name="ZéZin" extra="lolzeiro / padeiro" />
 *
 * @example
 * <Avatar avatar={avatarEstab.jpeg} kind={"Unidade"} name="Unidade123" extra="Rua Fernando de Albuquerque - Consolação, São Paulo - SP" />
 **/
export const Avatar = ({ avatar, kind, name, extra }) => {
  return (
    <S.Container>
      <S.AvatarImage src={avatar} />

      <S.TextContainer>
        <S.Text title label>
          {kind}
        </S.Text>
        <S.Text label>{name}</S.Text>
        <S.Text>{extra}</S.Text>
      </S.TextContainer>
    </S.Container>
  );
};
