const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: {},
};

/**
 * TYPES
 */
export const Types = {
  REQUEST: "request-establishment-address",
  SUCCESS: "request-success-establishment-address",
  FAILURE: "request-failure-establishment-address",
  UPDATE: "update-property-establishment-address",
  RESET: "reset-establishment-address",
};
/**
 * REDUCER
 */
export function establishmentAddress(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    case Types.UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case Types.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  request: ({ pk }) => ({
    type: Types.REQUEST,
    payload: { pk },
  }),
  onSuccess: ({ data }) => ({
    type: Types.SUCCESS,
    payload: { data },
  }),
  onFailure: ({ data }) => ({
    type: Types.FAILURE,
    payload: { data },
  }),
  update: ({ data }) => ({
    type: Types.UPDATE,
    payload: { data },
  }),
  reset: () => ({
    type: Types.RESET,
    payload: null,
  }),
};
