import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as EstablishmentTaxAddressActions } from "store/ducks/establishment/taxAddress";

export function* getEstablishmentTaxAddress(action) {
  try {
    const { data } = yield call(
      MainService.get,
      `v2/establishment/${action.payload.pk}/legal_address`
    );

    yield put(EstablishmentTaxAddressActions.onSuccess({ data }));
  } catch (error) {
    yield put(
      EstablishmentTaxAddressActions.onFailure({ data: error.message })
    );
  }
}
