import styled from 'styled-components'

export const Snackbar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: end;
  pointer-events: none;
  padding: 1rem;
  box-sizing: border-box;
  justify-content: flex-end;
`;