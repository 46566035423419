import styled, { css } from "styled-components";
import colors from "assets/colors";

/* ---------------------------- */
/* Vertical padding */
const PaddingY = css`
  padding-top: 5.875rem;
  padding-bottom: 1.5rem;
  @media (max-width: 968px) {
    padding-top: 2.5rem;
    padding-bottom: 1rem;
  } ;
`;

/* ---------------------------- */
/* Horizontal padding */
const PaddingX = css`
  padding-right: 6.063rem;
  padding-left: 6.063rem;
  @media (max-width: 968px) {
    padding-right: 2rem;
    padding-left: 2rem;
  } ;
`;

/* ---------------------------- */
/* A default padding page */
const PagePadding = css`
  ${PaddingX};
  ${PaddingY};
`;

/* --------------------------------------- */
/* A wrapper to envolve all elements */
export const PageContainer = styled.main`
  width: 100%;
  min-height: 100%;

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${colors.background};
  ${PagePadding};

  > img.cloud-purple {
    height: 90%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }
  @media (max-width: 959px) {
    background: ${colors.primary_1};
  }
`;

/* --------------------------------------- */
/* A wrapper to envolve title and illustration */
export const BoxPrincipal = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 5;

  /* #1 wrapper title */
  > div.title {
    display: flex;
    flex-direction: column;
    > h2 {
      font-family: "Muli Bold";
      color: ${colors.black};
      margin-bottom: 1.688rem;
      font-size: 2.375rem;
      line-height: 2.981rem;
    }

    > h5 {
      width: 100%;
      max-width: 21.7rem;

      font-family: "Muli Bold";
      color: ${colors.black};
      font-size: 1.25rem;
      line-height: 1.569rem;
    }
  }
  /* End #1 */
  /* #2 illustration */
  > img.illustration {
    margin-top: 3.313rem;
    max-width: 33.25rem;
    max-height: 31.632rem;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  /* End #2 */
`;

/* --------------------------------------- */
/* A wrapper safe area align to envolve main container of information */
export const SafeAreaInfo = styled.div`
  width: 100%;
  height: 70%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 959px) {
    justify-content: center;
  }

  @media (max-width: 600px) {
    margin-top: 25%;
  }
`;

/* --------------------------------------- */
/* A wrapper to envolve information and action to go back */
export const BoxInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 31.75rem;
  width: 100%;
  z-index: 5;

  /* #1 Wrapper logo */
  > div.logo-container {
    margin-bottom: 3.125rem;

    img {
      max-width: 12rem;
      width: 100%;
    }
  }
  /* End #1 */
  /* #2 Wrapper to text */
  > div.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #fff;
    text-align: center;

    /* Text */
    > p {
      font-family: "Muli";
      font-size: 1.125rem;
      line-height: 1.412rem;
      > strong {
        font-family: "Muli Bold";
      }
    }
    /* Button */
    > button {
      margin-top: 3.188rem;
    }
  }
  /* End #2 */
`;

/* --------------------------------------- */
/* A footer */
export const Footer = styled.footer`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 5;
  p {
    font-family: "Muli";
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.412rem;
    color: ${colors.black};
  }
  @media (max-width: 959px) {
    p {
      color: ${colors.background};
    }
  }
`;
