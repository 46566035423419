export const colors = {
  opacity: (hexadecimal, percentage) => {
    const color = String(hexadecimal);

    if (!color.startsWith("#") && color.length < 7) return color;

    const opacities = {
      100: "FF",
      99: "FC",
      98: "FA",
      97: "F7",
      96: "F5",
      95: "F2",
      94: "F0",
      93: "ED",
      92: "EB",
      91: "E8",
      90: "E6",
      89: "E3",
      88: "E0",
      87: "DE",
      86: "DB",
      85: "D9",
      84: "D6",
      83: "D4",
      82: "D1",
      81: "CF",
      80: "CC",
      79: "C9",
      78: "C7",
      77: "C4",
      76: "C2",
      75: "BF",
      74: "BD",
      73: "BA",
      72: "B8",
      71: "B5",
      70: "B3",
      69: "B0",
      68: "AD",
      67: "AB",
      66: "A8",
      65: "A6",
      64: "A3",
      63: "A1",
      62: "9E",
      61: "9C",
      60: "99",
      59: "96",
      58: "94",
      57: "91",
      56: "8F",
      55: "8C",
      54: "8A",
      53: "87",
      52: "85",
      51: "82",
      50: "80",
      49: "7D",
      48: "7A",
      47: "78",
      46: "75",
      45: "73",
      44: "70",
      43: "6E",
      42: "6B",
      41: "69",
      40: "66",
      39: "63",
      38: "61",
      37: "5E",
      36: "5C",
      35: "59",
      34: "57",
      33: "54",
      32: "52",
      31: "4F",
      30: "4D",
      29: "4A",
      28: "47",
      27: "45",
      26: "42",
      25: "40",
      24: "3D",
      23: "3B",
      22: "38",
      21: "36",
      20: "33",
      19: "30",
      18: "2E",
      17: "2B",
      16: "29",
      15: "26",
      14: "24",
      13: "21",
      12: "1F",
      11: "1C",
      10: "1A",
      9: "17",
      8: "14",
      7: "12",
      6: "0F",
      5: "0D",
      4: "0A",
      3: "08",
      2: "05",
      1: "03",
      0: "00",
    };

    const sufix = opacities[percentage] || opacities[100];

    return `#${color.replace("#", "")}${sufix}`;
  },
  primary_0: "#EFE9FF",
  primary_1: "#CEC3F1",
  primary_2: "#B19DE3",
  primary_3: "#9475D6",
  primary_4: "#7B4ECA",
  primary_5: "#6535B0",
  primary_6: "#52298A",
  primary_7: "#351D63",
  primary_8: "#1D113D",
  primary_9: "#0A041A",

  black_pure: "#000000",
  black_0: "#2C3033",
  black_1: "#575757",
  black_2: "#051226",

  grey_0: "#EBF6F2",
  grey_1: "#D4DEDB",
  grey_2: "#BAC6C2",
  grey_3: "#9FB0AA",
  grey_4: "#839A90",
  grey_5: "#6A8174",
  grey_6: "#526459",
  grey_7: "#3B473F",
  grey_8: "#232729",
  grey_9: "#070E13",

  white_pure: "#FFFFFF",
  white_0: "#A7A7A7",
  white_1: "#D6D6D6",
  white_2: "#F6F6F6",

  success_0: "#D9FFF3",
  success_1: "#ADFFE3",
  success_2: "#7DFFD7",
  success_3: "#4DFFCC",
  success_4: "#25FFC7",
  success_5: "#13E6B4",
  success_6: "#00B392",
  success_7: "#00805F",
  success_8: "#004E33",
  success_9: "#001C0F",

  danger_0: "#FFE5E8",
  danger_1: "#FBB9BE",
  danger_2: "#F28D91",
  danger_3: "#EB6060",
  danger_4: "#E4343F",
  danger_5: "#CB1B31",
  danger_6: "#9F1430",
  danger_7: "#720C28",
  danger_8: "#46051B",
  danger_9: "#1E000C",

  blue_0: "#DDFCFF",
  blue_1: "#B8E7F9",
  blue_2: "#8ED3EE",
  blue_3: "#64BCE6",
  blue_4: "#3BA3DD",
  blue_5: "#2294C4",
  blue_6: "#147D99",
  blue_7: "#08616F",
  blue_8: "#004145",
  blue_9: "#001A1D",

  yellow_0: "#FFF8DD",
  yellow_1: "#FBEFB3",
  yellow_2: "#F8E986",
  yellow_3: "#F5E557",
  yellow_4: "#F2D129",
  yellow_5: "#D9AA13",
  yellow_6: "#A8790A",
  yellow_7: "#784E04",
  yellow_8: "#482900",
  yellow_9: "#190C00",

  green_0: "#F6FAE1",
  green_1: "#E6EFBF",
  green_2: "#D2E29A",
  green_3: "#BDD774",
  green_4: "#A7CB4D",
  green_5: "#95B234",
  green_6: "#7B8A26",
  green_7: "#5C631A",
  green_8: "#3A3B0C",
  green_9: "#151500",

  dark_opacity_0: "rgba(0, 0, 0, 0.07)",
  dark_opacity_1: "rgba(0, 0, 0, 0.15)",

  purple_opacity_0: "rgba(208, 171, 255, 0.20)",
};
