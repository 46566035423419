import * as React from "react";
const SvgRatingStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M11.66 4.41a.364.364 0 0 1 .681 0l1.842 5.223h5.202a.364.364 0 0 1 .236.64l-4.346 3.604 1.82 5.465a.364.364 0 0 1-.561.408L12 16.424 7.464 19.75a.364.364 0 0 1-.558-.408l1.82-5.465-4.348-3.604a.364.364 0 0 1 .236-.64h5.203L11.66 4.41Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRatingStar;
