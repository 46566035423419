const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: []
};

/**
 * TYPES
 */

export const Types = {
  JOB_SETTINGS_REQUEST: "job-settings-request",
  JOB_SETTINGS_SUCCESS: "job-settings-success",
  JOB_SETTINGS_FAILURE: "job-settings-failure"
};
/**
 * REDUCER
 */
export function jobSettings(state = INITIAL_STATE, action) {
  switch (action.type) {
    // JOB SETTINGS
    case Types.JOB_SETTINGS_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.JOB_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true
      };
    case Types.JOB_SETTINGS_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  // JOB SETTINGS
  jobSettingsRequest: data => ({
    type: Types.JOB_SETTINGS_REQUEST,
    payload: { data }
  }),
  jobSettingsSuccess: data => ({
    type: Types.JOB_SETTINGS_SUCCESS,
    payload: { data }
  }),
  jobSettingsFailure: data => ({
    type: Types.JOB_SETTINGS_FAILURE,
    payload: { data }
  })
};
