import * as React from "react";
const SvgArrowRight1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="m7.765 19.672 8.389-7.423a.334.334 0 0 0 0-.498l-8.39-7.422"
    />
  </svg>
);
export default SvgArrowRight1;
