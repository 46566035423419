import { call, put } from "redux-saga/effects";
import MainService from "../../services/mainService";

import { Creators as CategoriesActions } from "../ducks/categories";

export function* getCategories(action) {
  try {
    const { data } = yield call(MainService.get, "/establishment/categories/");

    yield put(CategoriesActions.getCategoriesSuccess(data));
  } catch (error) {
    if (error?.response && error?.response?.status !== 401) {
      yield put(CategoriesActions.getCategoriesFailure(error.message));
    }
  }
}
