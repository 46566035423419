import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as EstablishmentAddressActions } from "store/ducks/establishment/address";

export function* getEstablishmentAddress(action) {
  try {
    const { data } = yield call(
      MainService.get,
      `v2/establishment/${action.payload.pk}/address`
    );

    yield put(EstablishmentAddressActions.onSuccess({ data }));
  } catch (error) {
    yield put(EstablishmentAddressActions.onFailure({ data: error.message }));
  }
}
