import { call, put } from "redux-saga/effects";

import { Creators as Actions } from "store/ducks/subscription";
import { SubscriptionController } from "controller";

export function* getSubscription(action) {
  try {
    const { data } = yield call(SubscriptionController.getSubscriptionData, {
      user: action.payload.user,
      userId: action.payload.userId,
    });
    yield put(Actions.success(data));
  } catch (error) {
    yield put(Actions.failure(error?.message));
  }
}
