/**
 * @description Leia antes de anexar uma nova key
 * Cada key deve ser única, não repetir keys em diferentes requests pois os dados vão ser persistidos
 * Utilize o prefixo /dashboard/* se o componente é renderizado apenas na versão unidade
 * Utilize o prefixo /adm/* se o componente for renderizado somente para supervisor ou bigcompany
 * Utilize nomes sem prefixo / se o componente for compartilhado entre as stacks
 * Lembre de adicionar o nome do componente compartilhado na lipeza clearStacks dentro da sidebar ou os filtros não serão limpos
 */

const KEYS = [
  "/dashboard/base-externa",
  "/dashboard/freelancer/minha-base",
  "/dashboard/freelancer/agenda",
  "/dashboard/freelancer/relatorios",
  "/dashboard/subcontas",
  "/dashboard/recruta/vagas",
  "/dashboard/recruta/vaga/inscritos",
  "/dashboard/recruta/vaga/selecionados",
  "/dashboard/treinamentos/educloseer",
  "/dashboard/treinamentos/videos",
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Veja a descrição acima antes de adicionar uma nova key
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  "/adm",
  "/adm/modalUserUnits",
  "/adm/usuarios",
  "/adm/unidade/usuarios",
  "/adm/unidade/limites",
  "/adm/freelancer/jobs",
  "/adm/freelancer/carteira",
  "/adm/recruta/vagas",
  "/adm/treinamentos/educloseer",
  "/adm/treinamentos/videos",
  "/adm/recruta/vaga/inscritos",
  "/adm/recruta/vaga/selecionados",
  "/adm/aprovacoes",
  "/adm/aprovacoes/pendentes",
  "/adm/jobs/limites",
  "/adm/jobs/unidade/limites",
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Veja a descrição acima antes de adicionar uma nova key
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  "Signature",
  "DepositHistory",
  "CostCenter",
  "Instructions",
  "Jobs",
  "JobsRequests",
  "ValueControl",
  "BlockCpf",
  "Subscription",
];

/**
 * INITIAL STATE
 */

const INITIAL_SCOPE = {
  page: 1,
  page_size: 10,
  full_url: "",
  search: {},
  dayView: new Date(),
  monthView: new Date(),
  isFilter: false,
};

const INITIAL_STATE = getInitialState(KEYS);

/**
 * TYPES
 */
export const Types = {
  CHANGE_FILTER: "change-filter",
  RELOAD_FILTER: "reload-filter",
  CLEAR_STACK: "clear-stack-filter",
};

/**
 * REDUCER
 */
export function persistedFilters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CHANGE_FILTER:
      return changeFilterOfPathname(state, action);
    case Types.RELOAD_FILTER:
      return onChangeStatck(state, action);
    case Types.CLEAR_STACK:
      return onClearStack(state, action);
    default:
      return state;
  }
}

/**
 *
 * FUNCTIONS
 */
function onClearStack(state, action) {
  const { stack, other_paths } = action.payload;

  let reloadedState = state;

  Object.keys(reloadedState).forEach((_key) => {
    if (_key.match(stack) || other_paths.includes(_key)) {
      reloadedState[_key] = {
        ...state[_key],
        page: 1,
        search: {},
        dayView: new Date(),
        monthView: new Date(),
        isFilter: false,
      };
    }
  });

  return {
    ...reloadedState,
  };
}

function onChangeStatck(state, action) {
  const { path } = action.payload;

  let reloadedState = state;

  Object.keys(reloadedState).forEach((_key) => {
    if (path) {
      if (_key.match(path)) {
        reloadedState[_key] = {
          ...state[_key],
          page: 1,
          search: {},
          dayView: new Date(),
          monthView: new Date(),
          isFilter: false,
        };
      }
    } else {
      reloadedState[_key] = {
        ...state[_key],
        page: 1,
        search: {},
        dayView: new Date(),
        monthView: new Date(),
        isFilter: false,
      };
    }
  });

  return {
    ...reloadedState,
  };
}

function changeFilterOfPathname(state, action) {
  const key = !!state[action.payload.pathname] ? action.payload.pathname : "";

  return {
    ...state,
    [key]: {
      ...state[key],
      page: action?.payload?.page ?? state[key].page ?? 1,
      page_size: action?.payload?.page_size ?? state[key].page_size ?? 10,
      full_url: action?.payload?.full_url ?? state[key].full_url ?? "",
      search: action?.payload?.search ?? state[key].search ?? {},
      dayView: action?.payload?.dayView ?? state[key].dayView ?? null,
      monthView: action?.payload?.monthView ?? state[key].monthView ?? null,
      isFilter: action?.payload?.isFilter ?? state[key].isFilter ?? false,
    },
  };
}

function getInitialState(data = []) {
  let schema = {};

  data?.forEach((key) => {
    schema[key] = {
      ...INITIAL_SCOPE,
    };
  });
  return schema;
}

/**
 * ACTIONS
 */
export const Creators = {
  updateUrlSearch: ({ pathname, full_url, search, page, ...rest }) => ({
    type: Types.CHANGE_FILTER,
    payload: { pathname, full_url, search, page, ...rest },
  }),
  clear: (path = "") => ({
    type: Types.RELOAD_FILTER,
    payload: { path },
  }),
  clearStack: ({ stack = "", other_paths = [] }) => ({
    type: Types.CLEAR_STACK,
    payload: { stack, other_paths },
  }),
};

/**
 * ---------------------------------------
 * ########### Como utilizar #############
 * ---------------------------------------
 * 1. Para cada página, adicionar uma key ao objeto do estado seguindo o mesmo modelo de dados.
 * 2. Após adicionar state[pathname], chamar os dados na página utilizando:
 *  const REDUX_search_filters = useSelector(
      (state) => state.persistedFilters["/your/pathname"]
    );
  3. Trocar a lógica de uso dos dados da página para utilizar a partir do redux.
  4. Para persistir os dados, segue o padrão:
      4.1 Autocomplete de busca padrão (por termo):
      - Adicionar a propriedade initialSearchValue={REDUX_search_filters?.search?.name ?? ""}} no componente de filtro

      4.2 Busca por datas:
      - Adicionar a propriedade initialRangeDate={REDUX_search_filters?.search?.dates_range ?? { range_start: null, range_end: null }} no componente de filtro

      4.3 Para checkbox, autocomplete multiselect:
      - Utilizar as funções utils [mountPreSelectedArrayCheckbox, mountPreSelectedDataAutoComplete, ] no formato:

      myObj.preSelectedArray = mountPreSelectedArrayCheckbox({
        reduxState: REDUX_search_filters?.search?.[propName]
          ? REDUX_search_filters?.search?.[propName]?.split(",")
          : [],
        data: myObj,
      });

      - [propName] é igual ao key do myObj, ou seja, para myObj = { key: "favorite" }, o dado seria REDUX_search_filters?.search?.favorite

      4.4 Para radio group:
      - Adicionar a propriedade ao objeto de configuração preSelected: REDUX_search_filters?.search?.[propName] ?? "",
 */
