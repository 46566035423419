import OneSignal from "react-onesignal";
import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as StatusActions } from "store/ducks/status";
import { Creators as WalletActions } from "store/ducks/wallet";
import { Creators as CommercialSettingsActions } from "store/ducks/commercialSettings";
import { Creators as AddressActions } from "store/ducks/establishment/address";
import { Creators as TaxAddressActions } from "store/ducks/establishment/taxAddress";

export function* getStatus(action) {
  try {
    const response = yield call(
      MainService.get,
      "v2/establishment/user_to_establishment/data"
    );

    const estabPk = response?.data?.establishment_id;

    let { data } = yield call(
      MainService.get,
      `v2/establishment/${estabPk}/data`
    );
    data = {
      is_manager: response?.data?.is_manager,
      user: response?.data?.user,
      ...data,
    };

    if (data?.id) {
      const isPmeOrUnitWithSeparatedBilling = !(
        !!data?.bigcompany?.id && !data?.bigcompany?.separated_billing
      );

      yield put(
        CommercialSettingsActions.request({
          pk: data?.id,
          user: "establishment",
          required: isPmeOrUnitWithSeparatedBilling,
        })
      );

      if (isPmeOrUnitWithSeparatedBilling) {
        yield put(WalletActions.setWallet(data?.id));
      }

      yield put(
        TaxAddressActions.request({
          pk: data?.id,
        })
      );
      yield put(
        AddressActions.request({
          pk: data?.id,
        })
      );
    }
    yield put(StatusActions.statusSuccess(data));
  } catch (error) {
    if (error?.response && error?.response?.status !== 401) {
      yield put(StatusActions.statusFailure(error.message));
    }
  }
}
