import { call, all, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as TwoFactorAuthenticationActions } from "store/ducks/2fa";

export function* getTwoFactorAuthentication(action) {
  try {
    const [active, settings] = yield all([
      call(MainService.get, "v2/user/verify_2fa_actived"),
      call(
        MainService.get,
        `v2/${action?.payload?.user}/${action?.payload?.pk}/2fa_settings`,
        {
          headers: {
            "notifications-front-banned": true,
          },
        }
      ),
    ]);

    const data = {
      ...active?.data,
      ...settings?.data,
      active_for_all:
        settings?.data?.active_for_all ?? settings?.data?.active_for_all_units,
    };

    delete data.active_for_all_units;

    yield put(TwoFactorAuthenticationActions.success({ data }));
  } catch (error) {
    yield put(TwoFactorAuthenticationActions.failure(error.message));
  }
}
