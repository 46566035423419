const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: null,
};

/**
 * TYPES
 */

export const Types = {
  REQUEST: "2fa-request",
  SUCCESS: "2fa-success",
  FAILURE: "2fa-failure",
  SET_2FA: "set-2fa",
};

/**
 * REDUCER
 */

export function twoFactorAuthentication(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return {
        ...state,
        data: action.payload.data,
        loading: true,
        error: "",
      };
    case Types.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    case Types.SET_2FA:
      return { ...state, data: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */

export const Creators = {
  request: ({ pk, user = "bigcompany" }) => ({
    type: Types.REQUEST,
    payload: { pk, user },
  }),
  success: (data) => ({
    type: Types.SUCCESS,
    payload: { data },
  }),
  failure: (data) => ({
    type: Types.FAILURE,
    payload: { data },
  }),
  set2FA: (data) => ({
    type: Types.SET_2FA,
    payload: { data },
  }),
};
