import React from "react";
import { useTranslation } from "react-i18next";

import { handleLogout } from "utils/basic";
import Footer from "components/Footer";
import { Button } from "libs/purple-ui";

import Logo from "assets/imgs/brand/closeer-logo.svg";
import ImgError from "assets/imgs/undraw-server-down.svg";

import * as S from "./styles";

const ErrorLoadingModule = () => {
  const { t } = useTranslation(["error_loading_module"]);
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <S.Container>
      <S.Image src={Logo} maxWidth="12.5rem" marginTop="auto"></S.Image>
      <S.BodyContainer>
        <S.Title>{t("title")}</S.Title>
        <S.Subtitle>{t("subtitle")}</S.Subtitle>
        <S.Image
          src={ImgError}
          maxWidth="17.25rem"
          marginTop="4.375rem"
        ></S.Image>
        <S.ButtonGroup>
          <Button variant="outlined" onClick={handleReload}>
            {t("reload")}
          </Button>
          <Button
            variant="filledDanger"
            onClick={() =>
              handleLogout(() => {
                window.location.href = "/login";
              })
            }
          >
            {t("logout")}
          </Button>
        </S.ButtonGroup>
      </S.BodyContainer>

      <Footer />
    </S.Container>
  );
};

export default ErrorLoadingModule;
