import { css, keyframes } from "styled-components";

export const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`;

export const InfiniteRotateAnimation = css`
  animation: ${rotateKeyframes} 0.75s linear infinite;
`;
