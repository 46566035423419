import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as LevelsUsers } from "store/ducks/levelsUsers";

export function* getLevelsUsers(action) {
  try {
    const { pk } = action.payload.data;
    const url = `v2/bigcompany/${pk}/access_levels`;

    const { data } = yield call(MainService.get, url);

    yield put(LevelsUsers.levelsSuccess(data.results));
  } catch (error) {
    yield put(LevelsUsers.levelsFailure(error.message));
  }
}
