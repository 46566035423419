import mainService from "services/mainService";
import { USERS } from "utils/permissions";

/**
 * --------------------------------------------------
 * @description Lista as instruções automáticas
 * @param {string} user
 * @param {number} userPk
 * @param {number} pageSize
 * @param {number} pageIn
 * @param {string} params
 * @example
 * getInstructions({
 *  user: "establishment",
 *  userPk: 1,
 *  pageSize: 10,
 *  pageIn: 1,
 *  params: "&name=Teste&role=1,2,3"
 * })
 */
export function getInstructions({
  user = USERS.establishment,
  userPk = 0,
  pageSize = 10,
  pageIn = 1,
  params = "",
} = {}) {
  return mainService.get(
    `v2/${user}/${userPk}/messages_instruction?page_size=${pageSize}&pagination=true&page=${pageIn}${params}`
  );
}

/**
 * --------------------------------------------------
 * @description Cria ou edita uma instrução automática
 * @param {string} user
 * @param {number} userPk
 * @param {number} instructionPk
 * @param {object} body
 * @example
 * createOrEditInstruction({
 *  user: "establishment",
 *  userPk: 1,
 *  instructionPk: 1,
 *  body: {
 *   name: "Ajudante Geral - Manhã",
 *   message: "Chegue 30 minutos antes do horário de início",
 *   role: 1,
 *   _role: {
 *    id: 1,
 *    name: "Ajudante Geral",
 *    jobs_last_month: 10,
 *   }
 *  }
 * })
 */
export function createOrEditInstruction({
  user = USERS.establishment,
  userPk = 0,
  instructionPk = 0,
  body = {},
} = {}) {
  return !!instructionPk
    ? mainService.patch(
        `v2/${user}/${userPk}/messages_instruction/${instructionPk}`,
        body
      )
    : mainService.post(`v2/${user}/${userPk}/messages_instruction`, body);
}

/**
 * --------------------------------------------------
 * @description Deleta uma instrução automática
 * @param {string} user
 * @param {number} userPk
 * @param {number} instructionPk
 * @example
 * deleteInstruction({
 *  user: "establishment",
 *  userPk: 1,
 *  instructionPk: 1
 * })
 */
export function deleteInstruction({
  user = USERS.establishment,
  userPk = 0,
  instructionPk = 0,
} = {}) {
  return mainService.delete(
    `v2/${user}/${userPk}/messages_instruction/${instructionPk}`,
    {}
  );
}

/**
 * --------------------------------------------------
 * @description Busca informações de uma instrução
 * @param {string} user
 * @param {number} userPk
 * @param {number} instructionPk
 * @example
 * getInstructionInformation({
 *  user: "establishment",
 *  userPk: 1,
 *  instructionPk: 1
 * })
 */
export function getInstructionInformation({
  user = USERS.establishment,
  userPk = 0,
  instructionPk = 0,
}) {
  return mainService.get(
    `v2/${user}/${userPk}/messages_instruction/${instructionPk}`
  );
}
