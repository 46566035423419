import { Cookie } from "utils/cookies";
import { DEVICES_WIDTH } from "consts/grid";

export const isAuthenticated = () => {
  let hasToken = false;
  let redirect_to_path_when_not_auth = "/login";

  const tokenSupervisor = Cookie.get("token-supervisor");

  const tokenManager = Cookie.get("token-manager");

  const tokenAdministrator = Cookie.get("token-administrator");

  const { pathname } = window.location;

  if (!!tokenAdministrator && pathname.includes("/adm")) {
    hasToken = true;
  } else if (!!tokenSupervisor && pathname.includes("/spv")) {
    hasToken = true;
  } else if (!!tokenManager && pathname.includes("/dashboard")) {
    hasToken = true;
  } else {
    if (pathname.includes("/adm")) {
      Cookie.remove("token-administrator");
    } else if (pathname.includes("/spv")) {
      Cookie.remove("token-supervisor");
    } else {
      Cookie.remove("token-manager");
    }
    redirect_to_path_when_not_auth = !!tokenAdministrator
      ? "/adm"
      : !!tokenSupervisor
      ? "/spv"
      : !!tokenManager
      ? "/dashboard"
      : "/login";
    hasToken = false;
  }

  return { isAuth: hasToken, redirect: redirect_to_path_when_not_auth };
};

export const isLogged = () => {
  const tokenSupervisor = Cookie.get("token-supervisor");
  const tokenManager = Cookie.get("token-manager");
  const tokenAdministrator = Cookie.get("token-administrator");

  if (!!tokenSupervisor || !!tokenManager || !!tokenAdministrator) {
    return true;
  } else {
    return false;
  }
};

export const isMobile = () => window.innerWidth < DEVICES_WIDTH.LARGE;
export const isMedium = () => window.innerWidth < DEVICES_WIDTH.MEDIUM;

export const debounce = (fn, time = 500) => {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(this, arguments), time);
  };
};
