import "./index.css";
import "./styles/index.scss";
import "./i18n";
import "./polifyll";

import React from "react";
import ReactDOM from "react-dom/client";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";

import App from "./App";
import SplashScreen from "templates/SplashScreen";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

moment.locale("pt-BR");
momentLocalizer();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Suspense fallback={<SplashScreen />}>
    <App />
  </React.Suspense>
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration?.waiting) {
      registration?.waiting.postMessage({ type: "SKIP_WAITING" });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  },
});

reportWebVitals();
