const INITIAL_STATE = {
  video_roles: {
    loading: false,
    loaded: false,
    error: "",
    data: [],
  },
};

export const Types = {
  REQUEST_VIDEO_ROLES: "training-get-video-roles-request",
  SUCCESS: "get-training-success",
  FAILURE: "get-training-failure",
};

export function training(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST_VIDEO_ROLES:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          loading: true,
          error: "",
        },
      };
    case Types.SUCCESS:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          loading: false,
          data: action.payload.data,
          loaded: true,
        },
      };
    case Types.FAILURE:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          loading: false,
          error: action.payload.data,
        },
      };
    default:
      return state;
  }
}

export const Creators = {
  requestVideoRoles: ({ key = "video_roles" } = {}) => ({
    type: Types.REQUEST_VIDEO_ROLES,
    payload: { key },
  }),
  success: ({ key, data }) => ({
    type: Types.SUCCESS,
    payload: { key, data },
  }),
  failure: ({ key, data }) => ({
    type: Types.FAILURE,
    payload: { key, data },
  }),
};
