const INITIAL_STATE = {
  show: false,
  content: null,
  variant: null
};

/**
 * TYPES
 */

export const Types = {
  TRIGGER_NOTIFICATION: "trigger-notification",
  HIDE_NOTIFICATION: "hide-notification"
};
/**
 * REDUCER
 */
export function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.TRIGGER_NOTIFICATION:
      return {
        ...state,
        show: true,
        content: action.payload.content,
        variant: action.payload.variant
      };
    case Types.HIDE_NOTIFICATION:
      return INITIAL_STATE;
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  trigger: (content, variant = "information") => ({
    type: Types.TRIGGER_NOTIFICATION,
    payload: { content, variant }
  }),
  hide: () => ({
    type: Types.HIDE_NOTIFICATION
  })
};
