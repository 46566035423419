import styled from "styled-components";
import colors from "assets/colors";

export const SnackbarContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 21rem;
  border-radius: 1rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
  background-color: ${(props) => props.color || colors.red};
  padding: 0.813rem;
  box-sizing: border-box;
  pointer-events: auto;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translate(0, 20px);
  opacity: 0;
  transition-delay: 0.2s;
  margin-right: 1.3rem;
  overflow: hidden;
  transform: none;
  opacity: 1;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 0.875rem;
  font-family: "Muli Bold", sans-serif;
  color: ${(props) => props.color || colors.white};
  margin-left: 0.5rem;
  line-height: 1.125rem;
`;

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0.5rem;

  & > * {
    margin-right: 0.5rem;
  }

  & > *:last-child {
    margin-right: 0;
  }

  & > *:first-child {
    margin-left: 2rem;
  }
`;

export const Content = styled.span`
  width: 100%;
  padding-left: 2rem;
  padding-top: 0.625rem;
  overflow: hidden;
  > p {
    font-size: 0.875rem;
    font-family: "Muli", sans-serif;
    line-height: 1.125rem;
    color: ${(props) => props.color || colors.white};
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 0.625rem;
  > p {
    color: ${(props) => props.color || colors.white};
    font-family: "Muli", sans-serif;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-decoration: underline;
    cursor: pointer;
  }
`;
