import React from "react";
import PropTypes from "prop-types";
import { Input } from "@material-ui/core";

import colors from "assets/colors";

InputCustom.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.string,
  customStyles: PropTypes.object,
};

InputCustom.defaultProps = {
  value: "",
  name: "",
  placeholder: "",
  onChange() {},
  invalid: false,
  error: "",
  customStyles: {},
};

function InputCustom({
  value,
  name,
  placeholder,
  onChange,
  invalid,
  error,
  customStyles,
  ...rest
}) {
  return (
    <Input
      value={value}
      name={name}
      onChange={onChange}
      error={invalid}
      helperText={error}
      placeholder={placeholder}
      {...rest}
      autoComplete="off"
      style={{
        fontSize: "0.875rem",
        width: "100%",
        color: colors.black,
        ...customStyles,
      }}
    />
  );
}

export default InputCustom;
