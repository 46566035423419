import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/InfoRounded";
import CloseIcon from "@material-ui/icons/Close";

export const getTypeToast = (variant) => {
  const stylesIcon = {
    borderRadius: "0.625rem",
    fontSize: "1.563rem",
  };

  switch (variant) {
    case "danger":
      return {
        icon: (
          <CloseIcon
            style={{
              ...stylesIcon,
              color: "#fff",
              backgroundColor: "#EB5F5F",
            }}
          />
        ),
        color: "#EB5F5F",
      };
    case "success":
      return {
        icon: (
          <CheckIcon
            style={{
              ...stylesIcon,
              color: "#fff",
              backgroundColor: "#7FC837",
            }}
          />
        ),
        color: "#7FC837",
      };
    case "warning":
      return {
        icon: (
          <WarningIcon
            style={{
              ...stylesIcon,
              color: "#ECC561",
              backgroundColor: "#fff",
            }}
          />
        ),
        color: "#ECC561",
      };
    case "information":
      return {
        icon: (
          <InfoIcon
            style={{
              ...stylesIcon,
              color: "#2D9CDB",
              backgroundColor: "#fff",
            }}
          />
        ),
        color: "#2D9CDB",
      };
    default:
      break;
  }
};
