import styled from 'styled-components'
import colors from 'assets/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.188rem;
  box-sizing: border-box;
  border-radius: 0.125rem;
  background-color: ${props => props.color};
  border-color: ${props => props.border};
  border-width: 1px;
  border-style: solid;
`;

export const Text = styled.span`
  color: ${props => props.colorText};
  font-size: 0.75rem;
  font-family: "Muli", sans-serif;
  line-height: 1.125rem;
`;