import React from "react";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";

import { Text } from "libs/purple-ui";

import { Icon } from "@iconify/react";

import * as S from "./styles";

/**
 * Componente Checkbox
 *
 * ## Parâmetros
 * @param {boolean} checked - Define se o checkbox está marcado ou não (default: false)
 * @param {function} onChange - O callback chamado quando o estado do checkbox é alterado
 * @param {boolean} disabled - Define se o checkbox está desabilitado (default: false)
 * @param {string} text - O texto exibido junto ao checkbox (default: "")
 * @param {string} description - Uma descrição opcional para o checkbox (default: "")
 *
 * ## Exemplo de uso
 * @example
 * <Checkbox
 *  checked={true}
 *  onChange={(e) => console.log('Checkbox marcado')}
 *  disabled={false}
 *  text="Aceito os termos e condições"
 *  description="Ao marcar esta opção, você concorda com os termos e condições"
 * />
 *
 * @returns {node} - Retorna um elemento de caixa de seleção
 */
export const Checkbox = ({
  checked = false,
  onChange = () => {},
  disabled = false,
  text = "",
  description = "",
}) => {
  const theme = useTheme();

  const handleClick = () => {
    if (!disabled) {
      onChange();
    }
  };
  return (
    <S.ContainerCheckbox disabled={disabled}>
      <S.CheckboxWrapper>
        <S.Checkbox onClick={handleClick} disabled={disabled} checked={checked}>
          {checked && (
            <Icon
              icon="f7:checkmark-alt"
              color={disabled ? theme.colors.grey_2 : theme.colors.white_pure}
            />
          )}
        </S.Checkbox>
        <S.Text disabled={disabled} onClick={handleClick}>
          {text}
        </S.Text>
      </S.CheckboxWrapper>
      <S.ContainerDescription>
        <Text color={theme.colors.grey_3}>{description}</Text>
      </S.ContainerDescription>
    </S.ContainerCheckbox>
  );
};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string,
  description: PropTypes.string,
};
