const INITIAL_STATE = {
    length: 0
  };
  
  /**
   * TYPES
   */
  
  export const Types = {
    ADDRESS_LENGTH: "change-label-modal",
  };
  /**
   * REDUCER
   */
  export function address(state = INITIAL_STATE, action) {
    switch (action.type) {
      case Types.ADDRESS_LENGTH:
        return {
            ...state,
            length: action.payload.length,
        };
      default:
        return state;
    }
  }
  
  /**
   * ACTIONS
   */
  export const Creators = {
    setAddressLength: ( length ) => ({
      type: Types.ADDRESS_LENGTH,
      payload: { length }
    })
  };
  