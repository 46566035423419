const INITIAL_STATE = {
  tab: 0,
};

/**
 * TYPES
 */

export const Types = {
  CHANGE_TAB_SUBSCRIPTION: "CHANGE_TAB_SUBSCRIPTION",
};

/**
 * REDUCER
 */
export function tabSubscription(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CHANGE_TAB_SUBSCRIPTION:
      return {
        ...state,
        tab: action.payload.tab,
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  changeTab: (tab) => ({
    type: Types.CHANGE_TAB_SUBSCRIPTION,
    payload: { tab },
  }),
};
