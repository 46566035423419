import styled from "styled-components";
import colors from "assets/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BodyContainer = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;
export const Title = styled.h1`
  display: flex;
  font-family: "Muli Bold", sans-serif;
  font-weight: regular;
  font-size: 2.375rem;
  line-height: 2.981rem;
  text-align: center;
  color: ${colors.black};

  @media (max-width: 1100px) {
    font-size: 1.875rem;
    line-height: 2.353rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.883rem;
  }
`;

export const Subtitle = styled.h2`
  font-family: "Muli Bold", sans-serif;
  font-size: 1.125rem;
  font-weight: regular;
  margin-top: 1.5rem;
  line-height: 1.412rem;
  text-align: center;
  color: ${colors.black};

  @media (max-width: 1100px) {
    font-size: 1rem;
    line-height: 1.255rem;
  }
  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.098rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop};
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4.438rem;

  button + button {
    margin-left: 1rem;
  }
`;
