import * as React from "react";
const SvgAccountingBill = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.996 6.997h14.008c.369 0 .667.298.667.667v8.672a.667.667 0 0 1-.667.667H4.996a.667.667 0 0 1-.668-.667V7.664c0-.369.3-.667.668-.667Zm.667 2.001a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0ZM12 14.001A2.001 2.001 0 1 1 12 10 2.001 2.001 0 0 1 12 14Zm5.003 1a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0Z"
      clipRule="evenodd"
    />
    <mask
      id="accounting-bill_svg__a"
      width={16}
      height={12}
      x={4}
      y={6}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M4.996 6.997h14.008c.369 0 .667.298.667.667v8.672a.667.667 0 0 1-.667.667H4.996a.667.667 0 0 1-.668-.667V7.664c0-.369.3-.667.668-.667Zm.667 2.001a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0ZM12 14.001A2.001 2.001 0 1 1 12 10 2.001 2.001 0 0 1 12 14Zm5.003 1a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0Z"
        clipRule="evenodd"
      />
    </mask>
  </svg>
);
export default SvgAccountingBill;
