import * as React from "react";
const SvgCheck1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path stroke="currentColor" d="M19.671 4.328 8.665 19.67l-4.337-4.336" />
  </svg>
);
export default SvgCheck1;
