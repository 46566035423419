export const font = {
  family: {
    regular: "Muli",
    semibold: "Muli SemiBold",
    bold: "Muli Bold",
  },
  size: {
    xxs: "0.625rem",
    xs: "0.75rem",
    s: "0.875rem",
    ms: "1rem",
    m: "1.125rem",
    mt: "1.25rem",
    l: "1.625rem",
    xl: "1.875rem",
    xxl: "2.25rem",
  },
  lineHeight: {
    small: "120%",
    regular: "130%",
    medium: "140%",
    large: "150%",
  },
  letterSpacing: {
    xxs: "-0.063rem",
    xs: "0rem",
  },
};
