import { pt_BR } from "translations/locales/pt";
import { es_ES } from "translations/locales/es";
import { en_US } from "translations/locales/en";

const config = {
  pt: {
    ...pt_BR,
  },
  es: {
    ...es_ES,
  },
  en: {
    ...en_US,
  }
};

export default config;
