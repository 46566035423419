import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as NPSActions } from "store/ducks/searchNPS";

export function* getSearchNPS(action) {
  try {
    const { data } = yield call(
      MainService.get,
      "v2/user/satisfaction_research"
    );
    yield put(
      NPSActions.success({
        can_response_nps: data.can_response_nps,
      })
    );
  } catch (error) {
    yield put(NPSActions.failure(error.message));
  }
}
