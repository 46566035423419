import { call, put } from "redux-saga/effects";
import MainService from "../../services/mainService";

import { Creators as HelpLinkActions } from "../ducks/helpLink";

export function* getHelpLink(action) {
  try {
    const { data } = yield call(MainService.get, "v2/help_desk_whatsapp");
    yield put(HelpLinkActions.helpLinkSuccess(data));
  } catch (error) {
    yield put(HelpLinkActions.helpLinkFailure(error.message));
  }
}
