const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: [],
};

// TYPES
export const Types = {
  GET_USER_REQUEST: "get-user-request",
  GET_USER_REQUEST_WITH_SUBSCRIPTION: "get-user-request-with-subscription",
  GET_USER_SUCCESS: "get-user-success",
  GET_USER_FAILURE: "get-user-failure",
  UPDATE_FINANCIAL_EMAIL: "update-financial-email-big-company",
  UPDATE_EMAIL: "config-update-email-big-company",
  UPDATE_USERNAME_PROFILE: "update-username-profile-big-company",
  UPDATE_WHATSAPP: "config-update-whatsapp-big-company",
  UPDATE_PIX_ADM: "config-update-pix-adm",
  RESET: "reset-user-initial-state",
};

// REDUCER
export function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_USER_REQUEST:
      return { ...state, loaded: false, loading: true, error: "" };
    case Types.GET_USER_REQUEST_WITH_SUBSCRIPTION:
      return { ...state, loaded: false, loading: true, error: "" };
    case Types.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.GET_USER_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    case Types.UPDATE_FINANCIAL_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          big_company: {
            ...state.data.big_company,
            financial_email: {
              ...state?.data?.big_company?.financial_email,
              ...action.payload.data,
            },
          },
        },
      };
    case Types.UPDATE_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          email: action.payload.data,
        },
      };
    case Types.UPDATE_USERNAME_PROFILE:
      return {
        ...state,
        data: {
          ...state.data,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
        },
      };
    case Types.UPDATE_WHATSAPP:
      return {
        ...state,
        data: {
          ...state.data,
          username: action.payload.data,
        },
      };
    case Types.UPDATE_PIX_ADM:
      return {
        ...state,
        data: {
          ...state.data,
          big_company: {
            ...state.data.big_company,
            pix: {
              ...state?.data?.big_company?.pix,
              ...action.payload.data,
            },
          },
        },
      };
    case Types.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}

// ACTIONS
export const Creators = {
  getUserRequest: (data) => ({
    type: Types.GET_USER_REQUEST,
    payload: { data },
  }),
  requestUserAndSubscription: (data) => ({
    type: Types.GET_USER_REQUEST_WITH_SUBSCRIPTION,
    payload: { data },
  }),
  getUserSuccess: (data) => ({
    type: Types.GET_USER_SUCCESS,
    payload: { data },
  }),
  getUserFailure: (data) => ({
    type: Types.GET_USER_FAILURE,
    payload: { data },
  }),
  updateFinancialEmail: (data) => ({
    type: Types.UPDATE_FINANCIAL_EMAIL,
    payload: { data },
  }),
  updateEmail: (data) => ({
    type: Types.UPDATE_EMAIL,
    payload: { data },
  }),
  updateUsernameProfile: ({first_name, last_name}) => ({
    type: Types.UPDATE_USERNAME_PROFILE,
    payload: { first_name, last_name},
  }),
  updateWhatsapp: (data) => ({
    type: Types.UPDATE_WHATSAPP,
    payload: { data },
  }),
  updatePix: (data) => ({
    type: Types.UPDATE_PIX_ADM,
    payload: { data },
  }),
  reset: () => ({
    type: Types.RESET,
    payload: null
  }),
};
