import styled from "styled-components"
import colors from "assets/colors";

export const Footer = styled.footer`

  width: 100%;
  padding: 1rem 2.5rem;
  font-family: "Muli", sans-serif;
  line-height: 1.412rem;
  color: ${colors.black};
  font-size: 1.125rem;
  text-align: center;

  @media (max-width: 768px) {
    border-top: 0.063rem solid ${colors.disable};
    padding: 0.5rem 1.875rem;
    font-size: 0.75rem;
    line-height: 0.938rem;
  }
`;
