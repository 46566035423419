import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";

import { DeferredPromptProvider } from "ContextAPI/PWAEventContext";
import { initializeOneSignal } from "hooks/useOneSignal";

import WrapperErrorBoundary from "templates/ErrorPage/useErrorBoundary";
import { ThemeProvider } from "templates/Theme";
import { ThemeProvider as ThemeProviderPurple } from "libs/purple-ui";
import { store, persistor } from "./store";
import Routes from "./routes";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
      debug: false,
      titleCase: false,
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    });

    initializeOneSignal();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProviderPurple>
          <ThemeProvider>
            <QueryClientProvider client={queryClient}>
              <WrapperErrorBoundary>
                <DeferredPromptProvider>
                  <Routes />
                </DeferredPromptProvider>
              </WrapperErrorBoundary>
            </QueryClientProvider>
          </ThemeProvider>
        </ThemeProviderPurple>
      </PersistGate>
    </Provider>
  );
};

export default App;
