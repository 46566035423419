const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: [],
};

/**
 * TYPES
 */

export const Types = {
  GET_CATEGORIES_REQUEST: "get-categories-request",
  GET_CATEGORIES_SUCCESS: "get-categories-success",
  GET_CATEGORIES_FAILURE: "get-categories-failure",
};
/**
 * REDUCER
 */
export function categories(state = INITIAL_STATE, action) {
  switch (action.type) {
    // JOB SETTINGS
    case Types.GET_CATEGORIES_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.GET_CATEGORIES_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  // JOB SETTINGS
  getCategoriesRequest: (data) => ({
    type: Types.GET_CATEGORIES_REQUEST,
    payload: { data },
  }),
  getCategoriesSuccess: (data) => ({
    type: Types.GET_CATEGORIES_SUCCESS,
    payload: { data },
  }),
  getCategoriesFailure: (data) => ({
    type: Types.GET_CATEGORIES_FAILURE,
    payload: { data },
  }),
};
