import styled from "styled-components";
import colors from "assets/colors";

export const Nav = styled.p`
  font-size: 0.875rem;
  font-family: "Muli", sans-serif;
  line-height: 1.125rem;
  color: ${(props) => props.color || colors.white};
  > span:first-child {
    word-break: break-word;
    margin-right: 1rem;
  }
  > span:last-child {
    cursor: pointer;
    text-decoration: underline;
    text-align: justify;
  }
`;
