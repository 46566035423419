const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: null
};

/**
 * TYPES
 */

export const Types = {
  TIPS_ESTABLISHMENT_REQUEST: "tips-establishment-request",
  TIPS_ESTABLISHMENT_SUCCESS: "tips-establishment-success",
  TIPS_ESTABLISHMENT_FAILURE: "tips-establishment-failure"
};
/**
 * REDUCER
 */
export function tipsEstablishment(state = INITIAL_STATE, action) {
  switch (action.type) {
    // JOB SETTINGS
    case Types.TIPS_ESTABLISHMENT_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.TIPS_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true
      };
    case Types.TIPS_ESTABLISHMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  // TIPS ESTABLISHMENT
  tipsEstablishmentRequest: data => ({
    type: Types.TIPS_ESTABLISHMENT_REQUEST,
    payload: { data }
  }),
  tipsEstablishmentSuccess: data => ({
    type: Types.TIPS_ESTABLISHMENT_SUCCESS,
    payload: { data }
  }),
  tipsEstablishmentFailure: data => ({
    type: Types.TIPS_ESTABLISHMENT_FAILURE,
    payload: { data }
  })
};
