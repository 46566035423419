import JSCookie from "js-cookie";

const config = { expires: 365 };

export const Cookie = {
  set: (key, value) => {
    JSCookie.set(key, JSON.stringify(value), { ...config });
  },
  get: (key) => {
    const value = JSCookie.get(key);

    if (value === undefined) return value;

    return JSON.parse(value);
  },
  remove: (key) => {
    JSCookie.remove(key);
  },
};
