import React from "react";
import PropTypes from "prop-types";

import * as S from "./styles";

/**
 * ### Propriedades
 *
 * @param {function} checked: informa o estado do botão. true|false
 * @param {function} onChange: função chamada quando o switch é pressionado, devolve o valor do botão
 * @param {boolean} small: define se o switch será do tipo small
 * @param {boolean} disabled: define se o switch será desabilitado
 *
 * ### Exemplo
 * @example
 *
    <SwitchButton checked={switchOn} onChange={()=>{}} />;
 *
 *
 **/
export const SwitchButton = ({
  checked = false,
  small = false,
  onChange = () => {},
  disabled = false,
}) => {
  const handleClick = () => {
    if (!disabled) {
      onChange(!checked);
    }
  };

  return (
    <S.SwitchButtonWrapper
      small={small}
      checked={checked}
      onClick={handleClick}
      disabled={disabled}
    >
      <S.Slider small={small} checked={checked} disabled={disabled} />
    </S.SwitchButtonWrapper>
  );
};

SwitchButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  small: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
