const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: {},
};

export const Types = {
  REQUEST: "v2-get-subscription-request",
  SUCCESS: "v2-get-subscription-success",
  FAILURE: "v2-get-subscription-failure",
};

export function subscription(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return { ...state, loading: true, loaded: false, error: "" };
    case Types.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
      };
    default:
      return state;
  }
}

export const Creators = {
  request: ({ user = "establishment", userId }) => ({
    type: Types.REQUEST,
    payload: { user, userId },
  }),
  success: (data) => ({
    type: Types.SUCCESS,
    payload: { data },
  }),
  failure: (data) => ({
    type: Types.FAILURE,
    payload: { data },
  }),
};
