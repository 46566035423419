const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: []
};

/**
 * TYPES
 */

export const Types = {
  LANGUAGES_REQUEST: "languages-request",
  LANGUAGES_SUCCESS: "languages-success",
  LANGUAGES_FAILURE: "languages-failure"
};
/**
 * REDUCER
 */
export function languages(state = INITIAL_STATE, action) {
  switch (action.type) {
    // LANGUAGES
    case Types.LANGUAGES_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.LANGUAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true
      };
    case Types.LANGUAGES_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  // LANGUAGES
  languagesRequest: data => ({
    type: Types.LANGUAGES_REQUEST,
    payload: { data }
  }),
  languagesSuccess: data => ({
    type: Types.LANGUAGES_SUCCESS,
    payload: { data }
  }),
  languagesFailure: data => ({
    type: Types.LANGUAGES_FAILURE,
    payload: { data }
  })
};
