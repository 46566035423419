import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as BigcompanyProfileActions } from "store/ducks/bigcompany/profile";

export function* getBigcompanyProfile(action) {
  try {
    const { data } = yield call(
      MainService.get,
      `v2/bigcompany/${action.payload.pk}`
    );

    yield put(BigcompanyProfileActions.onSuccess({ data }));
  } catch (error) {
    yield put(BigcompanyProfileActions.onError({ data: error.message }));
  }
}
