import { call, put } from "redux-saga/effects";
import MainService from "../../services/mainService";

import { Creators as JobSettingsActions } from "../ducks/jobSettings";

export function* getJobSettings(action) {
  try {
    const { data } = yield call(MainService.get, "v2/jobs/jobs_settings");

    yield put(JobSettingsActions.jobSettingsSuccess(data));
  } catch (error) {
    if (error?.response && error?.response?.status !== 401) {
      yield put(JobSettingsActions.jobSettingsFailure(error.message));
    }
  }
}
