import React from "react";
import PropTypes from "prop-types";

import { Tooltip as MUITooltip, withStyles, Fade } from "@material-ui/core";

import colors from "assets/colors";

const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: colors.black,
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid " + colors.black,
    lineHeight: theme.typography.pxToRem(15.06),
    fontFamily: ["Muli", "sans-serif"].join(","),
    textAlign: "center",
    padding: theme.typography.pxToRem(8),
  },
}))(MUITooltip);

function CustomTooltip({ children, ...rest }) {
  return (
    <Tooltip
      {...rest}
      TransitionProps={{ enter: 0, exit: 0, unmountOnExit: true }}
      TransitionComponent={Fade}
    >
      {children}
    </Tooltip>
  );
}

CustomTooltip.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.node,
};

CustomTooltip.defaultProps = {
  title: "",
};

export default CustomTooltip;
