import "./styles.css";

import { toast, Toaster as SonnerToaster } from "sonner";

export const Toaster = (props) => {
  return (
    <SonnerToaster
      position="top-center"
      richColors
      closeButton
      toastOptions={{
        style: {
          padding: 16,
          gap: 10,
        },
        classNames: {
          title: "sonner__toast-title",
          description: "sonner__toast-description",
        },
      }}
      {...props}
    />
  );
};

export const Toast = {
  success(title = "", description = "") {
    toast.success(title, { description });
  },
  error(title = "", description = "") {
    toast.error(title, { description });
  },
  warning(title = "", description = "") {
    toast.warning(title, { description });
  },
  info(title = "", description = "") {
    toast.info(title, { description });
  },
};
