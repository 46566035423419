import React from "react";
import PropTypes from "prop-types";

import ErrorMessage from "components/ErrorMessage";

Error.propTypes = {
  message: PropTypes.string,
};

Error.defaultProps = {
  message: "",
};

/**
 * @param { string } message - Mesangem de erro a ser renderizada
 */
function Error({ message }) {
  return <ErrorMessage error={message} />;
}

export default Error;
