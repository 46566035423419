const INITIAL_STATE = {
  bigcompany: {
    loading: false,
    loaded: false,
    error: "",
    data: [],
  },
};

export const Types = {
  PAYMENT_PROFILE_REQUEST: "bigcompany-payment-profile-request",
  PAYMENT_PROFILE_SUCCESS: "bigcompany-payment-profile-success",
  PAYMENT_PROFILE_FAILURE: "bigcompany-payment-profile-failure",
  UPDATE_PAYMENT_PROFILE: "bigcompany-payment-profile-update",
};

export function paymentProfile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PAYMENT_PROFILE_REQUEST:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loaded: false,
          loading: true,
          error: "",
        },
      };
    case Types.PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: false,
          data: action.payload.data,
          loaded: true,
        },
      };
    case Types.PAYMENT_PROFILE_FAILURE:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: false,
          error: action.payload.data,
        },
      };
    case Types.UPDATE_PAYMENT_PROFILE:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
}

export const Creators = {
  request: ({ data, user = "bigcompany" }) => ({
    type: Types.PAYMENT_PROFILE_REQUEST,
    payload: { data, user },
  }),
  success: ({ data, user = "bigcompany" }) => ({
    type: Types.PAYMENT_PROFILE_SUCCESS,
    payload: { data, user },
  }),
  failure: ({ data, user = "bigcompany" }) => ({
    type: Types.PAYMENT_PROFILE_FAILURE,
    payload: { data, user },
  }),
  update: ({ data, user = "bigcompany" }) => ({
    type: Types.UPDATE_PAYMENT_PROFILE,
    payload: { data, user },
  }),
};
