import { useState, useCallback, useRef, useEffect } from "react";

export default function useDebounce(value, callback, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const handler = useRef();

  useEffect(() => {
    handler.current = setTimeout(() => {
      if (value !== undefined) {
        if(debouncedValue !== value){
          setDebouncedValue(value);
          callback && callback(value);
        }
      } else {
        setDebouncedValue("");
      }
    }, delay);

    return () => {
      clearTimeout(handler.current);
    };
  }, [value]);

  const cancelDebounce = useCallback(() => {
    clearTimeout(handler.current);
  }, [handler.current])

  return [debouncedValue, setDebouncedValue, cancelDebounce];
}
