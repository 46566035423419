export const HIGH_PRIORITY_MODAL_ID = "MODAL_LIMITED_ACCESS";

export const ModalSizes = {
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

const INITIAL_STATE = {
  show: false,
  title: "",
  content: null,
  size: ModalSizes.lg,
  onClose: () => {},
  id: null,
};

/**
 * TYPES
 */

export const Types = {
  SHOW_MODAL: "show-modal",
  HIDE_MODAL: "hide-modal",
  RESIZE_MODAL: "resize-modal",
  CHANGE_MODAL: "change-title-modal",
};

/**
 * REDUCER
 */
export function modal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SHOW_MODAL:
      // Temporário para evitar que outros modais substituam o modal de acesso limitado
      if (HIGH_PRIORITY_MODAL_ID === state.id) return state;

      return {
        ...state,
        show: true,
        title: action.payload.title,
        content: action.payload.content,
        size: action.payload.size,
        onClose: action.payload.onClose,
        id: action.payload.id,
      };
    case Types.HIDE_MODAL:
      return INITIAL_STATE;
    case Types.RESIZE_MODAL:
      return {
        ...state,
        size: action.payload.size,
      };
    case Types.CHANGE_MODAL:
      return {
        ...state,
        title: action.payload.title,
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  show: (
    title,
    content,
    size = ModalSizes.lg,
    onClose = () => {},
    id = null
  ) => ({
    type: Types.SHOW_MODAL,
    payload: { title, content, size, onClose, id },
  }),
  hide: () => {
    return { type: Types.HIDE_MODAL };
  },
  changeSize: (size) => ({
    type: Types.RESIZE_MODAL,
    payload: { size },
  }),
  changeTitle: (title) => ({
    type: Types.CHANGE_MODAL,
    payload: { title },
  }),
};
