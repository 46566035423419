import React from "react";
import PropTypes from "prop-types";
import { List, FormControl, ListItem, ListItemText } from "@material-ui/core";

import Error from "components/modalJobAndInterview/components/form/error";

import * as S from "./styles";

Field.propTypes = {
  label: PropTypes.string,
  labelComponent: PropTypes.node,
  description: PropTypes.string,
  children: PropTypes.any,
  invalid: PropTypes.bool,
  error: PropTypes.string,
  renderAttrs: PropTypes.boolean,
};

Field.defaultProps = {
  label: "",
  labelComponent: null,
  description: "",
  children: null,
  invalid: false,
  error: "",
  renderAttrs: true,
};

/**
 * @param { string } label - Label do campo
 * @param { node } labelComponent - Componente a ser renderizado como label
 * @param { string } description - Descrição do campo
 * @param { any } children - Conteúdo a ser renderizado como um campo
 * @param { boolean } invalid - Determina se o campo é ou não válido
 * @param { string } error - Mensagem de erro resultante da invalidação do campo
 * @param { boolean } renderAttrs - Define se o campo deve renderizar label
 * e erro ou apenas servir de wrapper.
 */
function Field({
  label = "",
  labelComponent = null,
  description = "",
  children = null,
  invalid = false,
  error = "",
  renderAttrs = true,
  area = "",
  style = {},
  ...rest
}) {
  return (
    <S.Container area={area} style={style} {...rest}>
      {labelComponent ? labelComponent : <S.Label>{label}</S.Label>}
      {description && <S.Description>{description}</S.Description>}
      {children}
      {!!renderAttrs && invalid && <Error message={error} />}
    </S.Container>
  );
}

export default Field;
