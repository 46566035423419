import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import { Spinner, useRipple } from "libs/purple-ui";

import * as S from "./styles";

/**
 * ### Parâmetros
 * @param {svg} icon - Componente SVG
 * @param {string?} color
 * @param {string?} background cor do background que envolve o icon
 * @param {boolean?} disabled
 * @param {boolean?} loading - Exibe um spinner de loading padrão em escala de cinza. O botão se torna inativo enquanto loading for `true`
 * @param {function} onClick
 * @param {object?} buttonProps - Todas as propriedades aplicáveis a um `<button />`
 * @param {string?} size - Tamanho do botão em px ou rem
 * @see libs\streamline\index.jsx
 *
 * @example
 * <IconButton
 *  icon={SLAdd}
 *  color={theme.colors.primary_1}
 *  onClick={() => doSomething()}
 *  disabled={false}
 *  loading={false}
 *  buttonProps={{ onFocus: (event) => doSomething(), type: "submit" }}
 *  size="2rem"
 * />
 */
export function IconButton({
  icon,
  color,
  background,
  disabled,
  loading,
  onClick,
  buttonProps,
  size,
}) {
  const theme = useTheme();
  const ref = useRef();

  const _color = color || theme.colors.grey_3;

  const ripples = useRipple({ ref, color: _color });

  const handleClick = (event) => {
    if (loading || disabled) return;
    onClick?.();
  };

  return (
    <S.Container
      {...buttonProps}
      onClick={handleClick}
      disabled={disabled}
      ref={ref}
      color={_color}
      background={background}
      size={size}
    >
      {ripples}
      {loading ? (
        <S.LoadingContainer>
          <Spinner color={[theme.colors.grey_2, theme.colors.grey_3]} />
        </S.LoadingContainer>
      ) : (
        <>{React.cloneElement(icon)}</>
      )}
    </S.Container>
  );
}

IconButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  buttonProps: PropTypes.object,
  size: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  loading: false,
  onClick() {},
  buttonProps: {},
  iconProps: {},
  size: "2rem",
};
