import styled from "styled-components";
import colors from "assets/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 0.25rem;

  > span {
    font-family: "Muli Bold", sans-serif;
    font-size: 0.875rem;
    line-height: 0.941rem;
    color: ${colors.black};
    padding-left: 0.375rem;
  }
`;

export const ContainerStarts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Star = styled.div`
  padding-right: 0.5rem;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
  }
`;
