import * as React from "react";
const SvgQuestionCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3.995A8.005 8.005 0 1 0 20.005 12 8.014 8.014 0 0 0 12 3.995Zm0 12.675a1 1 0 1 1 0-2.002 1 1 0 0 1 0 2.002Zm.667-3.445c0-.265.157-.505.4-.611a2.668 2.668 0 1 0-3.735-2.445.667.667 0 0 0 1.334 0 1.334 1.334 0 1 1 1.868 1.222 2.001 2.001 0 0 0-1.201 1.834.667.667 0 0 0 1.334 0Z"
      clipRule="evenodd"
    />
    <mask
      id="question-circle_svg__a"
      width={18}
      height={18}
      x={3}
      y={3}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 3.995A8.005 8.005 0 1 0 20.005 12 8.014 8.014 0 0 0 12 3.995Zm0 12.675a1 1 0 1 1 0-2.002 1 1 0 0 1 0 2.002Zm.667-3.445c0-.265.157-.505.4-.611a2.668 2.668 0 1 0-3.735-2.445.667.667 0 0 0 1.334 0 1.334 1.334 0 1 1 1.868 1.222 2.001 2.001 0 0 0-1.201 1.834.667.667 0 0 0 1.334 0Z"
        clipRule="evenodd"
      />
    </mask>
  </svg>
);
export default SvgQuestionCircle;
