import { useSelector } from "react-redux";
import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as BigcompanySettingsActions } from "store/ducks/bigcompany/settings";

export function* getBigcompanySettings(action) {
  try {
    const { data } = yield call(MainService.get, `v2/bigcompany/${action.payload.pk}/job_settings`);
    yield put(BigcompanySettingsActions.onSuccess({ data }));
  } catch (error) {
    yield put(BigcompanySettingsActions.onFailure({ data: error.message }));
  }
}
