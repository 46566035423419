import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  gap: ${({ spacing }) => spacing};
  padding-top: ${({ column }) => (column ? "0.5rem" : 0)};
`;

export const Separator = styled.div`
  height: 1rem;
`;
