import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import isPropValid from "@emotion/is-prop-valid";
import { StyleSheetManager } from "styled-components";

import { defaultTheme } from "libs/purple-ui/shared/theme";

function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

export const ThemeProvider = ({ theme, children }) => {
  const __theme__ = {
    purpleUi: {
      colors: Object.assign({}, defaultTheme?.colors, theme?.colors),
      font: {
        family: Object.assign(
          {},
          defaultTheme?.font?.family,
          theme?.font?.family
        ),
        size: Object.assign({}, defaultTheme?.font?.size, theme?.font?.size),
        letterSpacing: Object.assign(
          {},
          defaultTheme?.font?.letterSpacing,
          theme?.font?.letterSpacing
        ),
        lineHeight: Object.assign(
          {},
          defaultTheme?.font?.lineHeight,
          theme?.font?.lineHeight
        ),
      },
      headings: Object.assign({}, defaultTheme?.headings, theme?.headings),
      borderRadius: Object.assign(
        {},
        defaultTheme?.borderRadius,
        theme?.borderRadius
      ),
      ...(theme?.customAttrs || {}),
    },
  };

  return (
    <StyledThemeProvider theme={__theme__.purpleUi}>
      <StyleSheetManager
        shouldForwardProp={shouldForwardProp}
        enableVendorPrefixes
      >
        {children}
      </StyleSheetManager>
    </StyledThemeProvider>
  );
};
