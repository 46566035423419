import styled, { css, keyframes } from "styled-components";

export const TextGroup = styled.p`
  font-family: ${(props) => (props.bold ? "Muli Bold" : "Muli")}, sans-serif;
  font-size: 0.875rem;
  line-height: 1.098rem;
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  border-bottom: ${(props) => (props.bordered ? "1px dashed #d6d6d6" : "none")};
  padding-bottom: ${(props) => (props.pb ? props.pb : 0)};
  padding-top: ${(props) => (props.pt ? props.pt : 0)};
  padding-left: ${(props) => (props.pl ? props.pl : 0)};
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};

  > strong {
    font-family: "Muli Bold", sans-serif;
    margin-bottom: 0.25rem;
  }
  > p {
    align-items: center;
    display: ${(props) => (props.dflex ? props.dflex : "grid")};
  }
`;