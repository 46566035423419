import { call, put } from "redux-saga/effects";
import MainService from "../../services/mainService";

import { Creators as LanguagesActions } from "../ducks/languages";

export function* getLanguages(action) {
  try {
    const { data } = yield call(MainService.get, "/languages/");
    yield put(LanguagesActions.languagesSuccess(data));
  } catch (error) {
    yield put(LanguagesActions.languagesFailure(error.message));
  }
}
