import * as React from "react";
const Svg3DBoxCorner = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path stroke="currentColor" d="M12.022 4.359v3.275" />
    <path
      stroke="currentColor"
      d="M12.022 7.634 6.2 10.18l5.822 2.547 5.822-2.547-5.822-2.547Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      d="M6.2 10.18v6.914l5.822 2.547 5.822-2.547v-6.913M12.022 12.728v6.913M4.017 18.183 6.2 17.094M17.844 17.094l2.161 1.079"
    />
  </svg>
);
export default Svg3DBoxCorner;
