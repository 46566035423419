import React from "react";
import { useTranslation } from "react-i18next";

import * as S from "./styles";

const Footer = () => {
  const { t } = useTranslation("common");

  return <S.Footer>{t("copyright")}</S.Footer>;
};

export default Footer;
