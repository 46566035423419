const INITIAL_STATE = {
  data: [],
  next: null,
  count: null
};

/**
 * TYPES
 */
export const Types = {
  SET_EXTRACT_SCROLL: "set-extract-scroll",
};
/**
 * REDUCER
 */
export function extractScroll(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_EXTRACT_SCROLL:
      return {
        ...state,
        data: action.payload.data,
        next: action.payload.next,
        count: action.payload.count
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  setExtract: (data, next, count) => ({
    type: Types.SET_EXTRACT_SCROLL,
    payload: { data, next, count }
  }),
};
