import React, { useEffect, useState } from "react";

import * as S from "./styles";
import colors from "assets/colors";
import TagWidget from "../TagWidget";

import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import DismissIcon from "@iconify-icons/fluent/dismiss-24-regular";
import ReadMore from "../ReadMore";

function CardWidget({
  status = {
    icon: null,
    background: null,
    title: null,
    message: null,
    link: null,
    tags: [],
  },
  onClose = () => {},
  onClick = () => {},
}) {
  const { t } = useTranslation(["dashboard"]);
  const [colorText, setColorText] = useState(colors.white);

  useEffect(() => {
    let color = colors.white;
    if (status.background === "#FFC225") {
      color = colors.black;
    }
    setColorText(color);
  }, [status]);

  return (
    <S.SnackbarContent className="show" color={status?.background}>
      <S.Header>
        <S.ContainerTitle>
          <Icon
            icon={status?.icon}
            style={{
              color: colorText,
              fontSize: "1.5rem",
            }}
          />
          <S.Title color={colorText}>{status?.title}</S.Title>
        </S.ContainerTitle>

        <S.Close onClick={onClose}>
          <Icon
            icon={DismissIcon}
            style={{
              color: colorText,
              fontSize: "1.5rem",
            }}
          />
        </S.Close>
      </S.Header>

      <S.Tags>
        {status?.tags?.map((tag, index) => (
          <TagWidget
            key={index}
            background={status?.background}
            colorText={colorText}
          >
            {tag}
          </TagWidget>
        ))}
      </S.Tags>
      <S.Content color={colorText}>
        {status?.message?.length >= 100 ? (
          <ReadMore text={status?.message} color={colorText} />
        ) : (
          <p>{status?.message}</p>
        )}
        <S.Nav color={colorText}>
          <p onClick={onClick}>{t("dashboard:status_closeer")}</p>
        </S.Nav>
      </S.Content>
    </S.SnackbarContent>
  );
}

export default CardWidget;
