import styled from "styled-components";
import DefaultAvatar from "assets/imgs/user.jpg";
import colors from "assets/colors";

const AvatarSize = {
  width: "4.125rem",
  height: "4.125rem",
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 19.75rem;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Text = styled.p`
  font-family: ${({ label }) =>
    label ? "'Muli Bold', sans-serif" : "'Muli', sans-serif"};
  font-size: ${({ title }) => (title ? "1rem" : "0.875rem")};
  line-height: 1.255rem;
  padding-bottom: ${({ title }) => (title ? "0.25rem" : 0)};
`;

export const TextContainer = styled.div`
  padding-left: 1rem;
`;

export const AvatarImage = styled.img.attrs(({ src }) => ({
  src: src || DefaultAvatar,
}))`
  width: ${AvatarSize.width};
  height: ${AvatarSize.height};
  border-radius: 50%;
  border: 1px solid ${colors.white};
`;
