import styled, { css, keyframes } from "styled-components";
import colors from "assets/colors";

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.625rem;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.412rem;
  color: ${colors.black};
`;

export const SuccessSent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.313rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.3s;
  transition-timing-function: ease-in-out;

  > span {
    font: 1.125rem/1.412rem "Muli Bold", sans-serif;
  }
`;

export const RatingReceived = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > span {
    padding-left: 0.618rem;
    font: 1.125rem/1.412rem "Muli Bold", sans-serif;
  }
`;
