const INITIAL_STATE = {
    page: 0,
    search: "",
    favorite: null,
    role: null,
    doc: null,
    pkCountry: null,
    pkCity: null,
};

/**
 * TYPES
 */

export const Types = {
    CHANGE_FILTER: "change-filter",
};
/**
 * REDUCER
 */
export function filterFavoriteList(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.CHANGE_FILTER:
            return {
                ...state,
                page: action.payload.page,
                search: action.payload.search,
                favorite: action.payload.favorite,
                role: action.payload.role,
                doc: action.payload.doc,
                pkCountry: action.payload.pkCountry,
                pkCity: action.payload.pkCity,
            };
        default:
            return state;
    }
}

/**
 * ACTIONS
 */
export const Creators = {
    changeFilter: (
        filters
    ) => ({
        type: Types.CHANGE_FILTER,
        payload: filters,
    })
};