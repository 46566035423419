import styled, { keyframes } from "styled-components";
import colors from "assets/colors";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  align-items: center;
`;

export const Logo = styled.img`
  max-width: 12.5rem;
  width: 100%;
  display: block;
  margin-top: 5rem;

  @media (max-width: 768px) {
    margin-top: 4rem;
    display: flex;
  }
`;

export const Loader = styled.div`
  max-height: 3.125rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 46.5rem;
  height: 100%;
  justify-content: center;
  padding: 1rem;
  animation: ${fadeIn} 1s forwards;
`;

export const ContainerWorker = styled.div`
  padding-bottom: 1.875rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    & > :nth-child(2) {
      padding-top: 2rem;
    }
    align-items: center;
    flex-direction: column;
  }
`;

export const ContainerRating = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 1.5rem;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const ContainerTextField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const Comment = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${colors.black};
  font-style: italic;
  line-height: 1.098rem;
`;

export const Actions = styled.div`
  padding-bottom: 1rem;
`;

export const ContainerJobInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  > p {
    min-width: 10rem;

    @media (max-width: 768px) {
      width: 100%;
      max-width: 10rem;
      min-width: unset;
    }
  }
`;

export const Title = styled.h3`
  font-family: "Muli Bold", sans-serif;
  color: ${colors.black};

  font-size: 0.875rem;
  line-height: 1.098rem;
`;

export const Header = styled.div`
  width: 100%;
  text-align: center;
  max-width: 50rem;
`;
