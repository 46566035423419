const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: false,
};

/**
 * TYPES
 */
export const Types = {
  REQUEST: "nps-request",
  SUCCESS: "nps-success",
  FAILURE: "nps-failure",
  SET_NPS: "set-nps",
};
/**
 * REDUCER
 */
export function searchNPS(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    case Types.SET_NPS:
      return { ...state, data: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  request: () => ({
    type: Types.REQUEST,
  }),
  success: (data) => ({
    type: Types.SUCCESS,
    payload: { data },
  }),
  failure: (data) => ({
    type: Types.FAILURE,
    payload: { data },
  }),
  setNPS: (data) => ({
    type: Types.SET_NPS,
    payload: { data },
  }),
};
