const INITIAL_STATE = {
  bigcompany: {
    loading: false,
    loaded: false,
    error: "",
    data: [],
  },
  establishment: { loading: false, loaded: false, error: "", data: [] },
  supervisor: { loading: false, loaded: false, error: "", data: [] },
};

/**
 * TYPES
 */

export const Types = {
  NOTIFICATION_PREFERENCES_REQUEST: "notification-preferences-request",
  NOTIFICATION_PREFERENCES_SUCCESS: "notification-preferences-success",
  NOTIFICATION_PREFERENCES_FAILURE: "notification-preferences-failure",
  TOGGLE_NOTIFICATION_PREFERENCES: "toggle-notification-preferences",
};
/**
 * REDUCER
 */
export function notificationPreferences(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NOTIFICATION_PREFERENCES_REQUEST:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: true,
          error: "",
        },
      };
    case Types.NOTIFICATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: false,
          data: action.payload.data,
          loaded: true,
        },
      };
    case Types.NOTIFICATION_PREFERENCES_FAILURE:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: false,
          error: action.payload.data,
        },
      };
    case Types.TOGGLE_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          data: toggleRead(state, action),
        },
      };
    default:
      return state;
  }
}

/**
 * FUNCTIONS
 */

function toggleRead(state, action) {
  let preferences = state[action.payload.user]?.data[action?.payload?.tab];
  let preferencesIndex = preferences.findIndex(
    (item) => item?.pk === action.payload.pk
  );

  if (preferencesIndex !== -1) {
    preferences[preferencesIndex].allowed =
      !preferences[preferencesIndex].allowed;
  }

  return { ...state[action.payload.user]?.data, preferences };
}

/**
 * ACTIONS
 */
export const Creators = {
  notificationPreferencesRequest: ({ user = "establishment" } = {}) => ({
    type: Types.NOTIFICATION_PREFERENCES_REQUEST,
    payload: { user },
  }),
  notificationPreferencesSuccess: ({ data, user = "establishment" }) => ({
    type: Types.NOTIFICATION_PREFERENCES_SUCCESS,
    payload: { data, user },
  }),
  notificationPreferencesFailure: ({ data, user = "establishment" }) => ({
    type: Types.NOTIFICATION_PREFERENCES_FAILURE,
    payload: { data, user },
  }),
  togglePreference: ({ pk, tab, user = "establishment" }) => ({
    type: Types.TOGGLE_NOTIFICATION_PREFERENCES,
    payload: { pk, tab, user },
  }),
};
