export const USERS = {
  establishment: "establishment",
  bigcompany: "bigcompany",
  supervisor: "supervisor",
};

export const isEstablishment = (currentUser) =>
  currentUser === USERS.establishment;

export const renderContentByUser = ({ currentUser, allowedUsers, content }) => {
  const isAllowed = allowedUsers.includes(currentUser);
  return isAllowed ? [content] : [];
};
