import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Icon, InlineIcon } from "@iconify/react";
import colors from "assets/colors";
import PropTypes from "prop-types";

const IconCloseer = ({
  color,
  icon,
  image,
  background,
  style,
  heightIcon = 20,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        padding: 5,
        borderRadius: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: background || theme.colors.primary_1,
        ...style,
      }}
    >
      {icon ? (
        <Icon
          height={heightIcon}
          icon={icon}
          color={color || theme.colors.primary_5}
        />
      ) : (
        image
      )}
    </div>
  );
};

IconCloseer.propTypes = {
  icon: PropTypes.element,
  color: PropTypes.string,
  background: PropTypes.string,
};

export default IconCloseer;
