import React from "react";
import { useWidgetStatus } from "hooks/useWidgetStatus";

import CardWidget from "./components/CardWidget";

import * as S from "./styles";

function WidgetStatusPage() {
  const { clickSeeMore, handleClose, showWidget, styleWidget } =
    useWidgetStatus();

  return (
    <S.Snackbar>
      {!!showWidget && (
        <CardWidget
          status={styleWidget}
          onClick={() => clickSeeMore()}
          onClose={() => handleClose()}
        />
      )}
    </S.Snackbar>
  );
}

export default WidgetStatusPage;
