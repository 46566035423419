import React from "react";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

import { Icon } from "@iconify/react";
import CheckedIcon from "@iconify-icons/fluent/checkmark-starburst-24-filled";

import * as S from "./styles";

export function HeaderRating({
  jobDetails = {},
  textAfterRating = "",
  statusRatingSend = false,
}) {
  const { t } = useTranslation(["rating"]);
  const theme = useTheme();

  return (
    <S.ContainerText>
      {statusRatingSend && (
        <S.SuccessSent>
          <S.RatingReceived>
            <Icon
              icon={CheckedIcon}
              color={theme.colors.green_4}
              style={{
                fontSize: "1.25rem",
              }}
            />
            <span>{t("external_evaluation.review_received")}</span>
          </S.RatingReceived>
          <span>{textAfterRating}</span>
        </S.SuccessSent>
      )}

      {!statusRatingSend && (
        <span>
          {t("external_evaluation.about_experience", {
            id: jobDetails?.id,
            date: jobDetails?.date,
            hourStart: jobDetails?.hourStart,
            hourEnd: jobDetails?.hourEnd,
          })}
        </span>
      )}
    </S.ContainerText>
  );
}
