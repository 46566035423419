import React from "react";
import colors from "assets/colors";

const ErrorMessage = ({ error = "" }) => {
  return (
    error && (
      <span
        style={{
          display: "block",
          color: colors.red,
          fontFamily: ["Muli", "sans-serif"].join(", "),
          marginTop: "0.25rem",
          fontSize: "0.75rem",
          lineHeight: "0.897rem",
        }}
      >
        {error}
      </span>
    )
  );
};

export default ErrorMessage;
