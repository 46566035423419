import { combineReducers } from "redux";

import { auth } from "./auth";
import { categories } from "./categories";
import { jobSettings } from "./jobSettings";
import { languages } from "./languages";
import { modal } from "./modal";
import { notification } from "./notification";
import { roles } from "./roles";
import { sidebar } from "./sidebar";
import { status } from "./status";
import { user } from "./user";
import { supervisors } from "./supervisors";
import { labelNextModal } from "./labelNextModal";
import { filterFavoriteList } from "./filterFavoriteList";
import { address } from "./address";
import { extractInte } from "./extractInte";
import { extractFree } from "./extractFree";
import { pageChangeTab } from "./tabEduCloseer";
import { newMonth } from "./currentMonth";
import { wallet } from "./wallet";
import { dateInterval } from "./dateInterval";
import { extractScroll } from "./extractScroll";
import { persistedFilters } from "./persistedFilters";
import { searchNPS } from "./searchNPS";
import { menuAdm } from "./menuAdm";
import { helpLink } from "./helpLink";
import { historyBilling } from "./historyBilling";
import { tipsEstablishment } from "./tipsEstablishment";
import { levels } from "./levelsUsers";
import { notificationCenter } from "./notificationCenter";
import { vacancy } from "./vacancy";
import { walletBigCompany } from "./walletBigCompany";
import { paymentProfile } from "store/ducks/bigcompany/paymentProfile";
import { training } from "store/ducks/training";
import { reloads } from "store/ducks/reloads";
import { notificationSettings } from "store/ducks/notificationSettings";
import { commercialSettings } from "store/ducks/commercialSettings";
import { notificationPreferences } from "store/ducks/notificationPreferences";
import { bigcompanyProfile } from "store/ducks/bigcompany/profile";
import { bigcompanySettings } from "store/ducks/bigcompany/settings";
import { establishmentSettings } from "store/ducks/establishment/settings";
import { extraData } from "store/ducks/extraData";
import { establishmentAddress } from "store/ducks/establishment/address";
import { establishmentTaxAddress } from "store/ducks/establishment/taxAddress";
import { approvalsPending } from "store/ducks/bigcompany/approvals";
import { subscription } from "store/ducks/subscription";
import { batchActions } from "store/ducks/batchActions";
import { twoFactorAuthentication } from "store/ducks/2fa";
import { contract } from "store/ducks/contract";
import { tabSubscription } from "store/ducks/tabSubscription";

export default combineReducers({
  auth,
  categories,
  jobSettings,
  languages,
  modal,
  notification,
  roles,
  sidebar,
  status,
  user,
  supervisors,
  labelNextModal,
  filterFavoriteList,
  address,
  extractInte,
  extractFree,
  pageChangeTab,
  newMonth,
  wallet,
  dateInterval,
  extractScroll,
  persistedFilters,
  searchNPS,
  menuAdm,
  helpLink,
  historyBilling,
  helpLink,
  tipsEstablishment,
  levels,
  notificationCenter,
  vacancy,
  walletBigCompany,
  paymentProfile,
  training,
  reloads,
  notificationSettings,
  commercialSettings,
  notificationPreferences,
  bigcompanyProfile,
  bigcompanySettings,
  establishmentSettings,
  establishmentAddress,
  extraData,
  establishmentTaxAddress,
  approvalsPending,
  subscription,
  batchActions,
  twoFactorAuthentication,
  contract,
  tabSubscription,
});
