import React from 'react';

import colors from 'assets/colors';
import * as S from "./styles";

function TagWidget({
  children,
  background = colors.red,
  colorText = colors.white,
}) {
  return (
    <S.Container color={background} border={colorText}>
      <S.Text colorText={colorText}>{children}</S.Text>
    </S.Container>
  );
}

export default TagWidget;