import * as React from "react";
const SvgCustomEyeHide = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M11.98 15.78c1.693 0 3.066-1.394 3.066-3.113 0-1.72-1.373-3.113-3.066-3.113-1.693 0-3.066 1.393-3.066 3.113 0 1.719 1.373 3.113 3.066 3.113Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      d="M2.788 11.54s-.916 1.002-.773.835c1.019-1.174 5.093-5.49 9.965-5.49s8.946 4.316 9.965 5.49c.143.167.03-.017-.626-.683M20.515 4 4.758 20"
    />
  </svg>
);
export default SvgCustomEyeHide;
