const INITIAL_STATE = {
    loading: false,
    loaded: false,
    error: "",
    data: {
      showPending: false,
    },
};

// TYPES
export const Types = {
  REQUEST: "bigcompany-approvals-request",
  SUCCESS: "bigcompany-approvals-success",
  FAILURE: "bigcompany-approvals-failure",
  UPDATE: "bigcompany-approvals-update",
};

// REDUCER
export function approvalsPending(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return {
        ...state, loading: true, loaded: false, error: ""
      };
    case Types.SUCCESS:
      return {
        ...state, loading: false, loaded: true, data: {
          ...action?.payload.data,
          showPending: action?.payload.data?.count > 0
        }
      };
    case Types.FAILURE:
      return {
        ...state, loading: false, error: action?.payload.data
      };
    case Types.UPDATE_SHOW_PENDING:
      return {
        ...state, data: {
          ...state.data,
          showPending: action?.payload.show
        }
      };
    case Types.UPDATE:
      return {
        ...state, 
        data: {
          ...state.data,
          ...action?.payload.data
        }
      };
    default:
      return state;
  }
}

// ACTIONS
export const Creators = {
  request:
    () => ({
      type: Types.REQUEST,
    }),
  success:
    ({ data }) => ({
      type: Types.SUCCESS,
      payload: { data },
    }),
  failure:
    ({ data }) => ({
      type: Types.FAILURE,
      payload: { data },
    }),
  updateShowPending:
    ({ show }) => ({
      type: Types.UPDATE_SHOW_PENDING,
      payload: { show },
    }),
  update:
    ({ data }) => ({
      type: Types.UPDATE,
      payload: { data },
    }),
};

