import React from "react";
import { useSelector } from "react-redux";
import { Grid, Hidden, Grow } from "@material-ui/core";
import { Button } from "libs/purple-ui";

import Logo from "assets/imgs/brand/closeer-logo-white.svg";

import * as S from "./styles";
import { useTranslation } from "react-i18next";

const ErrorPage = ({ error }) => {
  const resetErrorBoundary = () => {
    window.location.reload();
  };
  const { t } = useTranslation(["error"]);
  const country_name = useSelector(
    (state) => state?.status?.data?.country_name
  );

  return (
    <S.PageContainer>
      <img className="cloud-purple" src="/404-bg.svg" />
      <Grid container className="main">
        <Hidden smDown>
          <Grid xl={6} lg={6} md={6} sm={12} xs={12} style={{ zIndex: 5 }}>
            <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
              <S.BoxPrincipal>
                <div className="title">
                  <h2>{t("ops")}</h2>
                  <h5>{t("error")}</h5>
                </div>
                <img className="illustration" src="/404-error.svg" alt="Erro" />
              </S.BoxPrincipal>
            </Grow>
          </Grid>
        </Hidden>

        <Grid xl={6} lg={6} md={6} sm={12} xs={12} style={{ zIndex: 5 }}>
          <S.SafeAreaInfo>
            <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
              <S.BoxInformation>
                <div className="logo-container">
                  <img src={Logo} alt="Closeer Logo" />
                </div>
                <div className="content">
                  {error.name === "ChunkLoadError" ? (
                    <p>{t("content_try_access_changed")}</p>
                  ) : (
                    <p>
                      {t("our_equip_call")}{" "}
                      <strong>
                        {country_name == "Argentina"
                          ? t("email_arg")
                          : t("email_pt")}
                      </strong>
                    </p>
                  )}
                  <div style={{ marginTop: "2.5rem" }}>
                    <Button variant="outlined" onClick={resetErrorBoundary}>
                      {error.name === "ChunkLoadError"
                        ? t("update")
                        : t("back")}
                    </Button>
                  </div>
                </div>
              </S.BoxInformation>
            </Grow>
          </S.SafeAreaInfo>
        </Grid>
      </Grid>
      <S.Footer>
        <p>&copy; {t("closeer_ltda")}</p>
      </S.Footer>
    </S.PageContainer>
  );
};

export default ErrorPage;
