const INITIAL_STATE = {
  bigcompany: {
    loading: false,
    loaded: false,
    error: "",
    filters: {
      page: 1,
      readed: false,
      loading: false,
    },
    metadata: {
      unread: false,
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
  establishment: {
    loading: false,
    loaded: false,
    error: "",
    filters: {
      page: 1,
      readed: false,
      loading: false,
    },
    metadata: {
      unread: false,
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
  supervisor: {
    loading: false,
    loaded: false,
    error: "",
    filters: {
      page: 1,
      readed: false,
      loading: false,
    },
    metadata: {
      unread: false,
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
};

/**
 * TYPES
 */

export const Types = {
  CHANGE_PAGE_NOTIFICATIONS: "change-page-notifications-center",
  TOGGLE_READED_NOTIFICATIONS: "toggle-readed-notifications-center",
  REQUEST_NOTIFICATIONS: "request-notifications-center",
  SUCCESS_NOTIFICATIONS: "success-notifications-center",
  FAILURE_NOTIFICATIONS: "failure-notifications-center",
  READ_NOTIFICATIONS: "read-notifications-center",
  UNREAD_NOTIFICATIONS: "unread-notifications-center",
  CLEAR_NOTIFICATIONS: "clear-notifications-center",
  ADD_NOTIFICATION: "add-notification-center",
  READ_ALL_NOTIFICATIONS: "read-all-notifications",
};

/**
 * REDUCER
 */

export function notificationCenter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST_NOTIFICATIONS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: true,
          error: "",
        },
      };
    case Types.SUCCESS_NOTIFICATIONS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: false,
          loaded: true,
          filters: {
            ...state[action.payload.user]?.filters,
            loading: false,
          },
          metadata: {
            ...state[action.payload.user]?.metadata,
            ...action?.payload?.data,
            results: [
              ...state[action.payload.user]?.metadata?.results,
              ...action?.payload?.data?.results,
            ],
          },
        },
      };
    case Types.UNREAD_NOTIFICATIONS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          metadata: {
            ...state[action.payload.user]?.metadata,
            unread: action?.payload?.unread,
          },
        },
      };
    case Types.CHANGE_PAGE_NOTIFICATIONS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          filters: {
            ...state[action.payload.user]?.filters,
            page: state[action.payload.user]?.filters?.page + 1,
          },
        },
      };
    case Types.TOGGLE_READED_NOTIFICATIONS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          metadata: {
            ...state[action.payload.user]?.metadata,
            results: [],
          },
          filters: {
            page: 1,
            readed: !state[action.payload.user]?.filters?.readed,
            loading: true,
          },
        },
      };
    case Types.FAILURE_NOTIFICATIONS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: false,
          error: action.payload.data,
        },
      };
    case Types.READ_NOTIFICATIONS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          metadata: {
            ...state[action.payload.user]?.metadata,
            results: checkRead(state, action),
          },
        },
      };

    case Types.ADD_NOTIFICATION:
      let results = state[action.payload.user]?.metadata?.results;

      results?.unshift(action?.payload?.notification);

      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          metadata: {
            ...state[action.payload.user]?.metadata,
            unread: true, // If a new notification is added it means we will have unread notifications
            results,
          },
        },
      };

    case Types.READ_ALL_NOTIFICATIONS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          metadata: {
            ...state[action.payload.user]?.metadata,
            unread: false,
            results: !state[action.payload.user]?.filters?.readed
              ? []
              : state[action.payload.user]?.metadata?.results?.map((ntf) => ({
                  ...ntf,
                  read: true,
                })),
          },
        },
      };
    case Types.CLEAR_NOTIFICATIONS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

/**
 * FUNCTIONS
 */

function checkRead(state, action) {
  let notifications = state[action.payload.user]?.metadata?.results;
  let notificationIndex = notifications.findIndex(
    (notification) => notification.pk === action.payload.pk
  );

  if (notificationIndex !== -1) {
    notifications[notificationIndex].read = true;
  }

  // If the unread only filter is enabled and any notification is clicked, it needs to be removed from the results object
  if (!state[action.payload.user]?.filters?.readed) {
    notifications.splice(notificationIndex, 1);
  }

  return notifications;
}

/**
 * ACTIONS
 */

export const Creators = {
  requestNotifications: ({ user = "establishment" }) => ({
    type: Types.REQUEST_NOTIFICATIONS,
    payload: { user },
  }),
  successNotifications: ({ data, user = "establishment" }) => ({
    type: Types.SUCCESS_NOTIFICATIONS,
    payload: { data, user },
  }),
  failureNotifications: ({ data, user = "establishment" }) => ({
    type: Types.FAILURE_NOTIFICATIONS,
    payload: { data, user },
  }),
  addNotification: ({ notification, user = "establishment" }) => ({
    type: Types.ADD_NOTIFICATION,
    payload: { notification, user },
  }),
  toggleMarkRead: ({ pk, user = "establishment" }) => ({
    type: Types.READ_NOTIFICATIONS,
    payload: { pk, user },
  }),
  unreadNotifications: ({ unread, user = "establishment" }) => ({
    type: Types.UNREAD_NOTIFICATIONS,
    payload: { unread, user },
  }),
  readAll: ({ user = "establishment" }) => ({
    type: Types.READ_ALL_NOTIFICATIONS,
    payload: { user },
  }),
  changePageNotifications: ({ user = "establishment" }) => ({
    type: Types.CHANGE_PAGE_NOTIFICATIONS,
    payload: { user },
  }),
  toggleViewMode: ({ user = "establishment" }) => ({
    type: Types.TOGGLE_READED_NOTIFICATIONS,
    payload: { user },
  }),
  reset: () => ({
    type: Types.CLEAR_NOTIFICATIONS,
    payload: null,
  }),
};
