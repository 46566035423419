const INITIAL_STATE = {
  establishment: {
    loading: false,
    loaded: false,
    error: "",
    data: {},
  },
  bigcompany: {
    loading: false,
    loaded: false,
    error: "",
    data: {},
  },
};

// TYPES
export const Types = {
  REQUEST: "get-extra-data-request",
  SUCCESS: "get-extra-data-success",
  FAILURE: "get-extra-data-failure",
  UPDATE: "update-extra-data",
};

// REDUCER
export function extraData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return {
        ...state, [action.payload.user]:
          { ...state[action.payload.user], loading: true, loaded: false, error: "" }
      };
    case Types.SUCCESS:
      return {
        ...state, [action.payload.user]:
          { ...state[action.payload.user], loading: false, loaded: true, data: action.payload.data }
      };
    case Types.FAILURE:
      return {
        ...state, [action.payload.user]:
          { ...state[action.payload.user], loading: false, error: action.payload.data }
      };
    case Types.UPDATE:
      return {
        ...state, [action.payload.user]:
          { ...state[action.payload.user], data: { 
            ...state[action.payload.user].data,
            image: action.payload.data
          } }
      };
    default:
      return state;
  }
}

// ACTIONS
export const Creators = {
  request: ({ data, user = 'establishment' }) => ({
    type: Types.REQUEST,
    payload: { data, user },
  }),
  success: ({ data, user = 'establishment' }) => ({
    type: Types.SUCCESS,
    payload: { data, user },
  }),
  failure: ({ data, user = 'establishment' }) => ({
    type: Types.FAILURE,
    payload: { data, user },
  }),
  updateImage: ({ data, user = 'establishment' }) => ({
    type: Types.UPDATE,
    payload: { data, user },
  }),
};
