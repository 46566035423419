import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";

import { Text } from "libs/purple-ui";

import * as S from "./styles";

/**
 * ## Parâmetros
 * @param {boolean} checked - Define se o radio button está selecionado ou não
 * @param {function} onChange - Função a ser executada ao clicar no radio button
 * @param {string|number} value - Define o valor do radio button
 * @param {node|string} text - A label da opção
 * @param {node|string} description - Define a descrição do radio button
 * @param {node|string} children - Define a label do radio button
 * @param {boolean} disabled Define se o radiobutton está desabilitado
 * @param {string} name - nome do radiobutton

 * ## Exemplo de uso
 * @example
 * <RadioButton
 *  checked={false}
 *  onChange={() => console.log('Clicou no radio button')}
 *  value="value"
 *  text="text"
 *  description="description"
 * >
 *  <TextColorRed>Teste</TextColorRed>
 * </RadioButton>
 * @returns node
 */

export const RadioButton = ({
  checked = false,
  value = "",
  text = "",
  onChange = () => {},
  description = null,
  disabled = false,
  children,
  name = "",
}) => {
  const theme = useTheme();

  const handleClick = () => {
    if (disabled === false) {
      onChange({ value, text, name });
    } else return;
  };

  return (
    <S.Container onClick={() => handleClick()}>
      <S.ContainerRadioButton disabled={disabled}>
        <S.RadioButton disabled={disabled} checked={checked}></S.RadioButton>
        <S.Text>{children}</S.Text>
        {/* //TODO trocar pelo componente de text do purpleui */}
      </S.ContainerRadioButton>
      <S.ContainerDescription>
        <Text color={theme.colors.grey_3}>{description}</Text>
      </S.ContainerDescription>
    </S.Container>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  description: PropTypes.node,
  children: PropTypes.node,
};
