import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as Actions } from "store/ducks/contract";

export function* getContract(action) {
  try {
    const { data } = yield call(
      MainService.get,
      `v2/establishment/${action.payload.userId}/contract`,
      {
        headers: {
          "notifications-front-banned": true,
        },
      }
    );
    yield put(Actions.success(data));
  } catch (error) {
    if (error.response && error.response.status === 404) {
      yield put(
        Actions.setContract({
          contract: "nonexistent",
          loaded: true,
        })
      );
    } else {
      yield put(Actions.failure(error?.message));
    }
  }
}
