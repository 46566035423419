import React from "react";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";

/**
 * @param {number|string} size - Tamanho do SVG (padrão: 1.5rem)
 * @param {string} color - Cor do SVG (padrão: theme.colors.grey_4 || 'inherit')
 * @param {React.Component} icon - SVG
 * @param {React.CSSProperties} style - Estilos do SVG
 * @param {React.SVGProps<SVGSVGElement>} svgProps - Todos os atributos que um SVG pode receber
 *
 * @example
 * import {Icon} from "libs/streamline";
 * import {SLAdd} from "libs/streamline/icons/light";
 *
 * <Icon icon={SLAdd} size="1rem" color="red" />
 */
export const Icon = ({
  size,
  color,
  icon: IconComponent,
  style: svgStyle,
  ...svgProps
}) => {
  const theme = useTheme();

  let svgExtraProps = {
    width: "1.5rem",
    height: "1.5rem",
    viewBox: `0 0 24 24`,
  };

  if (size !== undefined) {
    let _size = typeof size === "number" ? `${size}rem` : size;
    svgExtraProps.width = _size;
    svgExtraProps.height = _size;
  }

  svgExtraProps.style = {
    color: color || theme.colors.white_pure || "inherit",
    ...svgStyle,
  };

  return <IconComponent {...svgProps} {...svgExtraProps} />;
};

Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  icon: PropTypes.elementType.isRequired,
  style: PropTypes.object,
  svgProps: PropTypes.object,
};
