import React from "react";
import { useTheme } from "styled-components";
import CustomTooltip from "components/CustomTooltip";

import { Icon } from "libs/streamline";
import { SLRatingStar } from "libs/streamline/icons/light";
import { SLQuestionCircle } from "libs/streamline/icons/bold";

import * as S from "./styles";

export function RatingStar({
  value = 0,
  disabled = false,
  name = "",
  tooltip = "",
  onClick = () => {},
}) {
  const theme = useTheme();

  const handleRating = (index) => {
    if (disabled) return;
    onClick(index + 1);
  };

  return (
    <S.Container>
      <S.ContainerText>
        <CustomTooltip title={tooltip}>
          <div>
            <Icon icon={SLQuestionCircle} color={theme.colors.black_pure} />
          </div>
        </CustomTooltip>
        <span>{name}</span>
      </S.ContainerText>
      <S.ContainerStarts>
        {[...Array(5)].map((_, index) => (
          <S.Star
            onClick={() => handleRating(index)}
            key={index}
            clickable={!disabled}
          >
            <Icon
              icon={SLRatingStar}
              size={"2rem"}
              color={theme.colors.primary_5}
              fill={value >= index + 1 ? theme.colors.primary_5 : "transparent"}
            />
          </S.Star>
        ))}
      </S.ContainerStarts>
    </S.Container>
  );
}
