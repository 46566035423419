import mainService from "services/mainService";

/**
 * --------------------------------------------------
 * @description envia email para confirmar 2fa
 * @example
 * sendEmail2faConfirmation()
 */
export function sendEmail2faConfirmation() {
  const url = `v2/establishment/send_email_to_confirm_2fa`;
  return mainService.post(url);
}

/**
 * --------------------------------------------------
 * @description ativa 2fa para todas as unidades
 * @param {number} pk
 * @param {string} user
 * @param {object} body
 * @example
 * active2faToAllUnits({
 *  body = {active_for_all_units: true, expiration_time: null}
 *  pk = 988
 *  user = "bigcompany"
 * })
 * active2faToAllUnits({
 * body = {active_for_all: true, expiration_time: null}
 * pk = 988
 * user = "establishment"
 * })
 */
export function active2faToAllUnits({
  body = {},
  pk,
  user = "bigcompany",
} = {}) {
  let url = `v2/bigcompany/${pk}/active_2fa_to_all_units`;
  if (user === "establishment") {
    url = `v2/establishment/${pk}/active_2fa_to_all_users`;
  }
  return mainService.put(url, body);
}

/**
 * --------------------------------------------------
 * @description verifica se o usuário tem 2fa ativo
 * @example
 * verifyHas2faActive()
 */
export function verifyHas2faActive() {
  const url = "v2/user/verify_2fa_actived";
  return mainService.get(url);
}

/**
 * --------------------------------------------------
 * @description cria 2fa para o usuário
 * @param {object} body
 * @example
 * create2fa({
 *  body = {code: 170194}
 * })
 */
export function create2fa({ body = {} } = {}) {
  const url = `v2/establishment/create_2fa_user`;
  return mainService.post(url, body, {
    headers: {
      "notifications-front-banned": true,
    },
  });
}

/**
 * --------------------------------------------------
 * @description desativa 2fa
 * @example
 * delete2fa()
 */
export function delete2fa() {
  const url = `v2/establishment/create_2fa_user`;
  return mainService.delete(url);
}

/**
 * --------------------------------------------------
 * @description verifica as informações de 2fa de uma bigcompany
 * @param {number} pkBigcompany
 * @example
 * settings2fa({ pkBigCompany: 1234 })
 */
export function settings2fa({ pkBigCompany }) {
  const url = `v2/bigcompany/${pkBigCompany}/2fa_settings`;
  return mainService.get(url, {
    headers: {
      "notifications-front-banned": true,
    },
  });
}
