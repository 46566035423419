import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./styles";

function ReadMore({ text, color }) {
  const { t } = useTranslation(["dashboard"]);
  const [showMore, setShowMore] = useState(false);
  return (
    <S.Nav color={color}>
      <span>{showMore ? text : `${text.substring(0, 90) + "..."}`}</span>
      <span onClick={() => setShowMore((old) => !old)}>
        {showMore ? t("dashboard:view_less") : t("dashboard:view_more")}
      </span>
    </S.Nav>
  );
}

export default ReadMore;
