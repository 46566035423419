import {
  getBigcompanyOrSupervisorCurrentPathLocation,
  getShowSubItems,
} from "utils/basic";

const INITIAL_STATE = {
  opened: JSON.parse(localStorage.getItem("sidebar")) ?? true,
  openedHover: false,
  currentMenuItemAdm: "Home",
  showSubItems: [],
};

/**
 * TYPES
 */

export const Types = {
  OPEN_SIDEBAR: "open-sidebar-adm",
  CLOSE_SIDEBAR: "close-sidebar-adm",
  OPEN_SIDEBAR_HOVER: "open-sidebar-hover-adm",
  CLOSE_SIDEBAR_HOVER: "close-sidebar-hover-adm",
  SET_SIDEBAR_ADM: "set-sidebar-adm",
  SET_CURRENT_MENU_ITEM_ADM: "set-current-menu-item-adm",
  SET_SHOW_SUBITEMS: "set-show-sub-item-adm",
  CLOSE_SUBITEM: "set-subitem-adm",
};
/**
 * REDUCER
 */
export function menuAdm(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.OPEN_SIDEBAR:
      localStorage.setItem("sidebar", true);
      return { ...state, opened: true };
    case Types.CLOSE_SIDEBAR:
      localStorage.setItem("sidebar", false);
      return { ...state, opened: false, showSubItems: [] };
    case Types.OPEN_SIDEBAR_HOVER:
      return { ...state, openedHover: true };
    case Types.CLOSE_SIDEBAR_HOVER:
      return {
        ...state,
        openedHover: false,
        showSubItems: state.opened ? state.showSubItems : [],
      };
    case Types.CLOSE_SUBITEM:
      return {
        ...state,
        showSubItems: [],
      };
    case Types.SET_SIDEBAR_ADM:
      return { ...state, opened: JSON.parse(localStorage.getItem("sidebar")) };
    case Types.SET_CURRENT_MENU_ITEM_ADM:
      return {
        ...state,
        currentMenuItemAdm: getBigcompanyOrSupervisorCurrentPathLocation(
          action.payload
        ),
      };
    case Types.SET_SHOW_SUBITEMS:
      return {
        ...state,
        showSubItems: getShowSubItems(state, action),
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  open: () => ({ type: Types.OPEN_SIDEBAR }),
  close: () => ({ type: Types.CLOSE_SIDEBAR }),
  openHover: () => ({ type: Types.OPEN_SIDEBAR_HOVER }),
  closeHover: () => ({ type: Types.CLOSE_SIDEBAR_HOVER }),
  updateDockedStateAdm: () => ({ type: Types.SET_SIDEBAR_ADM }),
  setCurrentMenuItemAdm: (itemName) => ({
    type: Types.SET_CURRENT_MENU_ITEM_ADM,
    payload: itemName,
  }),
  setShowSubItems: ({ name }) => ({
    type: Types.SET_SHOW_SUBITEMS,
    payload: { name },
  }),
  closeSubItem: () => ({ type: Types.CLOSE_SUBITEM }),
};
