const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: {
    current_balance: 0,
  },
};

/**
 * TYPES
 */

export const Types = {
  WALLET_REQUEST_BIG_COMPANY: "wallet-request-big-company",
  WALLET_SUCCESS_BIG_COMPANY: "wallet-success-big-company",
  WALLET_FAILURE_BIG_COMPANY: "wallet-failure-big-company",
};
/**
 * REDUCER
 */
export function walletBigCompany(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.WALLET_REQUEST_BIG_COMPANY:
      return { ...state, loading: true, error: "" };
    case Types.WALLET_SUCCESS_BIG_COMPANY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.WALLET_FAILURE_BIG_COMPANY:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  setWallet: (data) => ({
    type: Types.WALLET_REQUEST_BIG_COMPANY,
    payload: { data },
  }),
  walletSuccess: (data) => ({
    type: Types.WALLET_SUCCESS_BIG_COMPANY,
    payload: { data },
  }),
  walletFailure: (data) => ({
    type: Types.WALLET_FAILURE_BIG_COMPANY,
    payload: { data },
  }),
};
