import { call, put } from "redux-saga/effects";
import MainService from "../../services/mainService";

import { Creators as RolesActions } from "../ducks/roles";

export function* getRoles(action) {
  try {
    const { data } = yield call(MainService.get, "v2/roles?establishment=true");

    yield put(RolesActions.getRolesSuccess(data));
  } catch (error) {
    yield put(RolesActions.getRolesFailure(error.message));
  }
}
