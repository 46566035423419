import * as React from "react";
const SvgCloudDownload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.87 8.478c.723.699 1.132 1.66 1.135 2.666a3.719 3.719 0 0 1-3.398 3.703.64.64 0 0 1-.084-1.275 2.456 2.456 0 0 0 2.201-2.428 2.403 2.403 0 0 0-.743-1.746 2.361 2.361 0 0 0-1.766-.672.614.614 0 0 1-.602-.365 3.641 3.641 0 0 0-6.93 1.337.64.64 0 0 1-.768.589c-.49-.102-1 .022-1.388.337a1.65 1.65 0 0 0-.61 1.286c-.03.421.119.836.408 1.144.387.333.881.515 1.391.512.35.003.637.28.65.631a.64.64 0 0 1-.63.65 3.54 3.54 0 0 1-2.31-.882 2.77 2.77 0 0 1-.79-2.055 2.94 2.94 0 0 1 2.73-2.927.16.16 0 0 0 .145-.128 4.92 4.92 0 0 1 9.03-1.47.16.16 0 0 0 .122.078c.83.077 1.608.435 2.207 1.015Zm-5.43 7.01h1.122v.002a.64.64 0 0 1 .452 1.093l-2.241 2.242a.64.64 0 0 1-.906 0l-2.241-2.242a.64.64 0 0 1 .453-1.093h1.12a.16.16 0 0 0 .16-.16v-4.645a.96.96 0 0 1 1.922 0v4.643c0 .088.071.16.16.16Z"
      clipRule="evenodd"
    />
    <mask
      id="cloud-download_svg__a"
      width={17}
      height={16}
      x={4}
      y={4}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.87 8.478c.723.699 1.132 1.66 1.135 2.666a3.719 3.719 0 0 1-3.398 3.703.64.64 0 0 1-.084-1.275 2.456 2.456 0 0 0 2.201-2.428 2.403 2.403 0 0 0-.743-1.746 2.361 2.361 0 0 0-1.766-.672.614.614 0 0 1-.602-.365 3.641 3.641 0 0 0-6.93 1.337.64.64 0 0 1-.768.589c-.49-.102-1 .022-1.388.337a1.65 1.65 0 0 0-.61 1.286c-.03.421.119.836.408 1.144.387.333.881.515 1.391.512.35.003.637.28.65.631a.64.64 0 0 1-.63.65 3.54 3.54 0 0 1-2.31-.882 2.77 2.77 0 0 1-.79-2.055 2.94 2.94 0 0 1 2.73-2.927.16.16 0 0 0 .145-.128 4.92 4.92 0 0 1 9.03-1.47.16.16 0 0 0 .122.078c.83.077 1.608.435 2.207 1.015Zm-5.43 7.01h1.122v.002a.64.64 0 0 1 .452 1.093l-2.241 2.242a.64.64 0 0 1-.906 0l-2.241-2.242a.64.64 0 0 1 .453-1.093h1.12a.16.16 0 0 0 .16-.16v-4.645a.96.96 0 0 1 1.922 0v4.643c0 .088.071.16.16.16Z"
        clipRule="evenodd"
      />
    </mask>
  </svg>
);
export default SvgCloudDownload;
