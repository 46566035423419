import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerRadioButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(12px, 1rem, 16px);
  height: clamp(12px, 1rem, 16px);
  background-color: ${({ theme, disabled, checked }) =>
    disabled && checked
      ? theme.colors.grey_2
      : disabled
      ? theme.colors.grey_1
      : theme.colors.white_pure};
  border-color: ${({ theme, checked, disabled }) =>
    disabled
      ? theme.colors.grey_1
      : checked
      ? theme.colors.primary_5
      : theme.colors.grey_2};
  border-width: ${({ checked }) => (checked ? "4px" : "1px")};
  border-style: solid;
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  margin-right: 0.5rem;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
`;

export const ContainerDescription = styled.div`
  padding-left: 1.438rem;
`;

export const Text = styled.p`
  font: 0.875rem/1.098rem "Muli", sans-serif;
`;
