import {call, put} from "redux-saga/effects";
import MainService from "services/mainService";

import {Creators as ApprovalsActions} from "store/ducks/bigcompany/approvals";

export function* getApprovalsPending() {
  try {
    const {data} = yield call(
      MainService.get,
      `v2/jobs/approvals/pending?page_size=10`
    );
    
    yield put(
      ApprovalsActions.success({
        data
      })
    );
  } catch (error) {
    yield put(
      ApprovalsActions.failure({
        data: error.message
      })
    );
  }
}