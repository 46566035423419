import { countPages } from "utils/basic";

const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: [],
  page: 0,
  pages: 0,
  next: null,
  previous: null,
  count: 0,
  firstLoaded: true,
};

/**
 * TYPES
 */

export const Types = {
  GET_SUPERVISORS_REQUEST: "get-supervisors-request",
  GET_SUPERVISORS_SUCCESS: "get-supervisors-success",
  GET_SUPERVISORS_FAILURE: "get-supervisors-failure",
};
/**
 * REDUCER
 */
export function supervisors(state = INITIAL_STATE, action) {
  switch (action.type) {
    // JOB SETTINGS
    case Types.GET_SUPERVISORS_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.GET_SUPERVISORS_SUCCESS:
      const { data } = action.payload;
      let pages;
      let firstLoaded;

      if (state.firstLoaded) {
        pages = countPages(data.count, data.results.length);
        firstLoaded = false;
      } else {
        pages = state.pages;
      }

      return {
        loading: false,
        loaded: true,
        error: "",
        data: data.results,
        page: data.page,
        pages,
        next: data.next,
        previous: data.previous,
        count: data.count,
        firstLoaded,
      };
    case Types.GET_SUPERVISORS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.data,
        data: [],
        page,
        next: null,
        previous: null,
        count: 0,
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  // SUPERVISORS
  getSupervisorsRequest: (page = 0, text = "") => ({
    type: Types.GET_SUPERVISORS_REQUEST,
    payload: { page, text },
  }),
  getSupervisorsSuccess: (data) => ({
    type: Types.GET_SUPERVISORS_SUCCESS,
    payload: { data },
  }),
  getSupervisorsFailure: (data) => ({
    type: Types.GET_SUPERVISORS_FAILURE,
    payload: { data },
  }),
};
