function getInitialState(data = []) {
  let schema = {};

  data?.forEach((key) => {
    schema[key] = {
      checkedItems: [],
    };
  });

  return schema;
}

const INITIAL_STATE = getInitialState([
  "generic",
  "approvalsPending",
  "valueControl",
]);

export const Types = {
  CHECK_ONE: "@batch-actions/check-one",
  CHECK_ALL: "@batch-actions/check-all",
  MERGE: "@batch-actions/merge",
  REMOVE_ITEMS: "@batch-actions/remove-items",
  RESET: "@batch-actions/reset",
};

export function batchActions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CHECK_ONE:
      return onCheckOne(state, action);

    case Types.CHECK_ALL:
      return onCheckAll(state, action);

    case Types.MERGE:
      return onMerge(state, action);

    case Types.REMOVE_ITEMS:
      return onRemoveItems(state, action);

    case Types.RESET:
      return onReset(state, action);

    default:
      return state;
  }
}

function onCheckOne(state, action) {
  const { key = "generic", item } = action.payload;
  const { checkedItems } = state[key];

  const checkedItemsClone = checkedItems.map((self) => ({ ...self }));
  const index = checkedItemsClone.findIndex((self) => self?.id === item?.id);

  if (index !== -1) {
    checkedItemsClone.splice(index, 1);
  } else {
    // cria um novo objeto copiando os atributos da linha, exceto 'row', 'contentColor' e 'batchActionControls'
    checkedItemsClone.push(
      (({ row, contentColor, batchActionControls, ...rest }) => rest)(item)
    );
  }

  return {
    ...state,
    [key]: {
      ...state[key],
      checkedItems: checkedItemsClone,
    },
  };
}
function onCheckAll(state, action) {
  const { key = "generic", items } = action.payload;

  return {
    ...state,
    [key]: {
      ...state[key],
      // realiza a cópia dos atributos do objeto, exceto 'row', 'contentColor' e 'batchActionControls'
      checkedItems: !!state?.[key]?.checkedItems?.length
        ? []
        : items.map((item) =>
            (({ row, contentColor, batchActionControls, ...rest }) => rest)(
              item
            )
          ) || [],
    },
  };
}
function onMerge(state, action) {
  const { key = "generic", items = [] } = action.payload;
  const { checkedItems } = state[key];

  const mergedItems = [];

  checkedItems.forEach((row) => {
    const index = items.findIndex((item) => item?.id === row?.id);

    if (index === -1) {
      return;
    }

    let updatedOrigin = { ...items[index]?.origin };

    mergedItems.push({
      ...row,
      origin: updatedOrigin,
    });
  });

  return {
    ...state,
    [key]: {
      ...state[key],
      checkedItems: mergedItems,
    },
  };
}
function onRemoveItems(state, action) {
  const { key = "generic", ids = [] } = action.payload;

  return {
    ...state,
    [key]: {
      ...state[key],
      checkedItems: state[key]?.checkedItems?.filter(
        ({ id }) => !ids.includes(id)
      ),
    },
  };
}
function onReset(state, action) {
  const { key = "generic", all = false } = action.payload;

  if (all) {
    return INITIAL_STATE;
  }

  return {
    ...state,
    [key]: {
      ...state[key],
      checkedItems: [],
    },
  };
}

export const Creators = {
  /**
   * @param {string} key - key para a ação em lote no redux
   * @param {object} item - linha de dados a ser salva
   * @comment apenas os atributos 'id' e 'origin' são salvos
   */
  checkOne: ({ key = "generic", item = {} }) => ({
    type: Types.CHECK_ONE,
    payload: { key, item },
  }),
  /**
   * @param {string} key - key para a ação em lote no redux
   * @param {array} items - array de linhas de dados a serem salvos
   */
  checkAll: ({ key = "generic", items = [] }) => ({
    type: Types.CHECK_ALL,
    payload: { key, items },
  }),
  /**
   * @param {string} key - key para a ação em lote no redux
   * @param {array} items - array de dados atualizados para mergear o objeto 'origin'
   * @comment o merge serve para sincronizar os dados alterados do origin com os dados copiados em checkedItems
   */
  merge: ({ key = "generic", items = [] }) => ({
    type: Types.MERGE,
    payload: { key, items },
  }),
  /**
   * @param {string} key - key para a ação em lote no redux
   * @param {array} ids - array de ids a serem removidos da marcação
   * @comment irá remover os itens cujos ids estejam presentes no array de ids enviado
   */
  removeItems: ({ key = "generic", ids = [] }) => ({
    type: Types.REMOVE_ITEMS,
    payload: { key, ids },
  }),
  /**
   * @param {string} key - key para a ação em lote no redux
   * @param {boolean} all - se true, reseta todas as keys de ação em lote no redux
   */
  reset: ({ key = "generic", all = false }) => ({
    type: Types.RESET,
    payload: { key, all },
  }),
};
