import { makeStyles, createTheme } from "@material-ui/core";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/styles";
import { defaultTheme as purpleTheme } from "libs/purple-ui/shared/theme";

export const useMaterialClasses = makeStyles((theme) => ({
  root: {
    fontFamily: "Muli, sans-serif",
  },
  hiddenAdornment: {
    display: "none",
  },
  // Para migrar
  safeAreaView: {
    width: "100%",
    maxWidth: 1800,
    margin: "0 auto",
  },
  // Em uso
  progressBar: {
    height: "0.3rem",
    backgroundColor: purpleTheme.colors.white_2,
  },
  icon: {
    flex: "none",
    borderRadius: "50%",
    width: "1.125rem",
    height: "1.125rem",
    minWidth: "14px",
    minHeight: "14px",
    border: "0.125rem solid black",
  },
  checkedIcon: {
    width: "1.125rem",
    height: "1.125rem",
    minWidth: "14px",
    minHeight: "14px",
    borderRadius: "50%",
    color: purpleTheme.colors.white_pure,
    backgroundColor: purpleTheme.colors.white_pure,
    position: "relative",
    border: `0.125rem solid ${purpleTheme.colors.black_pure}`,
    "&:before": {
      content: '""',
      position: "absolute",
      display: "block",
      backgroundColor: purpleTheme.colors.primary_5,
      width: "0.625rem",
      height: "0.625rem",
      top: "50%",
      left: "50%",
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  checkedIconDisabled: {
    backgroundColor: purpleTheme.colors.grey_2,
    border: `0.125rem solid ${purpleTheme.colors.grey_2}`,
    "&:before": {
      backgroundColor: purpleTheme.colors.white_pure,
    },
  },
  checkboxIcon: {
    flex: "none",
    borderRadius: "0.25rem",
    width: "1rem",
    height: "1rem",
    minWidth: "14px",
    minHeight: "14px",
    border: "0.125rem solid black",
  },
  checkboxCheckedIcon: {
    width: "1rem",
    height: "1rem",
    minWidth: "14px",
    minHeight: "14px",
    borderRadius: "0.25rem",
    color: purpleTheme.colors.white_pure,
    backgroundColor: purpleTheme.colors.primary_5,
    border: `0.125rem solid ${purpleTheme.colors.black_pure}`,
    position: "relative",
    "&:before": {
      content: '""',
      display: "block",
      width: "1rem",
      height: "1rem",
      minWidth: "10px",
      minHeight: "10px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      backgroundImage: `url("\data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='white'/></svg>\")`,
    },
  },
  checkboxCheckedIconDisabled: {
    backgroundColor: purpleTheme.colors.grey_2,
    border: `0.125rem solid ${purpleTheme.colors.grey_2}`,
  },
  tab: {
    backgroundColor: purpleTheme.colors.white_pure,
  },
  label: {
    color: purpleTheme.colors.black_pure,
    fontFamily: "Muli, sans-serif",
    fontSize: "0.875rem",
    "&.Mui-disabled": {
      color: purpleTheme.colors.black_pure,
    },
  },
  option: {
    minHeight: "2.5rem",
    fontSize: "0.875rem",
    "& > span": {
      marginRight: 10,
      fontSize: "0.875rem",
    },
  },
  listbox: {
    "& .MuiAutocomplete-option": {
      "&[aria-selected='true'], &[data-focus='true']": {
        backgroundColor: purpleTheme.colors.grey_0,
      },
      "&[data-focus='true']": {
        backgroundColor: purpleTheme.colors.grey_0,
      },
    },
  },
  noOptionsText: {
    fontFamily: "Muli",
    fontSize: "0.875rem",
    color: purpleTheme.colors.black_pure,
  },
  input: {
    fontSize: "0.875rem",
    color: purpleTheme.colors.black_pure,
  },
  positionEnd: {
    marginLeft: "25px !important",
    opacity: 0,
    pointerEvents: "none",
  },
  avatar_large: {
    width: "3.125rem",
    height: "3.125rem",
  },
}));

export const defaultTheme = createTheme({
  purple: {
    ...purpleTheme,
  },
  overrides: {
    MuiPaginationItem: {
      selected: {
        text: {
          fontWeight: "bold",
        },
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: "200px",
      },
      week: {
        justifyContent: "space-around",
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        maxWidth: 360,
        minWidth: 280,
        width: "100%",
      },
    },
    MuiPickersBasePicker: {
      container: {
        maxWidth: 360,
        minWidth: 280,
        width: "100%",
      },
      pickerView: {
        maxWidth: 340,
        minWidth: 280,
        width: "100%",
      },
    },
    MuiPickersDay: {
      day: {
        width: "34px",
        height: "34px",
        color: purpleTheme.colors.black_pure,
        margin: "2px 0",
      },
      daySelected: {
        backgroundColor: purpleTheme.colors.primary_2,
        color: purpleTheme.colors.black_pure,
        "&:hover": {
          backgroundColor: purpleTheme.colors.primary_2,
        },
      },
      dayDisabled: {},
      current: {
        border: `1px solid ${purpleTheme.colors.primary_5}`,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: "34px",
        height: "34px",
        fontSize: "0.875rem",
      },
      daysHeader: {
        justifyContent: "space-around",
      },
    },
    // DatePicker
    MuiTypography: {
      root: {
        fontFamily: "Muli, sans-serif",
      },
      caption: {
        fontFamily: "Muli, sans-serif",
      },
      body1: {
        fontFamily: "Muli Bold, sans-serif",
      },
      body2: {
        fontFamily: "Muli, sans-serif",
        fontSize: "0.875rem",
      },
      h6: {
        fontSize: "1rem",
        fontFamily: "Muli, sans-serif",
        fontWeight: 400,
        lineHeight: "1.25rem",
        letterSpacing: "0.0075em",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: `0.063rem solid ${purpleTheme.colors.white_1}`,
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "0.875rem",
      },
    },
    MuiFormLabel: {
      root: {
        padding: 0,
        fontSize: "0.875rem",
        fontWeight: 400,
        fontFamily: "Muli, sans-sans-serif",
        lineHeight: "1.098rem",
        letterSpacing: "0.00938em",
      },
    },
    MuiAvatar: {
      root: {
        width: "30px",
        height: "30px",
        display: "flex",
        overflow: "hidden",
        position: "relative",
        fontSize: "1rem",
      },
    },
    MuiSvgIcon: {
      root: {
        width: "1em",
        height: "1em",
      },
    },
    MuiPaper: {
      background: "red",
      elevation5: {
        background: `${purpleTheme.colors.white_pure} 0% 0% no-repeat padding-box`,
        boxShadow: `0px 2px 6px #00000029`,
        borderRadius: "0.5rem",
        opacity: "0.5",
      },
      elevation8: {
        background: `${purpleTheme.colors.white_pure} 0% 0% no-repeat padding-box`,
        boxShadow: `0px 2px 6px #00000029`,
        borderRadius: "1rem",
      },
      elevation9: {
        background: `#ffffff 0% 0% no-repeat padding-box`,
        boxShadow: `0px 2px 6px #00000029`,
        borderRadius: "0.313rem",
      },
    },
    MuiDrawer: {
      paper: {
        top: 0,
        flex: "1 0 auto",
        height: "100%",
        display: "flex",
        outline: 0,
        zIndex: 1,
        position: "fixed",
        overflowY: "auto",
        flexDirection: "column",
        background: "red",
        "-webkit-overflow-scrolling": "touch",
      },
    },
  },
  MuiInputBase: {
    input: {
      fontSize: "0.875rem",
    },
  },
  MuiAutocomplete: {},
  ".MuiSvgIcon-root svg": {
    color: purpleTheme.colors.black_pure,
  },
  palette: {
    primary: {
      main: purpleTheme.colors.primary_5,
      light: purpleTheme.colors.primary_5,
      dark: purpleTheme.colors.primary_5,
      contrastText: purpleTheme.colors.white_pure,
    },
    secondary: {
      main: purpleTheme.colors.primary_5,
      light: purpleTheme.colors.primary_5,
      contrastText: purpleTheme.colors.white_pure,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export const ThemeProvider = ({ children }) => (
  <MUIThemeProvider theme={defaultTheme}>{children}</MUIThemeProvider>
);
