import styled from "styled-components";

export const Badge = styled.span`
  border: 1px solid
    ${({ color, borderColor, theme }) =>
      borderColor ?? color ?? theme.colors.grey_7};
  color: ${({ color, textColor, theme }) =>
    textColor ?? color ?? theme.colors.grey_6};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? "transparent"};
  border-radius: 2em;
  display: inline-block;
  font-family: "Muli Bold", sans-serif;
  line-height: clamp(18px, 1.125rem, 24px);
  font-size: clamp(12px, 0.75rem, 16px);
  padding: 0rem 0.375rem;
  white-space: nowrap;
`;
