const INITIAL_STATE = {
  label: '',
  page: 0,
  progress: 0,
};

/**
 * TYPES
 */

export const Types = {
  CHANGE_LABEL_MODAL: "change-label-modal",
};
/**
 * REDUCER
 */
export function labelNextModal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CHANGE_LABEL_MODAL:
      return {
          ...state,
          label: action.payload.label,
          page: action.payload.page,
          progress: action.payload.progress
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  nextLabel: ( label, page, progress ) => ({
    type: Types.CHANGE_LABEL_MODAL,
    payload: { label, page, progress }
  })
};