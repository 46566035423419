import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as NotificationSettingsActions } from "store/ducks/notificationSettings";

export function* getNotificationSettings(action) {
  try {
    const { data } = yield call(MainService.get, "v2/notifications/settings");
    yield put(
      NotificationSettingsActions.success({ data, user: action.payload.user })
    );
  } catch (error) {
    yield put(
      NotificationSettingsActions.failure({
        data: error.message,
        user: action.payload.data,
      })
    );
  }
}
