import {
  getEstablishmentCurrentPathLocation,
  getShowSubItems,
} from "utils/basic";

const INITIAL_STATE = {
  opened: JSON.parse(localStorage.getItem("sidebar")) ?? false,
  openedHover: false,
  docked: false,
  currentMenuItem: "External-Base",
  showSubItems: [],
};

/**
 * TYPES
 */

export const Types = {
  OPEN_SIDEBAR: "open-sidebar",
  CLOSE_SIDEBAR: "close-sidebar",
  OPEN_SIDEBAR_HOVER: "open-sidebar-hover",
  CLOSE_SIDEBAR_HOVER: "close-sidebar-hover",
  DOCK_SIDEBAR: "dock-sidebar",
  UNDOCK_SIDEBAR: "undock-sidebar",
  SET_SIDEBAR: "set-sidebar",
  SET_CURRENT_MENU_ITEM: "set-current-menu-item",
  SET_SHOW_SUBITEMS: "set-show-sub-item",
  CLOSE_SUBITEM: "set-subitem",
};
/**
 * REDUCER
 */
export function sidebar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.OPEN_SIDEBAR:
      localStorage.setItem("sidebar", true);
      return { ...state, opened: true };
    case Types.CLOSE_SIDEBAR:
      localStorage.setItem("sidebar", false);
      return { ...state, opened: false, showSubItems: [] };
    case Types.OPEN_SIDEBAR_HOVER:
      return { ...state, openedHover: true };
    case Types.CLOSE_SIDEBAR_HOVER:
      return {
        ...state,
        openedHover: false,
        showSubItems: state.opened ? state.showSubItems : [],
      };
    case Types.DOCK_SIDEBAR:
      return { ...state, docked: true };
    case Types.UNDOCK_SIDEBAR:
      return { ...state, docked: false };
    case Types.SET_SIDEBAR:
      return { ...state, opened: JSON.parse(localStorage.getItem("sidebar")) };
    case Types.SET_CURRENT_MENU_ITEM:
      return {
        ...state,
        currentMenuItem: getEstablishmentCurrentPathLocation(action.payload),
      };
    case Types.SET_SHOW_SUBITEMS:
      return {
        ...state,
        showSubItems: getShowSubItems(state, action),
      };
    case Types.CLOSE_SUBITEM:
      return {
        ...state,
        showSubItems: [],
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  open: () => ({ type: Types.OPEN_SIDEBAR }),
  close: () => ({ type: Types.CLOSE_SIDEBAR }),
  openHover: () => ({ type: Types.OPEN_SIDEBAR_HOVER }),
  closeHover: () => ({ type: Types.CLOSE_SIDEBAR_HOVER }),
  dock: () => ({ type: Types.DOCK_SIDEBAR }),
  undock: () => ({ type: Types.UNDOCK_SIDEBAR }),
  updateDockedState: () => ({ type: Types.SET_SIDEBAR }),
  setCurrentMenuItem: (itemName) => ({
    type: Types.SET_CURRENT_MENU_ITEM,
    payload: itemName,
  }),
  setShowSubItems: ({ name }) => ({
    type: Types.SET_SHOW_SUBITEMS,
    payload: { name },
  }),
  closeSubItem: () => ({ type: Types.CLOSE_SUBITEM }),
};
