import React from "react";
import { useTheme } from "styled-components";

import { Icon } from "@iconify/react";
import loadingTwotoneLoop from "@iconify/icons-line-md/loading-twotone-loop";
import colors from "assets/colors";

import * as S from "./styles";

const LoadingSpinner = ({ size = "3.125rem" }) => {
  const theme = useTheme();

  return (
    <S.LoadingContainer>
      <Icon
        icon={loadingTwotoneLoop}
        color={theme.colors.primary_6}
        height={size}
        width={size}
      />
    </S.LoadingContainer>
  );
};

export default LoadingSpinner;
