const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: []
};

/**
 * TYPES
 */
export const Types = {
  GET_ROLES_REQUEST: "get-roles-request",
  GET_ROLES_SUCCESS: "get-roles-success",
  GET_ROLES_FAILURE: "get-roles-failure"
};
/**
 * REDUCER
 */
export function roles(state = INITIAL_STATE, action) {
  switch (action.type) {
    // JOB SETTINGS
    case Types.GET_ROLES_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.roles,
        loaded: true
      };
    case Types.GET_ROLES_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  getRolesRequest: () => ({
    type: Types.GET_ROLES_REQUEST
  }),
  getRolesSuccess: data => ({
    type: Types.GET_ROLES_SUCCESS,
    payload: { data }
  }),
  getRolesFailure: data => ({
    type: Types.GET_ROLES_FAILURE,
    payload: { data }
  })
};
