import styled from "styled-components";
import colors from "assets/colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.938rem 1rem;

  border-left: 0.5rem solid ${({ color }) => color};
`;

export const Text = styled.span`
  font: 0.875rem/1.098rem "Muli", sans-serif;
  color: ${colors.black};

  margin-left: 1rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  justify-content: space-between;
`;
