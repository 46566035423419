import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as ExtraDataActions } from "store/ducks/extraData";

export function* getExtraData(action) {
  try {
    const { data } = yield call(MainService.get, "v2/user/data");
    yield put(ExtraDataActions.success({ data, user: action.payload.user }));
  } catch (error) {
    yield put(ExtraDataActions.failure({ data: error.message, user: action.payload.user }));
  }
}
