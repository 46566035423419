import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as CommercialSettingsActions } from "store/ducks/commercialSettings";

export function* getCommercialSettings(action) {
  const path = {
    establishment: "units",
    bigcompany: "bigcompany",
  };

  try {
    if (!!action.payload.required) {
      const { data } = yield call(
        MainService.get,
        `v2/${path[action.payload.user]}/${
          action.payload.pk
        }/commercial_settings`
      );

      yield put(
        CommercialSettingsActions.success({ data, user: action.payload.user })
      );
    } else {
      yield put(
        CommercialSettingsActions.createOptionalConfig({
          user: action.payload.user,
        })
      );
    }
  } catch (error) {
    if (error?.response && error?.response?.status !== 401) {
      yield put(
        CommercialSettingsActions.failure({
          data: error.message,
          user: action.payload.user,
        })
      );
    }
  }
}
