import mainService from "services/mainService";

export const ExternalEvaluationJobController = {
  getJobDetails({ id = ''}) {
    let url = `v2/jobs/offer/${id}`;

    return mainService.get(url);
  },

  sendRating({ id = '', options = [], text = ""}) {
    let url = `v2/jobs/offer/${id}`;

    return mainService.post(url, { options, text});
  }
};