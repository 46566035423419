import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as TrainingActions } from "store/ducks/training";

export function* getVideoRoles(action) {
  try {
    const { data } = yield call(
      MainService.get,
      "v2/courses/videos_role/roles/establishment"
    );
    yield put(
      TrainingActions.success({ key: action.payload.key, data: data?.results })
    );
  } catch (error) {
    yield put(
      TrainingActions.error({
        key: action.payload.key,
        data: error.message,
      })
    );
  }
}
