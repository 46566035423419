const INITIAL_STATE = {
    text: "",
    url: "",
  };

  /**
   * TYPES
   */
  export const Types = {
    SET_DATE_INTERVAL: "set-date-interval",
  };
  /**
   * REDUCER
   */
  export function dateInterval(state = INITIAL_STATE, action) {
    switch (action.type) {
      case Types.SET_DATE_INTERVAL:
        return {
          ...state,
          url: action.payload.url,
          text: action.payload.text,
        };
      default:
        return state;
    }
  }

  /**
   * ACTIONS
   */
  export const Creators = {
    setDateInterval: ({ url, text }) => ({
      type: Types.SET_DATE_INTERVAL,
      payload: { url, text }
    }),
  };
