//production
export const SERVER_URL = "https://app.closeer.com.br/";
//developer
//export const SERVER_URL = "https://api.closeer.com.br/";

export const CLIENT_ID = "EKRDQqDEIh631rqmOSIfiZ5VqaYBhAqt9Mso3Qvg";

export const VINDI_URL = process.env.REACT_APP_VINDI_URL;
export const VINDI_API_KEY = process.env.REACT_APP_VINDI_API_KEY;

import i18n from "i18n";

export const MONTHS = [
  i18n.t("common:calendar_translate.1.full"),
  i18n.t("common:calendar_translate.2.full"),
  i18n.t("common:calendar_translate.3.full"),
  i18n.t("common:calendar_translate.4.full"),
  i18n.t("common:calendar_translate.5.full"),
  i18n.t("common:calendar_translate.6.full"),
  i18n.t("common:calendar_translate.7.full"),
  i18n.t("common:calendar_translate.8.full"),
  i18n.t("common:calendar_translate.9.full"),
  i18n.t("common:calendar_translate.10.full"),
  i18n.t("common:calendar_translate.11.full"),
  i18n.t("common:calendar_translate.12.full"),
];

export const MONTHS_REDUCE = [
  i18n.t("common:calendar_translate.1.short"),
  i18n.t("common:calendar_translate.2.short"),
  i18n.t("common:calendar_translate.3.short"),
  i18n.t("common:calendar_translate.4.short"),
  i18n.t("common:calendar_translate.5.short"),
  i18n.t("common:calendar_translate.6.short"),
  i18n.t("common:calendar_translate.7.short"),
  i18n.t("common:calendar_translate.8.short"),
  i18n.t("common:calendar_translate.9.short"),
  i18n.t("common:calendar_translate.10.short"),
  i18n.t("common:calendar_translate.11.short"),
  i18n.t("common:calendar_translate.12.short"),
];

export const MONTHS_REDUCE_SIGLA = [
  "J",
  "F",
  "M",
  "A",
  "M",
  "J",
  "J",
  "A",
  "S",
  "O",
  "N",
  "D",
];

export const MAX_WORKING_INTERVAL_BASE_IN_MINUTES = 720;
export const MIN_TIME_BETWEEN_NOW_AND_START_TIME_JOB_IN_MINUTES = 30;

export const DEFAULT_MESSAGE_INSTRUCTION =
  "Por favor, chegue na empresa pelo menos 15 minutos antes.";

export const DAYS_WEEK = [
  "schedule:day_week.sunday",
  "schedule:day_week.monday",
  "schedule:day_week.tuesday",
  "schedule:day_week.wendnesday",
  "schedule:day_week.thursday",
  "schedule:day_week.friday",
  "schedule:day_week.sartuday",
];
