import styled from "styled-components";
import colors from "assets/colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-area: ${(props) => props.area};
`;

export const Label = styled.div`
  &,
  * {
    color: ${colors.black};
    font-size: 0.875rem;
    line-height: 1.098rem;
    font-family: "Muli Bold", sans-serif;
  }
`;

export const Description = styled.div`
  &,
  * {
    color: ${colors.black};
    font-size: 0.875rem;
    line-height: 1.098rem;
    font-family: "Muli", sans-serif;
    margin-top: 0.5rem;
  }
`;
