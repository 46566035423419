import axios from "axios";
import { resolveErrorNotification } from "utils/basic";
import { getLocale } from "utils/basic";

const authService = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

authService.interceptors.request.use(
  function (config) {
    config.headers = { ...config.headers, "Accept-Language": getLocale() };
    return config;
  },
  (error) => Promise.reject(error)
);
// Add a response interceptor
authService.interceptors.response.use(
  (response) => response,
  (error) => {
    let notifyError = !!error?.response?.config?.headers[
      "notifications-front-banned"
    ]
      ? false
      : true;
    error.customErrorOptions = resolveErrorNotification({
      response: error.response,
      fireToast: notifyError,
    });
    return Promise.reject(error);
  }
);

export default authService;
