import axios from "axios";
import { Cookie } from "utils/cookies";
import { resolveErrorNotification, getLocale, handleLogout } from "utils/basic";

import authService from "./authService";

const mainService = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

const refreshToken = async ({ onError, onSuccess }) => {
  const tokenManager = Cookie.get("token-manager");
  const tokenSupervisor = Cookie.get("token-supervisor");
  const tokenAdministrator = Cookie.get("token-administrator");
  let token;
  let tokenName;
  const { pathname } = window.location;

  if (pathname.includes("/adm") || pathname.includes("/closeer/companhia")) {
    token = tokenAdministrator;
    tokenName = "token-administrator";
  } else if (
    pathname.includes("/spv") ||
    pathname.includes("/closeer/supervisor")
  ) {
    token = tokenSupervisor;
    tokenName = "token-supervisor";
  } else if (
    pathname.includes("/dashboard") ||
    pathname.includes("/closeer/unidade")
  ) {
    token = tokenManager;
    tokenName = "token-manager";
  } else {
    token = false;
  }

  try {
    if (!token) {
      throw "token undefined";
    }
    let body = {
      refresh: token.refresh_token,
    };
    const response = await authService.post("v2/user/token/refresh/", body);

    const { refresh_token, access_token } = response.data;
    token = {
      ...token,
      refresh_token,
      access_token,
    };

    Cookie.set(tokenName, token);
    location.reload();
    return onSuccess?.();
  } catch (error) {
    handleLogout(() => (window.location.pathname = "/login"));

    return onError?.();
  }
};

mainService.interceptors.request.use(
  function (config) {
    if (config.url.indexOf("v2") && config.url.indexOf("api/"))
      config.baseURL = config.baseURL + "api/";

    const tokenAdministrator = Cookie.get("token-administrator");
    const tokenSupervisor = Cookie.get("token-supervisor");
    const tokenManager = Cookie.get("token-manager");
    let token;
    const { pathname } = window.location;

    if (
      !!tokenAdministrator &&
      (pathname.includes("/adm") || pathname.includes("/closeer/companhia"))
    ) {
      token = tokenAdministrator;
    } else if (
      !!tokenSupervisor &&
      (pathname.includes("/spv") || pathname.includes("/closeer/supervisor"))
    ) {
      token = tokenSupervisor;
    } else if (
      !!tokenManager &&
      (pathname.includes("/dashboard") || pathname.includes("/closeer/unidade"))
    ) {
      token = tokenManager;
    }

    if (!config.headers.Authorization && !!token) {
      config.headers.Authorization = `Bearer ${token?.access_token}`;
    }

    if (!!config.headers.ignoreToken) {
      delete config.headers.ignoreToken;
      delete config.headers.Authorization;
    }

    config.headers = { ...config.headers, "Accept-Language": getLocale() };
    return config;
  },
  (error) => Promise.reject(error)
);

var isRefreshing = false;

mainService.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error?.config;

    if (isRefreshing) return;

    if (error?.response?.status === 401) {
      isRefreshing = true;
      originalRequest.__retry = true;

      return refreshToken({
        onSuccess: () => {
          isRefreshing = false;
          return mainService(originalRequest);
        },
        onError: () => {
          isRefreshing = false;
          return Promise.reject(error);
        },
      });
    }

    let notifyError = !!error?.response?.config?.headers?.[
      "notifications-front-banned"
    ]
      ? false
      : true;

    error.customErrorOptions = resolveErrorNotification({
      fireToast: notifyError,
      response: error?.response,
      isCancelled: error?.code === "ERR_CANCELED",
    });

    return Promise.reject(error);
  }
);

export default mainService;
