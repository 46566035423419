import { openExternalLink } from "utils/basic";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

import {
  checkCriticalImpact,
  compareStorage,
  listStatusPage,
  objectStatus,
} from "components/WidgetStatusPage/utils";
import { BASEURL, TIME_REQUEST, WIDGET_KEY } from "./utils";

/* import {
  incidentsMocked,
  maintenancesActiveMocked,
  maintenancesUpcomingMocked,
} from "components/WidgetStatusPage/mocked"; */

export const useWidgetStatus = () => {
  const queryClient = useQueryClient();

  const StatusPageQuery = useQuery({
    queryKey: WIDGET_KEY,
    queryFn: async () => {
      const [{ data: maintenances_active }, { data: incidents }] =
        await Promise.all([
          axios.get(`${BASEURL}/scheduled-maintenances/active.json`),
          axios.get(`${BASEURL}/incidents/unresolved.json`),
        ]);

      const countStatus = listStatusPage(maintenances_active, incidents);

      const hasCriticalImpact = checkCriticalImpact({
        active: countStatus?.active,
        incidents: countStatus?.incidents,
      });
      const itemStorage = localStorage.getItem("statusPage");
      const compare =
        JSON.stringify(countStatus) === JSON.stringify(JSON.parse(itemStorage));

      let styleWidget = {
        icon: null,
        background: null,
        title: null,
        tags: [],
        message: null,
        link: null,
      };
      let showWidget = false;
      let newStatus = null;

      const responseStatus = compareStorage(
        JSON.parse(itemStorage),
        countStatus
      );
      if (!compare) {
        newStatus = responseStatus.api;
        if (
          !["normal", "scheduled_multiple", "scheduled"].includes(
            responseStatus?.statusWidget
          )
        ) {
          const { statusWidget: widget, api } = responseStatus;

          const style = objectStatus(widget, api);
          styleWidget = style;
          showWidget = true;
        }
      }

      return {
        hasCriticalImpact,
        styleWidget,
        showWidget,
        newStatus,
      };
    },
    retry: false,
    staleTime: TIME_REQUEST,
    refetchInterval: TIME_REQUEST,
    placeholderData: {
      hasCriticalImpact: false,
      styleWidget: null,
      showWidget: false,
      newStatus: null,
    },
  });

  const { hasCriticalImpact, styleWidget, showWidget, newStatus } =
    StatusPageQuery?.data || {};

  const handleClose = () => {
    localStorage.setItem("statusPage", JSON.stringify(newStatus));
    queryClient.setQueryData(WIDGET_KEY, (oldData) => ({
      ...oldData,
      showWidget: false,
    }));

    if (newStatus?.showMoreCard) {
      queryClient.invalidateQueries(WIDGET_KEY);
    }
  };

  const clickSeeMore = () => {
    openExternalLink(styleWidget.link);
  };

  return {
    clickSeeMore,
    handleClose,
    showWidget,
    styleWidget,
    hasCriticalImpact,
  };
};
