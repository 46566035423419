import styled from "styled-components";

export const ContainerCheckbox = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Checkbox = styled.div`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  display: flex;
  align-items: center;
  width: 0.938rem;
  min-width: 0.938rem;
  height: 0.938rem;
  background-color: ${({ theme, disabled, checked }) =>
    disabled
      ? theme.colors.grey_1
      : checked
      ? theme.colors.primary_5
      : theme.colors.white_pure};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 0.063rem solid
    ${({ theme, checked, disabled }) =>
      disabled
        ? theme.colors.grey_1
        : checked
        ? theme.colors.primary_5
        : theme.colors.grey_2};
  margin-right: 0.5rem;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
`;

export const ContainerDescription = styled.div`
  padding-left: 1.438rem;
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const Text = styled.p`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  font: 0.875rem/1.098rem "Muli", sans-serif;
`;
