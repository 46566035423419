import { Cookie } from "utils/cookies";

const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  tokenManager: Cookie.get("token-manager"),
  tokenSupervisor: Cookie.get("token-supervisor"),
  tokenAdministrator: Cookie.get("token-administrator"),
};

/**
 * TYPES
 */

export const Types = {
  LOGIN_REQUEST: "login-request",
  LOGIN_SUCCESS: "login-success",
  LOGIN_2FA: "login-2fa",
  LOGIN_FAILURE: "login-failure",
  SET_TOKEN_MANAGER: "set-token-manager",
  SET_TOKEN_SUPERVISOR: "set-token-supervisor",
  SET_TOKEN_ADMINISTRATOR: "set-token-administrator",
};
/**
 * REDUCER
 */
export function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    // LOGIN
    case Types.LOGIN_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
        loaded: true,
      };
    case Types.LOGIN_2FA:
      return {
        ...state,
        ...action.payload.data,
        loading: false,
        loaded: true,
        error: "",
      };
    case Types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload?.data?.response?.data?.detail || "",
      };
    // TOKEN
    case Types.SET_TOKEN_MANAGER:
      return {
        ...state,
        tokenManager: action.payload.token,
      };
    case Types.SET_TOKEN_SUPERVISOR:
      return {
        ...state,
        tokenSupervisor: action.payload.token,
      };
    case Types.SET_TOKEN_ADMINISTRATOR:
      return {
        ...state,
        tokenAdministrator: action.payload.token,
      };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  // LOGIN
  loginRequest: (data) => ({
    type: Types.LOGIN_REQUEST,
    payload: { data },
  }),
  loginSuccess: (data) => ({
    type: Types.LOGIN_SUCCESS,
    payload: { data },
  }),
  login2fa: (data) => ({
    type: Types.LOGIN_2FA,
    payload: { data },
  }),
  loginFailure: (data) => ({
    type: Types.LOGIN_FAILURE,
    payload: { data },
  }),
  // TOKEN
  setTokenManager: (token) => ({
    type: Types.SET_TOKEN_MANAGER,
    payload: { token },
  }),
  setTokenSupervisor: (token) => ({
    type: Types.SET_TOKEN_SUPERVISOR,
    payload: { token },
  }),
  setTokenAdministrator: (token) => ({
    type: Types.SET_TOKEN_ADMINISTRATOR,
    payload: { token },
  }),
};
