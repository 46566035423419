import styled from "styled-components";

const getBackgroundColorSwitchButton = ({ theme, checked, disabled }) => {
  if (disabled && !checked) {
    return theme.colors.grey_3;
  }
  if (disabled && checked) {
    return theme.colors.primary_1;
  }
  if (checked) {
    return theme.colors.primary_0;
  }
  return theme.colors.grey_1;
};

const getBackgroundColorSlider = ({ theme, checked, disabled }) => {
  if (disabled && !checked) {
    return theme.colors.grey_4;
  }
  if (disabled && checked) {
    return theme.colors.primary_3;
  }
  if (checked) {
    return theme.colors.primary_5;
  }
  return theme.colors.grey_3;
};

export const SwitchButtonWrapper = styled.div`
  min-width: ${({ small }) => (small ? "1.5rem" : "2.5rem")};
  height: ${({ small }) => (small ? "0.75rem" : "1.25rem")};
  background-color: ${getBackgroundColorSwitchButton};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: background-color 0.3s;
  align-self: flex-start;
`;

export const Slider = styled.div`
  width: ${({ small }) => (small ? "0.625rem" : "1.042rem")};
  height: ${({ small }) => (small ? "0.625rem" : "1.042rem")};
  background-color: ${getBackgroundColorSlider};
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  position: absolute;
  top: ${({ small }) => (small ? "1px" : "1.67px")};
  left: ${({ small }) => (small ? "1px" : "1.67px")};
  transition: transform 0.3s;
  transform: ${({ checked, small }) =>
    checked && small
      ? "translateX(12px)"
      : checked && !small
      ? "translateX(20px)"
      : "translateX(0)"};
`;
