const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: []
};

/**
 * TYPES
 */

export const Types = {
  HELP_LINK_REQUEST: "link-help-request",
  HELP_LINK_SUCCESS: "link-help-success",
  HELP_LINK_FAILURE: "link-help-failure"
};
/**
 * REDUCER
 */
export function helpLink(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.HELP_LINK_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.HELP_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true
      };
    case Types.HELP_LINK_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  requestHelpLink: (data) => ({
    type: Types.HELP_LINK_REQUEST,
    payload: { data }
  }),
  helpLinkSuccess: (data) => ({
    type: Types.HELP_LINK_SUCCESS,
    payload: { data }
  }),
  helpLinkFailure: data => ({
    type: Types.HELP_LINK_FAILURE,
    payload: { data }
  })
};
