import { call, put } from "redux-saga/effects";
import authService from "services/authService";
import { Cookie } from "utils/cookies";

import { Creators as LoginActions } from "store/ducks/auth";

export function* login(action) {
  try {
    let request_body = {
      email: action?.payload?.data?.email,
      password: action?.payload?.data?.password,
    };
    const redirect = action?.payload?.data?.redirect ?? "";
    let response = {};
    if (action?.payload?.data?.code) {
      // 2FA
      request_body["code"] = action?.payload?.data?.code;
      response = yield call(
        authService.post,
        "/api/login/email/password/2fa/",
        request_body
      );
    } else {
      // Normal login
      response = yield call(
        authService.post,
        "/api/login/email/password/",
        request_body
      );
    }

    const data = response?.data;
    let error = "";
    let route = "";

    if (data?.expiration_time || data?.time_to_get_other_code) {
      const expirationTime =
        data?.expiration_time || data?.time_to_get_other_code;
      yield put(
        LoginActions.login2fa({ expirationTime, ...action.payload.data })
      );
      return;
    } else if (data.account_type === "adm") {
      Cookie.set("token-administrator", data);
      route = "/adm" + redirect;
    } else if (data.account_type === "supervisor") {
      Cookie.set("token-supervisor", data);
      route = "/spv" + redirect;
    } else if (["manager", "subaccount"].includes(data.account_type)) {
      Cookie.set("token-manager", data);
      route = "/dashboard" + redirect;
    } else if (data.account_type === "unknown") {
      error = "Esta conta está desativada";
    } else if (data.account_type === "not_related") {
      error = "Este usuário ainda não tem permissão de acessar esta unidade.";
    } else {
      error = "Conta com inconsistência de permissões.";
    }
    yield put(LoginActions.loginSuccess(error));
    if (action.payload.onSuccess) {
      action.payload.onSuccess(route);
    } else {
      window.location = route;
    }
  } catch (error) {
    yield put(LoginActions.loginFailure(error));
  }
}
