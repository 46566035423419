const INITIAL_STATE = {
    month: new Date(),
};

/**
 * TYPES
 */

export const Types = {
    CHANGE_MONTH: "change-month",
};
/**
 * REDUCER
 */
export function newMonth(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.CHANGE_MONTH:
            return {
                ...state,
                month: action.payload.month,
            };
        default:
            return state;
    }
}

/**
 * ACTIONS
 */
export const Creators = {
    setNewMonth: (month) => ({
        type: Types.CHANGE_MONTH,
        payload: { month }
    })
};