import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as NotificationPreferencesActions } from "store/ducks/notificationPreferences";

export function* getPreferencesNotification(action) {
  try {
    const { data } = yield call(
      MainService.get,
      "v2/notifications/user_preferences"
    );

    yield put(
      NotificationPreferencesActions.notificationPreferencesSuccess({
        data,
        user: action.payload.user,
      })
    );
  } catch (error) {
    yield put(
      NotificationPreferencesActions.notificationPreferencesFailure({
        data: error.message,
        user: action.payload.user,
      })
    );
  }
}
