const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: []
};

/**
 * TYPES
 */

export const Types = {
  LEVELS_REQUEST: "levels-request",
  LEVELS_SUCCESS: "levels-success",
  LEVELS_FAILURE: "levels-failure"
};
/**
 * REDUCER
 */
export function levels(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LEVELS_REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.LEVELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true
      };
    case Types.LEVELS_FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  setLevels: (data) => ({
    type: Types.LEVELS_REQUEST,
    payload: { data }
  }),
  levelsSuccess: (data) => ({
    type: Types.LEVELS_SUCCESS,
    payload: { data }
  }),
  levelsFailure: data => ({
    type: Types.LEVELS_FAILURE,
    payload: { data }
  })
};
