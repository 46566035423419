import { useState, useEffect } from "react";

import OneSignal from "react-onesignal";

export function initializeOneSignal() {
  OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL,
    serviceWorkerParam: { scope: "/onesignal/" },
    serviceWorkerPath: "onesignal/OneSignalSDKWorker.js",
  });
}

export const useOneSignal = ({ uuid, enabled = true } = {}) => {
  const [sended, setSended] = useState(false);
  const [isGranted, setIsGranted] = useState(false);

  useEffect(() => {
    if (!enabled) return;
    if (!uuid) return;
    if (sended && isGranted) return;

    const { permission, permissionNative } = OneSignal.Notifications;

    if (permission && permissionNative === "granted" && sended) return;

    OneSignal.User.addTag("uuid", uuid);
    setSended(true);

    const onPermissionChange = (granted) => {
      setIsGranted(granted);
    };

    OneSignal.Notifications.addEventListener(
      "permissionChange",
      onPermissionChange
    );

    return () => {
      OneSignal.Notifications.removeEventListener(
        "permissionChange",
        onPermissionChange
      );
    };
  }, [enabled, isGranted, uuid, sended]);

  return { permission_granted: isGranted, uuid_is_sended: sended };
};
