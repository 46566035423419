const INITIAL_STATE = {
  establishment: {
    loaded: false,
    loading: false,
    error: "",
    data: null,
  },
  bigcompany: {
    loaded: false,
    loading: false,
    error: "",
    data: null,
  },
};

export const Types = {
  REQUEST: "notification-settings-request",
  SUCCESS: "notification-settings-success",
  FAILURE: "notification-settings-failure",
  UPDATE: "update-notification-settings",
};

export function notificationSettings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loaded: false,
          loading: true,
          error: "",
        },
      };
    case Types.SUCCESS:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: false,
          data: action.payload.data,
          loaded: true,
        },
      };
    case Types.FAILURE:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          loading: false,
          error: action.payload.data,
        },
      };
    case Types.UPDATE:
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          data: {
            ...state[action.payload.user].data,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    default:
      return state;
  }
}

export const Creators = {
  request: ({ user = "establishment" }) => ({
    type: Types.REQUEST,
    payload: { user },
  }),
  success: ({ data, user = "establishment" }) => ({
    type: Types.SUCCESS,
    payload: { data, user },
  }),
  failure: ({ data, user = "establishment" }) => ({
    type: Types.FAILURE,
    payload: { data, user },
  }),
  update: ({ key, value, user = "establishment" }) => ({
    type: Types.UPDATE,
    payload: { user, key, value },
  }),
};
