const INITIAL_STATE = {
    page: 0,
  };
  
  /**
   * TYPES
   */
  
  export const Types = {
    CHANGE_PAGE_TAB: "change-page-tab",
  };
  /**
   * REDUCER
   */
  export function pageChangeTab(state = INITIAL_STATE, action) {
    switch (action.type) {
      case Types.CHANGE_PAGE_TAB:
        return {
            ...state,
            page: action.payload.page,
        };
      default:
        return state;
    }
  }
  
  /**
   * ACTIONS
   */
  export const Creators = {
    changePage: ( page ) => ({
      type: Types.CHANGE_PAGE_TAB,
      payload: { page }
    })
  };