import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as EstablishmentSettingsActions } from "store/ducks/establishment/settings";

export function* getEstablishmentSettings(action) {
  try {
    const { data } = yield call(
      MainService.get,
      `/establishment/${action.payload.pk}/job_settings`
    );

    yield put(
      EstablishmentSettingsActions.onSuccess({
        data,
        pk: action.payload.pk,
      })
    );
  } catch (error) {
    yield put(EstablishmentSettingsActions.onFailure({ data: error.message }));
  }
}
