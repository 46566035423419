/**
 * INITIAL STATE
 */
const keys = ["training", "modalJobToApprove"];

const INITIAL_STATE = getInitialState(keys);

/**
 * TYPES
 */
export const Types = {
  RELOAD: "start-reloads",
  CLEAR: "clear-reloads",
};

/**
 * REDUCER
 */
export function reloads(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RELOAD:
      return changeReload(state, action);
    case Types.CLEAR:
      return clearReload(state, action);
    default:
      return state;
  }
}

/**
 *
 * FUNCTION
 */
function changeReload(state, action) {
  const key = !!state[action.payload.key] ? action.payload.key : "";

  return {
    ...state,
    [key]: {
      ...state[key],
      count_reload: state[key].count_reload + 1,
    },
  };
}

function clearReload(state, action) {
  const key = !!state[action.payload.key] ? action.payload.key : "";

  return {
    ...state,
    [key]: {
      ...state[key],
      count_reload: 1,
    },
  };
}

function getInitialState(data = []) {
  let schema = {};

  data.forEach((key) => {
    schema[key] = {
      count_reload: 1,
    };
  });
  return schema;
}

/**
 * ACTIONS
 */
export const Creators = {
  reload: (key = "") => ({
    type: Types.RELOAD,
    payload: { key },
  }),
  clear: (key = "") => ({
    type: Types.CLEAR,
    payload: { key },
  }),
};
