import mainService from "services/mainService";

/**
 * --------------------------------------------------
 * Retorna os dados da assinatura do cliente, caso exista.
 *
 * Se o user for 'establishment', o retorno será:
 * {
 *  id: 1,
 *  status: "active",
 *  generate_periods_starts_at: "2022-08-25",
 *  users_count: 10,
 *  price_value: "30.00",
 * }
 *
 * Se o user for 'bigcompany', o retorno será:
 * {
 *  id: 1,
 *  status: "active",
 *  generate_periods_starts_at: "2022-08-25",
 *  users_count: 10,
 *  price_value: "30.00",
 * }
 *
 * @param {Object} params
 * @param {String} params.user - User pode ser 'bigcompany' ou 'establishment'
 * @param {String} params.userId - Id do usuário
 * @param {Object} params.headers - Headers da requisição
 *
 */
export function getSubscriptionData({ user, userId }) {
  return mainService.get(`v2/commercial/${user}/${userId}/subscription`, {
    headers: {
      "notifications-front-banned": true,
    },
  });
}

/**
 * --------------------------------------------------
 * Retorna um resumo da assinatura
 *
 * Se o user for 'establishment', o retorno será:
 * {
 *  due_at: "2022-08-25",
 *  period_start_date: "2022-08-25",
 *  has_debt: false,
 *  is_overdue: false,
 * }
 *
 * Se o user for 'bigcompany', o retorno será:
 * {
 *  due_at: "2022-08-25",
 *  period_start_date: "2022-08-25",
 *  has_debt: false,
 *  is_overdue: false,
 * }
 *
 * @param {Object} params
 * @param {String} params.user - User pode ser 'bigcompany' ou 'establishment'
 * @param {String} params.userId - Id do usuário
 *
 */
export function getSubscriptionSummary({ user, userId }) {
  return mainService.get(
    `v2/commercial/${user}/${userId}/subscription/summary`,
    {
      headers: {
        "notifications-front-banned": true,
      },
    }
  );
}

/**
 * --------------------------------------------------
 * Retorna os períodos da assinaturas
 *
 * Se o user for 'establishment', o retorno será:
 * [
 *  {
 *   id: 1,
 *   payment_method: "pix",
 *   due_at: "2022-09-25",
 *   status: "pending",
 *   value: 0,
 *   has_invoice: false,
 *  }
 * ]
 *
 * Se o user for 'bigcompany', o retorno será:
 * [
 *  {
 *   id: 1,
 *   payment_method: "pix",
 *   due_at: "2022-09-25",
 *   status: "pending",
 *   value: 0,
 *   has_invoice: false,
 *  }
 * ]
 *
 * @param {string} user - User pode ser 'bigcompany' ou 'establishment'
 * @param {string} userId - Id do usuário
 * @param {number} page
 * @param {number} pageSize
 *
 */
export function getSubscriptionPeriods({
  user,
  userId,
  page = 1,
  pageSize = 10,
}) {
  return mainService.get(
    `v2/commercial/${user}/${userId}/subscription/periods?page=${page}&page_size=${pageSize}`
  );
}

/**
 * --------------------------------------------------
 * Retorna a nota fiscal referente ao período
 *
 * Se o user for 'establishment', o retorno será:
 * "https://api.com.br/v2/commercial/establishment/1/subscription/periods/1/invoice"
 *
 * Se o user for 'bigcompany', o retorno será:
 * "https://api.com.br/v2/commercial/bigcompany/1/subscription/periods/1/invoice"
 *
 * @param {Object} params
 * @param {String} params.user - User pode ser 'bigcompany' ou 'establishment'
 * @param {String} params.userId - Id do usuário
 * @param {String} params.periodId - Id do período
 *
 */
export function getSubscriptionInvoice({ user, userId, periodId }) {
  return mainService.get(
    `v2/commercial/${user}/${userId}/subscription/periods/${periodId}/invoice`
  );
}

/**
 * --------------------------------------------------
 * Retorna as informações legais do usuário
 *
 * @param {String} userId - Id do usuário
 */
export function getLegalInformationBigCompany({ userId }) {
  return mainService.get(`v2/bigcompany/${userId}/legal_information`);
}

/**
 * --------------------------------------------------
 * Retorna dados do período especificado da assinatura
 *
 * @param {Object} params
 * @param {String} params.periodId - Id do período
 *
 */
export function getSubscriptionPeriodDetails({ periodId }) {
  return mainService.get(`v2/commercial/period/${periodId}/detail`);
}

/**
 * --------------------------------------------------
 * Ativa a assinatura de um cliente
 *
 * @param {Object} params
 * @param {String} params.user - User pode ser 'bigcompany' ou 'establishment'
 * @param {String} params.userId - Id do usuário
 *
 */
export function activeSubscription({ user, userId, body }) {
  return mainService.post(
    `v2/commercial/${user}/${userId}/active_subscription`,
    body
  );
}

/**
 * --------------------------------------------------
 * Retorna os dados da forma de pagamento da assinatura
 *
 * @param {Object} params
 * @param {String} params.subscriptionId - Id da assinatura
 *
 */
export function getPaymentMethod({ subscriptionId }) {
  return mainService.get(
    `v2/commercial/subscription/${subscriptionId}/payment_method_data`,
    {
      headers: {
        "notifications-front-banned": true,
      },
    }
  );
}

/**
 * --------------------------------------------------
 * Atualiza a forma de pagamento da assinatura
 *
 * @param {Object} params
 * @param {String} params.subscriptionId - Id da assinatura
 * @param {Object} params.body - Dados da forma de pagamento
 *
 */
export function updatePaymentMethod({ subscriptionId, body }) {
  return mainService.patch(
    `v2/commercial/subscription/${subscriptionId}/edit_payment_method`,
    body
  );
}
