const INITIAL_STATE = {
  establishment: {
    optional: false,
    loading: false,
    loaded: false,
    error: "",
    data: null,
  },
  bigcompany: {
    optional: false,
    loading: false,
    loaded: false,
    error: "",
    data: null,
  },
};

/**
 * TYPES
 */

export const Types = {
  REQUEST: "commercial-settings-request",
  SUCCESS: "commercial-settings-success",
  FAILURE: "commercial-settings-failure",
  SET_OPTIONAL_CONFIG: "commercial-settings-optional",
};

export function commercialSettings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return onRequest(state, action);
    case Types.SUCCESS:
      return onSuccess(state, action);
    case Types.FAILURE:
      return onFailure(state, action);
    case Types.SET_OPTIONAL_CONFIG:
      return createOptionalConfig(state, action);
    default:
      return state;
  }
}

function onRequest(state, action) {
  const user = action.payload.user;

  return {
    ...state,
    [user]: {
      ...state[user],
      loaded: false,
      loading: true,
      error: "",
    },
  };
}

function onSuccess(state, action) {
  const user = action.payload.user;
  const data = action.payload.data;

  return {
    ...state,
    [user]: {
      ...state[user],
      loading: false,
      data,
      loaded: true,
    },
  };
}

function onFailure(state, action) {
  const user = action.payload.user;
  const error = action.payload.data;

  return {
    ...state,
    [user]: {
      ...state[user],
      loading: false,
      error,
    },
  };
}

function createOptionalConfig(state, action) {
  const user = action.payload.user;

  return {
    ...state,
    [user]: {
      ...state[user],
      optional: true,
      loading: false,
      loaded: true,
    },
  };
}

export const Creators = {
  request: ({ required = true, pk, user = "establishment" }) => ({
    type: Types.REQUEST,
    payload: { required, pk, user },
  }),
  success: ({ data, user = "establishment" }) => ({
    type: Types.SUCCESS,
    payload: { data, user },
  }),
  failure: ({ data, user = "establishment" }) => ({
    type: Types.FAILURE,
    payload: { data, user },
  }),
  createOptionalConfig: ({ user = "establishment" }) => ({
    type: Types.SET_OPTIONAL_CONFIG,
    payload: { user },
  }),
};
