import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as WalletActions } from "store/ducks/wallet";

export function* getWallet(action) {
  try {
    const { data: workspace } = yield call(
      MainService.get,
      `v2/wallet/establishment/${action.payload.data}/workspace`
    );
    const { data: transit_balance } = yield call(
      MainService.get,
      `v2/wallet/establishment/${action.payload.data}/value_in_transit`
    ); //TODO valor em trânsito
    const data = { ...workspace, transit_balance: transit_balance?.value };

    yield put(WalletActions.walletSuccess(data));
  } catch (error) {
    yield put(WalletActions.walletFailure(error.message));
  }
}
